import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import './index.css';
import App from './App';

if (!window.location.host.startsWith("www")){
   window.location = window.location.protocol + "//www." + window.location.host + window.location.pathname;
}

ReactDOM.render(<BrowserRouter><App /></BrowserRouter>, document.getElementById('root'));
