import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { AccountMenu } from '../../components/account_menu';

class Discount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      userAmount: '',
      userLevel: '',
      userBonusCurrent: '',
      userBonusNext: '',
      userProgress: 0,
      userPercent: 0,
      userAmountLeft: '',
      startProgress: '',
      finishProgress: '',
      isOpenedMenu: false
    }
    this.accountSection = React.createRef();
    this.menuSection = React.createRef();
    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  loadingData() {
    fetch('/api/profile/discount', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.accessToken,
      },
      body: JSON.stringify({ 'loading': true }),
    })
      .then(response => response.json())
      .then((result) => {
          this.setState({
            data: result.data,
            userAmount: result.data.map(item => item.user.map(item => item.total_amount)),
            userLevel: result.data.map(item => item.level.map(item => item.name)),
            userBonusCurrent: result.data.map(item => item.level.map(item => item.discount_current)),
            userBonusNext: result.data.map(item => item.level.map(item => item.discount_next)),
            userProgress: result.data.map(item => item.progress),
            startProgress: result.data.map(item => item.level.map(item => item.amount_from)),
            finishProgress: result.data.map(item => item.level.map(item => item.amount_to)),
            loading: false
          });
      }, (error) => {
        if (error) {
          localStorage.removeItem('token');
          window.location.href = '/?login=true';
        }
      });
  }

  componentDidMount() {
    if (!this.props.accessToken) {
      window.location.href = '/?login=true';
    }
    this.loadingData();
    document.title = 'Бонусная программа | ' + process.env.REACT_APP_NAME;
    document.addEventListener('mousedown', this.closeMenu);
  }

  componentDidUpdate() {
    if (!this.props.accessToken) {
      window.location.href = '/?login=true';
    }
    if (this.state.userPercent !== parseFloat(this.state.userProgress)) {
      this.timer = setTimeout(() => this.setState({
        userPercent: this.state.userPercent + 1,
        userAmountLeft:
          parseFloat(this.state.userAmount[0][0]).toFixed(0).length === 2 && this.state.userPercent >= 88 ? 'inherit' :
            parseFloat(this.state.userAmount[0][0]).toFixed(0).length === 3 && this.state.userPercent >= 87 ? 'inherit' :
              parseFloat(this.state.userAmount[0][0]).toFixed(0).length === 4 && this.state.userPercent >= 86 ? 'inherit' :
                parseFloat(this.state.userAmount[0][0]).toFixed(0).length === 5 && this.state.userPercent >= 85 ? 'inherit' :
                  parseFloat(this.state.userAmount[0][0]).toFixed(0).length === 6 && this.state.userPercent >= 83 ? 'inherit' :
                    parseFloat(this.state.userAmount[0][0]).toFixed(0).length === 7 && this.state.userPercent >= 81 ? 'inherit' :
                      this.state.userPercent + 1
      }), 50);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
    document.removeEventListener('mousedown', this.closeMenu);
  }

  showMenu() {
    this.setState({ isOpenedMenu: !this.state.isOpenedMenu });
  }

  closeMenu(e) {
    if (this.accountSection.current && !this.accountSection.current.contains(e.target)) {
      if (this.state.isOpenedMenu && this.menuSection.current && !this.menuSection.current.contains(e.target)) {
        this.setState({ isOpenedMenu: false });
      }
    }
  }

  render() {
    return (
      <div className="page">
        <div className="page-content">
          <div className="account">
            <div className="account-column">
              <div className="account-section" onClick={this.showMenu} ref={this.accountSection}>
                <span className="account-section-title">Кабинет</span>
                <span className={'icon-arrow icon-default' + (this.state.isOpenedMenu ? ' rotate' : '')}></span>
              </div>
              <div className={'account-menu' + (this.state.isOpenedMenu ? ' open' : '')} ref={this.menuSection}>{AccountMenu}</div>
            </div>
            <div className="account-column">
              <div className="account-content">
                <h1>Бонусная программа</h1>
                <div className="icon-discount icon-main"></div>
              </div>
              <div className="user-menu">
                <NavLink to="/profile/discount" className="user-nav-item" activeClassName="user-nav-item-active">Статистика</NavLink>
                <NavLink to="/bonus" className="user-nav-item">О программе</NavLink>
              </div>
              {this.state.loading ?
                <div className="data-loading"></div>
                :
                <div>
                  <h2>Текущий уровень</h2>
                  <div className="user-data">
                    <div className="user-data-menu">
                      <div className="user-data-block">
                        <div className="user-data-column">Ваш оборот</div>
                        <div className="user-data-column">Уровень</div>
                        <div className="user-data-column">Ваш бонус</div>
                      </div>
                    </div>
                  </div>
                  <div className="user-data">
                    <div className="user-data-item">
                      <div className="user-data-block">
                        <div className="user-data-column">
                          <div className="user-data-info">{this.state.userAmount} USD</div>
                        </div>
                        <div className="user-data-column">
                          <div className="user-data-info">{this.state.userLevel}</div>
                        </div>
                        <div className="user-data-column">
                          <div className="user-data-info">{this.state.userBonusCurrent}%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="note">
                    <p>Чем больше сумма совершенных Вами обменов, тем больше размер Вашего бонуса и соответственно выше Ваш текущий уровень в системе. Бонус рассчитывается автоматически и распространяется на все обменные операции вне зависимости от участвующей в обмене валюты.</p>
                  </div>
                  <h2>Прогресс</h2>
                  {this.state.userAmount < 500000 ?
                    <div className="user-progress">
                      <div className="current-progress" style={{ left: this.state.userAmountLeft === 'inherit' ? this.state.userAmountLeft : this.state.userAmountLeft + '%', right: this.state.userAmountLeft === 'inherit' ? '0.5rem' : 'inherit' }}>{parseFloat(this.state.userAmount).toFixed(0)} USD</div>
                      <div className="start-progress">{this.state.startProgress} USD ({this.state.userBonusCurrent}%)</div>
                      <div className="finish-progress">{this.state.finishProgress} USD ({this.state.userBonusNext}%)</div>
                      <div className="user-progress-block">
                        <div className="user-progress-current" style={{ width: this.state.userPercent + '%' }}>
                          <span style={{ fontWeight: 'bold', marginLeft: '0.5rem', color: this.state.userPercent <= 1 ? '#7582ab' : '#fff' }}>{this.state.userPercent}
                            <span style={{ color: this.state.userPercent <= 2 ? '#7582ab' : '#fff' }}>%</span>
                          </span>
                        </div>
                      </div>
                    </div>
                    :
                    <div className="user-progress">
                      <div className="current-progress" style={{ left: this.state.userAmountLeft === 'inherit' ? this.state.userAmountLeft : this.state.userAmountLeft + '%', right: this.state.userAmountLeft === 'inherit' ? '0.5rem' : 'inherit' }}>{parseFloat(this.state.userAmount).toFixed(0)} USD</div>
                      <div className="start-progress">{this.state.startProgress} USD ({this.state.userBonusCurrent}%)</div>
                      <div className="user-progress-block">
                        <div className="user-progress-current" style={{ width: this.state.userPercent + '%', borderRadius: this.state.userPercent === 100 ? '.3rem' : '.3rem 0 0 .3rem' }}>
                          <span style={{ fontWeight: 'bold', marginLeft: '0.5rem', color: this.state.userPercent <= 1 ? '#7582ab' : '#fff' }}>{this.state.userPercent}
                            <span style={{ color: this.state.userPercent <= 2 ? '#7582ab' : '#fff' }}>%</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  }
                  <div className="note">
                    {this.state.userAmount < 500000 ?
                      <p>Индикатор прогресса отображает процент приближения к следующему уровню в зависимости от размера Вашего оборота. Для перехода на следующий уровень с размером бонуса <span className="strong">{this.state.userBonusNext}%</span> Вам осталось обменять <span className="strong">{parseFloat(this.state.finishProgress - this.state.userAmount).toFixed(2)} USD</span>.</p>
                      :
                      <p>Поздравляем, Вы достигли максимального уровня VIP!</p>
                    }
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Discount;