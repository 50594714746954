import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class Footer extends Component {
  constructor(props) {
    super(props);
    const newDate = new Date();
    const currentYear = newDate.getFullYear();
    this.state = {
      currentYear: currentYear,
      isOpened: false,
      isOpenedCookie: false
    }
  }

  componentDidMount() {
    const policy = localStorage.getItem('cookies-policy');
    this.timer = setTimeout(() => this.setState({ isOpened: policy ? false : true }), 1000);
    this.timer2 = setTimeout(() => this.setState({ isOpenedCookie: policy ? false : true }), 1500);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
    clearTimeout(this.timer2);
  }

  changeModal = () => {
    this.setState({ isOpened: !this.state.isOpened, isOpenedCookie: !this.state.isOpenedCookie });
    localStorage.setItem('cookies-policy', true);
  }

  render() {
    return (
      <div className="footer">
        <div className="footer-container">
          <div className="footer-menu">
            <div className="menu-column">
              <NavLink to="/agreement">Пользовательское соглашение</NavLink>
              <NavLink to="/about-us#instruction">Инструкция по обмену</NavLink>
              <NavLink to="/news">Новости и акции</NavLink>
              <NavLink to="/partners#ads">Реклама на сайте</NavLink>
            </div>
            <div className="menu-column">
              <NavLink to="/bonus">Бонусная программа</NavLink>
              <NavLink to="/partners">Партнерская программа</NavLink>
              <NavLink to="/reviews">Отзывы пользователей</NavLink>
              <NavLink to="/partners#monitoring">Экспорт курсов для мониторинга</NavLink>
            </div>
            <div className="menu-column">
              <NavLink to="/support">Центр поддержки</NavLink>
              <NavLink to="/agreement#privacy-policy">Политика конфиденциальности</NavLink>
              <NavLink to="/agreement#cookies-policy">Политика использования cookie</NavLink>
              <NavLink to="/agreement#aml-policy">Политика AML/KYC/KYT</NavLink>
            </div>
            <div className="menu-column">
              <div>Будьте всегда с нами!</div>
              <div className="social-links">
                <a href="tg://resolve?domain=wmsimcom"><span className="icon-telegram icon-medium"></span></a>
                <a href="https://twitter.com/wmsimcom" target="_blank" rel="nofollow noopener noreferrer"><span className="icon-twitter icon-medium"></span></a>
                <span className="icon-facebook icon-medium"></span>
                <span className="icon-instagram icon-medium"></span>
                <span className="icon-youtube icon-medium"></span>
                <span className="icon-vk icon-medium"></span>
              </div>
              <div className="language">
                <span id="language"><span className="ru icon-medium"></span><span>Russian</span></span>
                <a href="https://passport.webmoney.ru/asp/certview.asp?wmid=394929061825" target="_blank" rel="nofollow noopener noreferrer"><span className="icon-wm-verified"></span></a>
              </div>
            </div>
          </div>
          <div className="footer-copyright">
            <p>&copy; 2009-{this.state.currentYear} {process.env.REACT_APP_NAME} - Центр обмена электронных валют и криптовалют.</p>
            <p>Все логотипы и торговые марки на данном сайте являются собственностью их владельцев.</p>
          </div>
        </div>
        <div className="footer-fixed" id="fixed-footer">
          <div className="footer-fixed-menu">
            <div className="fixed-menu-column"><a href="/"><span className="icon-in icon-medium"></span>Обмен</a></div>
            <div className="fixed-menu-column" id="fixed-chat"><NavLink to="/support"><span className="icon-faq icon-medium"></span>Онлайн-чат</NavLink></div>
            {!this.props.accessToken ?
              <div className="fixed-menu-column" id="fixed-login" onClick={() => this.props.changeModal()}><div className="fixed-login"><span className="icon-user icon-medium"></span>Войти</div></div>
              :
              <div className="fixed-menu-column"><NavLink to="/profile/orders"><span className="icon-user icon-medium"></span>Кабинет</NavLink></div>
            }
          </div>
        </div>
        <div className={"modal-overlay" + (this.state.isOpened ? " open" : "")}>
          <div className={"cookie-fixed" + (this.state.isOpenedCookie ? " open" : "")}>
            <div className="cookie-content">
              <div className="cookie-column">Мы используем файлы cookie для обеспечения наилучшей работы нашего сайта, персонализации контента и анализа сетевого трафика согласно нашей <NavLink to="/agreement#cookies-policy">Политики использования cookie</NavLink>. Нажимая ПРИНЯТЬ или продолжая просмотр сайта, Вы разрешаете их использование.</div>
              <div className="cookie-column"><span className="cookie-agree" onClick={this.changeModal}>ПРИНЯТЬ</span></div>
            </div>
          </div>
        </div>
        <NavLink to="/bonus#diamonds" className="diamonds"></NavLink>
      </div>
    );
  }
}

export default Footer;