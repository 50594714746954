import React, { Component } from 'react';
import Tooltip from './tooltip';

class Withdraw extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            cbrUSD: '',
            systemSelected: 'WebMoney WMZ',
            iconSelected: 'icon-webmoney',
            purseSelected: 'Кошелек WMZ, Z111222333444',
            patternSelected: '^[Z,z]{1}[0-9]{12}$',
            systemSelect: 'QIWI RUB',
            iconSelect: 'icon-qiwi',
            purseSelect: 'Кошелек QIWI, 79111222333',
            patternSelect: '^[0-9]{5,16}$',
            isOpened: false,
            isOpenedSystem: false,
            withdrawAmount: '',
            withdrawWallet: '',
            isSubmitted: {},
            isDisabled: false,
            showToolTip: [],
            showDanger: [],
            pattern: [["withdrawAmount", false], ["withdrawWallet", false]]
        }
        this.submitForm = this.submitForm.bind(this);
        this.insertAmount = this.insertAmount.bind(this);
        this.choosePaymentSystem = this.choosePaymentSystem.bind(this);
        this.changePaymentSystem = this.changePaymentSystem.bind(this);
    }

    loadingData() {
        fetch('/api/courses-cbr.json?nocache=' + (new Date()).getTime(), {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 'loading': true }),
        })
            .then(response => response.json())
            .then(result =>
                this.setState({
                    data: result.data,
                    cbrUSD: result.data.map(item => item.USD)
                }));
    }

    componentDidMount() {
        this.loadingData();
        this.timer = setInterval(() => this.loadingData(), 15000);
        this.timer2 = setTimeout(() => this.setState({ isOpened: this.props.isOpened ? true : false }), 1);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    openToolTip(i) {
        const openStatus = this.state.showToolTip.slice();
        openStatus[i] = true;
        this.setState({ showToolTip: openStatus });
    }

    closeToolTip(i) {
        if (i === undefined) {
            this.setState({ showToolTip: [] });
        } else {
            const closeStatus = this.state.showToolTip.slice();
            closeStatus[i] = false;
            this.setState({ showToolTip: closeStatus });
        }
    }

    openDanger(i) {
        const openStatus = this.state.showDanger;
        openStatus[i] = true;
        this.setState({ showDanger: openStatus });
    }

    closeDanger(i) {
        if (i === undefined) {
            this.setState({ showDanger: [] });
        } else {
            const closeStatus = this.state.showDanger;
            closeStatus[i] = false;
            this.setState({ showDanger: closeStatus });
        }
    }

    clickForm = a => event => {
        this.closeToolTip(event.target.name);
        const pattern = new RegExp(a);
        if (event.target.value !== '' || (event.target.value === '' && this.state.showDanger[event.target.name] === true)) {
            if (!pattern.test(event.target.value)) {
                this.openDanger(event.target.name);
                this.openToolTip(event.target.name);
            } else {
                this.closeDanger(event.target.name);
                this.closeToolTip(event.target.name);
            }
        }
    }

    blurForm = a => event => {
        this.closeToolTip();
        const pattern = new RegExp(a);
        if (!pattern.test(event.target.value)) {
            this.openDanger(event.target.name);
        }
    }

    changeForm = a => event => {
        if (event.target.name === 'withdrawAmount') {
            event.target.value = event.target.value.replace(/[^\d.]*/g, '').replace(/([.])[.]+/g, '$1').replace(/^[^\d]*(\d+([.]\d{0,2})?).*$/g, '$1').replace(/^0+[^.]/, /[0-9]\.?[0-9]/.test(event.target.value) ? '0' : event.target.value);
        }
        if (event.target.name === 'withdrawWallet') {
            event.target.value = (event.target.value).toUpperCase();
        }
        this.setState({ [event.target.name]: event.target.value })
        const pattern = new RegExp(a);
        if (event.target.value !== '') {
            if (pattern.test(event.target.value)) {
                this.closeDanger(event.target.name);
                this.closeToolTip(event.target.name);
            }
        } else {
            this.closeToolTip(event.target.name);
            this.closeDanger(event.target.name);
        }
    }

    insertAmount() {
        this.setState({ withdrawAmount: this.props.referralsAvailable });
        this.closeDanger('withdrawAmount');
        this.closeToolTip('withdrawAmount');
    }

    choosePaymentSystem() {
        this.setState({ isOpenedSystem: !this.state.isOpenedSystem });
    }

    changePaymentSystem() {
        this.loadingData();
        this.closeDanger('withdrawWallet');
        this.setState({ withdrawWallet: '', systemSelected: this.state.systemSelect, iconSelected: this.state.iconSelect, purseSelected: this.state.purseSelect, patternSelected: this.state.patternSelect, systemSelect: this.state.systemSelected, iconSelect: this.state.iconSelected, purseSelect: this.state.purseSelected, patternSelect: this.state.patternSelected });
        this.choosePaymentSystem();
    }

    submitForm(e) {
        e.preventDefault();

        if (this.state.withdrawAmount === '' || (this.state.withdrawAmount && this.state.withdrawAmount < 5) || (this.state.withdrawAmount && this.state.withdrawAmount > parseFloat(this.props.referralsAvailable))) {
            this.openDanger('withdrawAmount');
        }
        if (this.state.withdrawWallet === '') {
            this.openDanger('withdrawWallet');
        }

        var sortDanger = this.state.pattern.map(pattern => Object.entries(this.state.showDanger).find(item => item[0] === pattern[0])).filter(item => item !== undefined);
        var trueProps = sortDanger.filter(([key, value]) => value === true);

        if (trueProps.length > 0) {
            if (this.state.withdrawAmount > parseFloat(this.props.referralsAvailable) && trueProps[0][0] === 'withdrawAmount') {
                this.openToolTip('withdrawAmountCheck');
            } else {
                this.openToolTip(trueProps[0][0]);
            }
        } else {
            this.setState({ isDisabled: true, isSubmitted: { submitForm: true } });
            this.props.withdrawFunds(this.state.withdrawAmount, this.state.withdrawWallet, this.state.systemSelected);
        }
    }

    render() {
        return (
            <div className={"modal-overlay" + (this.props.isOpened ? " open" : " close")}>
                <form autoComplete="off" onSubmit={this.submitForm}>
                    <div className={"modal-content modal-medium" + (this.state.isOpened ? " open" : "")}>
                        <div className='modal-close' onClick={() => this.props.changeModal()}><span className="icon-close icon-small"></span></div>
                        <div className="modal-title">Вывод средств</div>
                        <div className="modal-note first">Минимальная сумма для вывода 5.00 USD</div>
                        <div className="field">
                            <input type="text" className={"input" + (this.state.showDanger['withdrawAmount'] ? " input-danger" : "")} id="withdrawAmount" name="withdrawAmount" value={this.state.withdrawAmount} autoComplete="off" placeholder="Сумма к выводу, USD" maxLength="10" onClick={this.clickForm('^([0-9\\.])+$')} onBlur={this.blurForm('^([0-9\\.])+$')} onChange={this.changeForm('^([0-9\\.])+$')} />
                            <div className={"max-amount" + (this.state.withdrawAmount === this.props.referralsAvailable ? ' active' : '')} onClick={this.insertAmount}>MAX</div>
                            <Tooltip status={this.state.showToolTip['withdrawAmount']} closeToolTip={() => this.closeToolTip('withdrawAmount')} text="Минимальная сумма для вывода 5.00 USD." />
                            <Tooltip status={this.state.showToolTip['withdrawAmountCheck']} closeToolTip={() => this.closeToolTip('withdrawAmountCheck')} text={"Доступно для вывода " + this.props.referralsAvailable + " USD."} />
                        </div>
                        <div className="field">
                            <div className="button-exchange" onClick={this.choosePaymentSystem}>
                                <span className={this.state.iconSelected + " icon-big"}></span>
                                <span className="exchange-system-name">{this.state.systemSelected}</span>
                                <div className="field-arrow-icon">
                                    <div className="icon-arrow icon-default"></div>
                                </div>
                            </div>
                        </div>
                        <div style={{ position: 'relative' }}>
                            <div className={"field-absolute" + (this.state.isOpenedSystem ? " open" : "")}>
                                <div className="button-exchange" onClick={this.changePaymentSystem}>
                                    <span className={this.state.iconSelect + " icon-big"}></span>
                                    <span className="exchange-system-name">{this.state.systemSelect}</span>
                                </div>
                            </div>
                        </div>
                        <div className="field">
                            <input type="text" className="input input-disabled" id="paymentAmount" name="paymentAmount" value={this.state.withdrawAmount > 0 ? this.state.systemSelected === 'QIWI RUB' ? (this.state.withdrawAmount * this.state.cbrUSD).toFixed(2) : this.state.withdrawAmount : ''} autoComplete="off" placeholder={"Сумма к выводу, " + (this.state.systemSelected === 'QIWI RUB' ? "RUB" : "WMZ")} maxLength="10" disabled="disabled" />
                            <div className="field-icon"><span className={this.state.iconSelected + " icon-medium"}></span></div>
                        </div>
                        <div className="field">
                            <input type="text" className={"input" + (this.state.showDanger['withdrawWallet'] ? " input-danger" : "")} id="withdrawWallet" name="withdrawWallet" value={this.state.withdrawWallet} autoComplete="off" placeholder={this.state.purseSelected} onClick={this.clickForm(this.state.patternSelected)} onBlur={this.blurForm(this.state.patternSelected)} onChange={this.changeForm(this.state.patternSelected)} />
                            <div className="field-icon"><span className={this.state.iconSelected + " icon-medium"}></span></div>
                            <Tooltip status={this.state.showToolTip['withdrawWallet']} closeToolTip={() => this.closeToolTip('withdrawWallet')} text={"Введите корректный " + this.state.purseSelected + "."} />
                        </div>
                        <button className={"button" + (this.state.isSubmitted['submitForm'] ? " button-loading" : "")} disabled={this.state.isDisabled} type="submit" onBlur={this.blurForm()}>Вывести</button>
                        <div className="modal-note last"><span id="cancel" onClick={() => this.props.changeModal()}>Отменить</span></div>
                    </div>
                </form>
            </div>
        )
    }
}

export default Withdraw;