import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { AccountMenu } from '../../components/account_menu';

class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      dataGlobal: [],
      isFetching: true,
      isSorting: false,
      sortItem: 0,
      currentItem: 0,
      totalLoading: 0,
      totalCount: 0,
      showAdditionalInfo: [],
      isOpenedMenu: false
    };
    this.openAdditionalInfo = this.openAdditionalInfo.bind(this);
    this.accountSection = React.createRef();
    this.menuSection = React.createRef();
    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  loadingDataGlobal() {
    fetch('/api/global.json?nocache=' + (new Date()).getTime())
      .then(response => response.json())
      .then(result =>
        this.setState({
          dataGlobal: result.data
        }));
  }

  loadingData() {
    if (this.state.isFetching) {
      fetch('/api/profile/orders', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.props.accessToken,
        },
        body: JSON.stringify({ 'status': this.state.sortItem, 'limit': 10, 'item': this.state.currentItem }),
      })
        .then(response => { this.setState({ totalCount: response.headers.get('total-count') }); return response.json() })
        .then(result => {
          if (this.state.data.length > 0) {
            this.setState({ ...this.state.data, ...this.state.data[0].orders.push(...result.data[0].orders), loading: false, totalLoading: this.state.totalLoading + result.data[0].orders.length });
          } else {
            this.setState({ data: result.data, loading: false, totalLoading: result.data[0].orders.length });
          }
        }, (error) => {
          if (error) {
            localStorage.removeItem('token');
            window.location.href = '/?login=true';
          }
        })
        .finally(() => this.setState({ isFetching: false }));
    }
  }

  scrollHadler = (e) => {
    if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) <= 240 && this.state.totalLoading < this.state.totalCount && !this.state.isFetching) {
      this.setState({ isFetching: true, currentItem: this.state.currentItem + 10 });
      this.loadingData();
    }
  }

  componentDidMount() {
    if (!this.props.accessToken) {
      window.location.href = '/?login=true';
    }
    this.loadingDataGlobal();
    this.loadingData();
    document.title = 'Мои заявки | ' + process.env.REACT_APP_NAME;
    document.addEventListener('scroll', this.scrollHadler);
    document.addEventListener('mousedown', this.closeMenu);
  }

  componentDidUpdate() {
    if (!this.props.accessToken) {
      window.location.href = '/?login=true';
    }
    if (this.state.isSorting) {
      this.setState({ isSorting: false });
      this.loadingData();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.scrollHadler);
    document.removeEventListener('mousedown', this.closeMenu);
  }

  changeSort(i) {
    this.setState({ isFetching: true, isSorting: true, currentItem: 0, sortItem: i, data: [], loading: true, showAdditionalInfo: [] });
  }

  openAdditionalInfo(i) {
    const openStatus = this.state.showAdditionalInfo.slice();
    if (this.state.showAdditionalInfo[i] === true) {
      openStatus[i] = false;
    } else {
      openStatus[i] = true;
    }
    this.setState({ showAdditionalInfo: openStatus });
  }

  showMenu() {
    this.setState({ isOpenedMenu: !this.state.isOpenedMenu });
  }

  closeMenu(e) {
    if (this.accountSection.current && !this.accountSection.current.contains(e.target)) {
      if (this.state.isOpenedMenu && this.menuSection.current && !this.menuSection.current.contains(e.target)) {
        this.setState({ isOpenedMenu: false });
      }
    }
  }

  render() {
    const count = this.state.data.map(obj => Object.keys(obj.orders).length)[0];
    const orders = this.state.data.map(item => item.orders.sort((a, b) => b.id - a.id).map((item, index) => {
      const incomeClass = this.state.dataGlobal.map(item1 => item1.paymentSystems.find(item1 => item1.id === item.income_payment_system_id).attributes.icon_css_class);
      const outcomeClass = this.state.dataGlobal.map(item2 => item2.paymentSystems.find(item2 => item2.id === item.outcome_payment_system_id).attributes.icon_css_class);
      return (
        <div className="user-order-item cursor" key={index} onClick={() => this.openAdditionalInfo('order' + item.id)}>
          <div className="field-arrow-icon">
            <div className={'icon-arrow icon-small' + (this.state.showAdditionalInfo['order' + item.id] ? ' rotate' : '')}></div>
          </div>
          <div className="user-order-block">
            <div className="user-order-column">
              <div className="user-order-income"><span className={'icon-default ' + incomeClass}></span><span className="user-order-amount">{parseFloat(item.income_amount).toFixed(item.income_crypto ? 8 : 2)} {(item.income_payment_system_name).replace(/([^<]*)\s([A-Za-z]+)/, '$2')}</span></div>
              <div className="user-order-info">{(item.income_payment_system_name).replace(/([^<]*)\s([A-Za-z]+)/, '$1')}</div>
              <div className={!this.state.showAdditionalInfo['order' + item.id] ? "user-order-additional-info" : "user-order-additional-info-show"}>{item.income_account}</div>
            </div>
            <div className="user-order-column">
              <div className="user-order-outcome"><span className={'icon-default ' + outcomeClass}></span><span className="user-order-amount">{parseFloat(item.outcome_amount).toFixed(item.outcome_crypto ? 8 : 2)} {(item.outcome_payment_system_name).replace(/([^<]*)\s([A-Za-z]+)/, '$2')}</span></div>
              <div className="user-order-info">{(item.outcome_payment_system_name).replace(/([^<]*)\s([A-Za-z]+)/, '$1')}</div>
              <div className={!this.state.showAdditionalInfo['order' + item.id] ? "user-order-additional-info" : "user-order-additional-info-show"}>{item.outcome_account}</div>
            </div>
            <div className="user-order-column">
              <div className="user-order-number">#{item.id}</div>
              <div className="user-order-info">{(item.date).replace(/([^<]*)\s([^<]*)/, '$1')}</div>
              <div className={!this.state.showAdditionalInfo['order' + item.id] ? "user-order-additional-info" : "user-order-additional-info-show"}>{(item.date).replace(/([^<]*)\s([^<]*)/, '$2')}</div>
            </div>
            <div className="user-order-column">
              <div className="user-order-status">{item.status === 1 ? 'Ожидание' : item.status === 2 ? 'Проверка' : item.status === 3 ? 'Обработка' : item.status === 4 ? 'Успешно' : item.status === 5 ? 'Ошибка' : null}</div>
              <div className="user-order-info">{item.type === 'auto' ? 'Автомат' : 'Ручной'}</div>
              <div className={!this.state.showAdditionalInfo['order' + item.id] ? "user-order-additional-info" : "user-order-additional-info-show"}>{item.status === 1 ? 'Заявка ожидает оплаты' : item.status === 2 ? 'Заявка проверяется' : item.status === 3 ? 'Заявка обрабатывается' : item.status === 4 ? 'Заявка завершена' : item.status === 5 ? 'Заявка отменена' : null}</div>
            </div>
          </div>
        </div>
      )
    }));

    return (
      <div className="page" >
        <div className="page-content">
          <div className="account">
            <div className="account-column">
              <div className="account-section" onClick={this.showMenu} ref={this.accountSection}>
                <span className="account-section-title">Кабинет</span>
                <span className={'icon-arrow icon-default' + (this.state.isOpenedMenu ? ' rotate' : '')}></span>
              </div>
              <div className={'account-menu' + (this.state.isOpenedMenu ? ' open' : '')} ref={this.menuSection}>{AccountMenu}</div>
            </div>
            <div className="account-column">
              <div className="account-content">
                <h1>Мои заявки</h1>
                <div className="icon-data icon-main"></div>
              </div>
              <div className="user-menu">
                <NavLink to="/profile/orders" onClick={() => this.changeSort(0)} className="user-nav-item" activeClassName={this.state.sortItem === 0 ? "user-nav-item-active" : null}>Все</NavLink>
                <NavLink to="/profile/orders" onClick={() => this.changeSort(4)} className="user-nav-item" activeClassName={this.state.sortItem === 4 ? "user-nav-item-active" : null}>Успешно</NavLink>
                <NavLink to="/profile/orders" onClick={() => this.changeSort(3)} className="user-nav-item" activeClassName={this.state.sortItem === 3 ? "user-nav-item-active" : null}>В обработке</NavLink>
                <NavLink to="/profile/orders" onClick={() => this.changeSort(2)} className="user-nav-item" activeClassName={this.state.sortItem === 2 ? "user-nav-item-active" : null}>На проверке</NavLink>
                <NavLink to="/profile/orders" onClick={() => this.changeSort(5)} className="user-nav-item" activeClassName={this.state.sortItem === 5 ? "user-nav-item-active" : null}>Ошибка</NavLink>
                <NavLink to="/profile/orders" onClick={() => this.changeSort(1)} className="user-nav-item" activeClassName={this.state.sortItem === 1 ? "user-nav-item-active" : null}>В ожидании</NavLink>
              </div>
              {count !== 0 ?
                this.state.loading ?
                  <div className="data-loading"></div>
                  :
                  <div>
                    <div className="user-orders">
                      <div className="user-order-menu">
                        <div className="user-order-block">
                          <div className="user-order-column">Отдадите</div>
                          <div className="user-order-column">Получите</div>
                          <div className="user-order-column">Номер и дата</div>
                          <div className="user-order-column">Статус</div>
                        </div>
                      </div>
                    </div>
                    <div className="user-orders">
                      {orders}
                    </div>
                    {this.state.totalLoading < this.state.totalCount ?
                      <div className="data-loading next"></div>
                      :
                      null}
                  </div>
                :
                <div className="account-description-empty">
                  <div className="field"><span className="icon-empty icon-big"></span></div>
                  <div className="field">У Вас еще нет заявок</div>
                  <div><a className="button button-middle" href="/">Создать заявку</a></div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Orders;