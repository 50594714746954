import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { AccountMenu } from '../../components/account_menu';

class Promotion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isOpenedMenu: false,
      notification: '',
      showNotice: false,
      widgetLocale: 'ru',
      widgetType: 'vertical',
      widgetOutline: 'border',
      widgetTarget: 'blank',
      incomeSystemId: '',
      outcomeSystemId: '',
      incomeSystem: '',
      outcomeSystem: '',
      curFrom: '',
      curTo: '',
      incomeClass: '',
      outcomeClass: '',
      directionRate: '',
      incomeRate: '',
      outcomeRate: '',
      incomeCrypto: '',
      outcomeCrypto: '',
      incomeAmount: '',
      outcomeAmount: '',
      incomeAmountUser: '',
      outcomeAmountUser: '',
      incomeAmountCurrency: '',
      outcomeAmountCurrency: '',
      isCheckedTypeVertical: true,
      isCheckedTypeHorizontal: false,
      isCheckedOutlineBorder: true,
      isCheckedOutlineShadow: false,
      isCheckedOutlineNone: false,
      isCheckedTargetBlank: true,
      isCheckedTargetLink: false,
      isOpenedIncomeSystem: false,
      isOpenedOutcomeSystem: false,
      isOpenedIncomeSystemPromo: false,
      isOpenedOutcomeSystemPromo: false,
      selectPosition: 0,
      selectCheckPosition: false
    }
    this.accountSection = React.createRef();
    this.menuSection = React.createRef();
    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.changeToggleTypeVertical = this.changeToggleTypeVertical.bind(this);
    this.changeToggleTypeHorizontal = this.changeToggleTypeHorizontal.bind(this);
    this.changeToggleOutlineBorder = this.changeToggleOutlineBorder.bind(this);
    this.changeToggleOutlineShadow = this.changeToggleOutlineShadow.bind(this);
    this.changeToggleOutlineNone = this.changeToggleOutlineNone.bind(this);
    this.changeToggleTargetBlank = this.changeToggleTargetBlank.bind(this);
    this.changeToggleTargetLink = this.changeToggleTargetLink.bind(this);
    this.showIncomeSystemPromo = this.showIncomeSystemPromo.bind(this);
    this.showOutcomeSystemPromo = this.showOutcomeSystemPromo.bind(this);
    this.showIncomeSystem = this.showIncomeSystem.bind(this);
    this.showOutcomeSystem = this.showOutcomeSystem.bind(this);
    this.closeIncomeSystemPromo = this.closeIncomeSystemPromo.bind(this);
    this.closeOutcomeSystemPromo = this.closeOutcomeSystemPromo.bind(this);
    this.closeIncomeSystem = this.closeIncomeSystem.bind(this);
    this.closeOutcomeSystem = this.closeOutcomeSystem.bind(this);
    this.incomeSectionPromo = React.createRef();
    this.outcomeSectionPromo = React.createRef();
    this.incomeSelectPromo = React.createRef();
    this.outcomeSelectPromo = React.createRef();
    this.incomeSection = React.createRef();
    this.outcomeSection = React.createRef();
    this.incomeSelect = React.createRef();
    this.outcomeSelect = React.createRef();
    this.changeAmount = this.changeAmount.bind(this);
  }

  loadingData() {
    fetch('/api/global.json?nocache=' + (new Date()).getTime())
      .then(response => response.json())
      .then(result => {
        const incomeSystemId = result.data.map(item => item.initialDirection.incomePaymentSystemId)[0];
        const outcomeSystemId = result.data.map(item => item.initialDirection.outcomePaymentSystemId)[0];
        const directionRate = result.data.map(item => item.direction_rates[incomeSystemId][outcomeSystemId].v)[0];
        const incomeCrypto = result.data.map(item => item.paymentSystems.find(item => item.id === incomeSystemId).attributes.currency.is_crypto)[0];
        const outcomeCrypto = result.data.map(item => item.paymentSystems.find(item => item.id === outcomeSystemId).attributes.currency.is_crypto)[0];
        this.setState({
          data: result.data,
          incomeSystemId: incomeSystemId,
          outcomeSystemId: outcomeSystemId,
          incomeSystem: result.data.map(item => item.paymentSystems.find(item => item.id === incomeSystemId).attributes.name)[0],
          outcomeSystem: result.data.map(item => item.paymentSystems.find(item => item.id === outcomeSystemId).attributes.name)[0],
          curFrom: result.data.map(item => item.paymentSystems.find(item => item.id === incomeSystemId).attributes.letter_code)[0],
          curTo: result.data.map(item => item.paymentSystems.find(item => item.id === outcomeSystemId).attributes.letter_code)[0],
          incomeClass: result.data.map(item => item.paymentSystems.find(item => item.id === incomeSystemId).attributes.icon_css_class)[0],
          outcomeClass: result.data.map(item => item.paymentSystems.find(item => item.id === outcomeSystemId).attributes.icon_css_class)[0],
          directionRate: directionRate,
          incomeRate: result.data.map(item => item.direction_rates[incomeSystemId][outcomeSystemId].r.i),
          outcomeRate: result.data.map(item => item.direction_rates[incomeSystemId][outcomeSystemId].r.o),
          incomeCrypto: incomeCrypto,
          outcomeCrypto: outcomeCrypto,
          incomeAmount: result.data.map(item => item.direction_rates[incomeSystemId][outcomeSystemId].min_in)[0],
          outcomeAmount: (result.data.map(item => item.direction_rates[incomeSystemId][outcomeSystemId].min_in)[0] * directionRate).toFixed(outcomeCrypto ? 8 : 2),
          incomeAmountCurrency: result.data.map(item => item.paymentSystems.find(item => item.id === incomeSystemId).attributes.currency_iso_code)[0],
          outcomeAmountCurrency: result.data.map(item => item.paymentSystems.find(item => item.id === outcomeSystemId).attributes.currency_iso_code)[0]
        });
      })
  }

  componentDidMount() {
    if (!this.props.accessToken) {
      window.location.href = '/?login=true';
    }
    this.loadingData();
    document.title = 'Рекламные материалы | ' + process.env.REACT_APP_NAME;
    document.addEventListener('mousedown', this.closeMenu);
    document.addEventListener('mousedown', this.closeIncomeSystemPromo);
    document.addEventListener('mousedown', this.closeOutcomeSystemPromo);
    document.addEventListener('mousedown', this.closeIncomeSystem);
    document.addEventListener('mousedown', this.closeOutcomeSystem);
  }

  componentDidUpdate() {
    if (!this.props.accessToken) {
      window.location.href = '/?login=true';
    }
    if (this.state.selectCheckPosition) {
      if (this.incomeSectionPromo.current && document.getElementById('incomeSystemPromo').getBoundingClientRect().top > this.incomeSectionPromo.current.offsetHeight) {
        const a = this.incomeSectionPromo.current.offsetHeight + document.getElementById('incomeSystemPromo').offsetHeight;
        this.setState({ selectCheckPosition: false, selectPosition: '-' + a + 'px', selectTop: true });
      } else if (this.outcomeSectionPromo.current && document.getElementById('outcomeSystemPromo').getBoundingClientRect().top > this.outcomeSectionPromo.current.offsetHeight) {
        const a = this.outcomeSectionPromo.current.offsetHeight + document.getElementById('outcomeSystemPromo').offsetHeight;
        this.setState({ selectCheckPosition: false, selectPosition: '-' + a + 'px', selectTop: true });
      } else if (this.incomeSection.current && document.getElementById('incomeSystem').getBoundingClientRect().top > this.incomeSection.current.offsetHeight) {
        const a = this.incomeSection.current.offsetHeight + document.getElementById('incomeSystem').offsetHeight;
        this.setState({ selectCheckPosition: false, selectPosition: '-' + a + 'px', selectTop: true });
      } else if (this.outcomeSection.current && document.getElementById('outcomeSystem').getBoundingClientRect().top > this.outcomeSection.current.offsetHeight) {
        const a = this.outcomeSection.current.offsetHeight + document.getElementById('outcomeSystem').offsetHeight;
        this.setState({ selectCheckPosition: false, selectPosition: '-' + a + 'px', selectTop: true });
      } else {
        this.setState({ selectCheckPosition: false, selectPosition: '0', selectTop: false });
      }
    }
    if (this.state.showNotice) {
      this.timer = setTimeout(() => this.setState({ showNotice: false }), 5000);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.closeMenu);
    document.removeEventListener('mousedown', this.closeIncomeSystemPromo);
    document.removeEventListener('mousedown', this.closeOutcomeSystemPromo);
    document.removeEventListener('mousedown', this.closeIncomeSystem);
    document.removeEventListener('mousedown', this.closeOutcomeSystem);
    clearTimeout(this.timer);
  }

  showMenu() {
    this.setState({ isOpenedMenu: !this.state.isOpenedMenu });
  }

  closeMenu(e) {
    if (this.accountSection.current && !this.accountSection.current.contains(e.target)) {
      if (this.state.isOpenedMenu && this.menuSection.current && !this.menuSection.current.contains(e.target)) {
        this.setState({ isOpenedMenu: false });
      }
    }
  }

  copyText = (a) => {
    navigator.clipboard.writeText(a);
    this.setState({ notification: 'HTML-код скопирован.', showNotice: true });
  }

  changeToggleTypeVertical() {
    if (!this.state.isCheckedTypeVertical) {
      this.setState({ isCheckedTypeVertical: true, isCheckedTypeHorizontal: false, widgetType: 'vertical' });
    }
  }

  changeToggleTypeHorizontal() {
    if (!this.state.isCheckedTypeHorizontal) {
      this.setState({ isCheckedTypeVertical: false, isCheckedTypeHorizontal: true, widgetType: 'horizontal' });
    }
  }

  changeToggleOutlineBorder() {
    if (!this.state.isCheckedOutlineBorder) {
      this.setState({ isCheckedOutlineBorder: true, isCheckedOutlineShadow: false, isCheckedOutlineNone: false, widgetOutline: 'border' });
    }
  }

  changeToggleOutlineShadow() {
    if (!this.state.isCheckedOutlineShadow) {
      this.setState({ isCheckedOutlineBorder: false, isCheckedOutlineShadow: true, isCheckedOutlineNone: false, widgetOutline: 'shadow' });
    }
  }

  changeToggleOutlineNone() {
    if (!this.state.isCheckedOutlineNone) {
      this.setState({ isCheckedOutlineBorder: false, isCheckedOutlineShadow: false, isCheckedOutlineNone: true, widgetOutline: 'transparent' });
    }
  }

  changeToggleTargetBlank() {
    if (!this.state.isCheckedTargetBlank) {
      this.setState({ isCheckedTargetBlank: true, isCheckedTargetLink: false, widgetTarget: 'blank' });
    }
  }

  changeToggleTargetLink() {
    if (!this.state.isCheckedTargetLink) {
      this.setState({ isCheckedTargetBlank: false, isCheckedTargetLink: true, widgetTarget: 'link' });
    }
  }

  selectSystem = a => event => {
    const incomeSystemId = a === 'income' ? +event.target.getAttribute('data-id') : this.state.incomeSystemId;
    const incomeSystem = a === 'income' ? event.target.getAttribute('data-value') : this.state.incomeSystem;
    const incomeClass = a === 'income' ? event.target.getAttribute('data-class') : this.state.incomeClass;
    const outcomeSystemId = a === 'income' ? this.state.data.map(item => item.direction_rates[incomeSystemId][this.state.outcomeSystemId])[0] ? this.state.outcomeSystemId : this.state.data.map(item => item.paymentSystems.sort((a, b) => a.attributes.priority_out - b.attributes.priority_out).map(item => item.id).find(item => this.state.data.find(obj => Object.keys(obj.direction_rates[incomeSystemId]).some(obj => +obj === item))))[0] : +event.target.getAttribute('data-id');
    const outcomeSystem = a === 'income' ? this.state.data.map(item => item.paymentSystems.find(item => item.id === outcomeSystemId).attributes.name)[0] : event.target.getAttribute('data-value');
    const outcomeClass = a === 'income' ? this.state.data.map(item => item.paymentSystems.find(item => item.id === outcomeSystemId).attributes.icon_css_class)[0] : event.target.getAttribute('data-class');
    const directionRate = this.state.data.map(item => item.direction_rates[incomeSystemId][outcomeSystemId].v)[0];
    const incomeRate = this.state.data.map(item => item.direction_rates[incomeSystemId][outcomeSystemId].r.i);
    const outcomeRate = this.state.data.map(item => item.direction_rates[incomeSystemId][outcomeSystemId].r.o);
    const incomeCrypto = this.state.data.map(item => item.paymentSystems.find(item => item.id === incomeSystemId).attributes.currency.is_crypto)[0];
    const outcomeCrypto = this.state.data.map(item => item.paymentSystems.find(item => item.id === outcomeSystemId).attributes.currency.is_crypto)[0];
    const incomeAmount = this.state.incomeAmountUser !== '' ? +this.state.incomeAmount : this.state.outcomeAmountUser !== '' ? (this.state.outcomeAmount / directionRate).toFixed(incomeCrypto ? 8 : 2) : this.state.data.map(item => item.direction_rates[incomeSystemId][outcomeSystemId].min_in)[0];
    const outcomeAmount = this.state.outcomeAmountUser !== '' ? +this.state.outcomeAmount : (incomeAmount * directionRate).toFixed(outcomeCrypto ? 8 : 2);
    const incomeAmountCurrency = this.state.data.map(item => item.paymentSystems.find(item => item.id === incomeSystemId).attributes.currency_iso_code)[0];
    const outcomeAmountCurrency = this.state.data.map(item => item.paymentSystems.find(item => item.id === outcomeSystemId).attributes.currency_iso_code)[0];
    const curFrom = this.state.data.map(item => item.paymentSystems.find(item => item.id === incomeSystemId).attributes.letter_code)[0];
    const curTo = this.state.data.map(item => item.paymentSystems.find(item => item.id === outcomeSystemId).attributes.letter_code)[0];
    this.setState({ isOpenedIncomeSystem: false, isOpenedOutcomeSystem: false, isOpenedIncomeSystemPromo: false, isOpenedOutcomeSystemPromo: false, incomeSystemId: incomeSystemId, outcomeSystemId: outcomeSystemId, incomeSystem: incomeSystem, outcomeSystem: outcomeSystem, incomeClass: incomeClass, outcomeClass: outcomeClass, directionRate: directionRate, incomeRate: incomeRate, outcomeRate: outcomeRate, incomeCrypto: incomeCrypto, outcomeCrypto: outcomeCrypto, incomeAmount: +incomeAmount, outcomeAmount: +outcomeAmount, incomeAmountCurrency: incomeAmountCurrency, outcomeAmountCurrency: outcomeAmountCurrency, curFrom: curFrom, curTo: curTo });
  }

  showIncomeSystemPromo() {
    this.setState({ isOpenedIncomeSystemPromo: !this.state.isOpenedIncomeSystemPromo, selectCheckPosition: true });
  }

  showOutcomeSystemPromo() {
    this.setState({ isOpenedOutcomeSystemPromo: !this.state.isOpenedOutcomeSystemPromo, selectCheckPosition: true });
  }

  showIncomeSystem() {
    this.setState({ isOpenedIncomeSystem: !this.state.isOpenedIncomeSystem, selectCheckPosition: true });
  }

  showOutcomeSystem() {
    this.setState({ isOpenedOutcomeSystem: !this.state.isOpenedOutcomeSystem, selectCheckPosition: true });
  }

  closeIncomeSystemPromo(e) {
    if (this.incomeSelectPromo.current && !this.incomeSelectPromo.current.contains(e.target)) {
      if (this.state.isOpenedIncomeSystemPromo && this.incomeSectionPromo.current && !this.incomeSectionPromo.current.contains(e.target)) {
        this.setState({ isOpenedIncomeSystemPromo: false });
      }
    }
  }

  closeOutcomeSystemPromo(e) {
    if (this.outcomeSelectPromo.current && !this.outcomeSelectPromo.current.contains(e.target)) {
      if (this.state.isOpenedOutcomeSystemPromo && this.outcomeSectionPromo.current && !this.outcomeSectionPromo.current.contains(e.target)) {
        this.setState({ isOpenedOutcomeSystemPromo: false });
      }
    }
  }

  closeIncomeSystem(e) {
    if (this.incomeSelect.current && !this.incomeSelect.current.contains(e.target)) {
      if (this.state.isOpenedIncomeSystem && this.incomeSection.current && !this.incomeSection.current.contains(e.target)) {
        this.setState({ isOpenedIncomeSystem: false });
      }
    }
  }

  closeOutcomeSystem(e) {
    if (this.outcomeSelect.current && !this.outcomeSelect.current.contains(e.target)) {
      if (this.state.isOpenedOutcomeSystem && this.outcomeSection.current && !this.outcomeSection.current.contains(e.target)) {
        this.setState({ isOpenedOutcomeSystem: false });
      }
    }
  }

  changeAmount(event) {
    const pattern = new RegExp((event.target.name === 'incomeAmount' ? this.state.incomeCrypto : this.state.outcomeCrypto) ? /^[^\d]*(\d+([.]\d{0,8})?).*$/g : /^[^\d]*(\d+([.]\d{0,2})?).*$/g);
    event.target.value = event.target.value.replace(/^[^\d]*/g, '').replace(/^(0)([0-9])/g, '$2').replace(pattern, '$1');
    const incomeAmount = event.target.name === 'incomeAmount' ? event.target.value : +(parseFloat(this.state.data.map(item => (event.target.value / (item.direction_rates[this.state.incomeSystemId][this.state.outcomeSystemId].v)))[0]).toFixed(this.state.incomeCrypto ? 8 : 2));
    const outcomeAmount = event.target.name === 'outcomeAmount' ? event.target.value : +(parseFloat(this.state.data.map(item => (event.target.value * item.direction_rates[this.state.incomeSystemId][this.state.outcomeSystemId].v))[0]).toFixed(this.state.outcomeCrypto ? 8 : 2));
    this.setState({ incomeAmount: incomeAmount === '' ? '' : incomeAmount, outcomeAmount: outcomeAmount === '' ? '' : outcomeAmount, incomeAmountUser: event.target.name === 'incomeAmount' ? +event.target.value : '', outcomeAmountUser: event.target.name === 'outcomeAmount' ? +event.target.value : '' });
  }

  render() {
    let urlElements = window.location.href.split('/');

    const incomeList = this.state.data.map(item => item.paymentSystems.sort((a, b) => a.attributes.priority_in - b.attributes.priority_in).map((item, index) => {
      return <li key={index} className={this.state.incomeSystem === item.attributes.name ? 'select-system-active' : null} role="option" data-id={item.id} data-class={item.attributes.icon_css_class} data-value={item.attributes.name} onClick={this.selectSystem('income')} aria-selected={this.state.incomeSystem === item.attributes.name ? true : false}><span className={item.attributes.icon_css_class + " icon-medium"} data-id={item.id} data-class={item.attributes.icon_css_class} data-value={item.attributes.name} onClick={this.selectSystem('income')}></span><span data-id={item.id} data-class={item.attributes.icon_css_class} data-value={item.attributes.name} onClick={this.selectSystem('income')}>{item.attributes.name}</span></li>
    }));

    const outcomeList = this.state.data.map(item => item.paymentSystems.sort((a, b) => a.attributes.priority_out - b.attributes.priority_out).map((item, index) => this.state.data.map(obj => Object.keys(obj.direction_rates[this.state.incomeSystemId]).map(obj => +obj).map(rate => {
      return item.id === rate ? item.attributes.outcome_enabled === true ? <li key={index} className={this.state.outcomeSystem === item.attributes.name ? 'select-system-active' : null} role="option" data-id={item.id} data-class={item.attributes.icon_css_class} data-value={item.attributes.name} onClick={this.selectSystem('outcome')} aria-selected={this.state.outcomeSystem === item.attributes.name ? true : false}><span className={item.attributes.icon_css_class + " icon-medium"} data-id={item.id} data-class={item.attributes.icon_css_class} data-value={item.attributes.name} onClick={this.selectSystem('outcome')}></span><span data-id={item.id} data-class={item.attributes.icon_css_class} data-value={item.attributes.name} onClick={this.selectSystem('outcome')}>{item.attributes.name}</span></li> : null : null;
    }))));

    const widgetCode = <div>
      <h2>HTML-код виджета</h2>
      <p><textarea id="widgetCode" className="promo-widget-code" readOnly value={'<div id="wmsim-widget-container"></div>\n<script type="text/javascript" src="https://www.wmsim.com/scripts/widget.js"></script>\n<script type="text/javascript">\n    createWmsimWidget({\n        "locale": "' + this.state.widgetLocale + '",\n        "pid": "' + this.props.verifyToken + '",\n        "type": "' + this.state.widgetType + '",\n        "outline": "' + this.state.widgetOutline + '",\n        "target": "' + this.state.widgetTarget + '",\n        "from": "' + this.state.incomeSystem + '",\n        "to": "' + this.state.outcomeSystem + '"\n    });\n</script>'}></textarea></p>
      <button className="button" onClick={() => this.copyText(document.getElementById('widgetCode').value)}>Скопировать код</button>
    </div>;

    const widget = <div>
      <h2>Внешний вид</h2>
      <div className={"wmsim-widget " + this.state.widgetType + " " + this.state.widgetOutline}>
        <div className="exchange">
          <div className="exchange-column">
            <div className="exchange-content">
              <div className="exchange-title">Отдадите</div>
              <div className="icon-in icon-exchange"></div>
            </div>
            <div className="field">
              <input type="text" className="input" id="incomeAmount" name="incomeAmount" placeholder="Сумма к оплате, WMZ" autoComplete="off" maxLength="16" value={this.state.incomeAmount} onChange={this.changeAmount} />
              <div className="field-icon"><span className={this.state.incomeClass + ' icon-medium'}></span></div>
            </div>
            <div className="field select" onClick={this.showIncomeSystem} ref={this.incomeSelect}>
              <div className="field-icon left"><span className={this.state.incomeClass + ' icon-medium'}></span></div>
              <input type="text" className="input input-select" id="incomeSystem" name="incomeSystem" autoComplete="off" value={this.state.incomeSystem} readOnly />
              <div className="field-icon"><span className="icon-arrow icon-medium"></span></div>
            </div>
            {this.state.isOpenedIncomeSystem ?
              <div className="systems">
                <div className={"select-system" + (this.state.selectTop ? " top" : "")} style={{ marginTop: this.state.selectPosition }} ref={this.incomeSection}>
                  <ul>{incomeList}</ul>
                </div>
              </div>
              :
              null
            }
          </div>
          <div className="exchange-column">
            <div className="exchange-content">
              <div className="exchange-title">Получите</div>
              <div className="icon-out icon-exchange"></div>
            </div>
            <div className="field">
              <input type="text" className="input" id="outcomeAmount" name="outcomeAmount" placeholder="Сумма к получению, RUB" autoComplete="off" maxLength="16" value={this.state.outcomeAmount} onChange={this.changeAmount} />
              <div className="field-icon"><span className={this.state.outcomeClass + ' icon-medium'}></span></div>
            </div>
            <div className="field select" onClick={this.showOutcomeSystem} ref={this.outcomeSelect}>
              <div className="field-icon left"><span className={this.state.outcomeClass + ' icon-medium'}></span></div>
              <input type="text" className="input input-select" id="outcomeSystem" name="outcomeSystem" autoComplete="off" value={this.state.outcomeSystem} readOnly />
              <div className="field-icon"><span className="icon-arrow icon-medium"></span></div>
            </div>
            {this.state.isOpenedOutcomeSystem ?
              <div className="systems">
                <div className={"select-system" + (this.state.selectTop ? " top" : "")} style={{ marginTop: this.state.selectPosition }} ref={this.outcomeSection}>
                  <ul>{outcomeList}</ul>
                </div>
              </div>
              :
              null
            }
          </div>
          <div className="exchange-column">
            <div className="exchange-content">
              <div className="exchange-title">Курс обмена</div><a href={'https://www.wmsim.com/?pid=' + this.props.verifyToken + '&cur_from=' + this.state.curFrom + '&cur_to=' + this.state.curTo} target={this.state.widgetTarget === 'blank' ? '_blank' : '_self'} rel="noreferrer"><span className="logo"></span></a></div>
            <div className="field">
              <input type="text" className="input" id="exchangeRate" name="exchangeRate" value={parseFloat(this.state.incomeRate).toFixed(2) + ' ' + this.state.incomeAmountCurrency + ' = ' + parseFloat(this.state.outcomeRate).toFixed(2) + ' ' + this.state.outcomeAmountCurrency} readOnly />
            </div>
            <a href={'https://www.wmsim.com/?pid=' + this.props.verifyToken + '&cur_from=' + this.state.curFrom + '&cur_to=' + this.state.curTo} target={this.state.widgetTarget === 'blank' ? '_blank' : '_self'} rel="noreferrer" className="button">Обменять</a></div>
        </div>
      </div>
    </div>;

    return (
      <div className="page">
        <div className="page-content">
          <div className={"notice fade" + (this.state.showNotice ? " in" : "")}>{this.state.notification}</div>
          <div className="account">
            <div className="account-column">
              <div className="account-section" onClick={this.showMenu} ref={this.accountSection}>
                <span className="account-section-title">Кабинет</span>
                <span className={'icon-arrow icon-default' + (this.state.isOpenedMenu ? ' rotate' : '')}></span>
              </div>
              <div className={'account-menu' + (this.state.isOpenedMenu ? ' open' : '')} ref={this.menuSection}>{AccountMenu}</div>
            </div>
            <div className="account-column">
              <div className="account-content">
                <h1>Рекламные материалы</h1>
                <div className="icon-promotion icon-main"></div>
              </div>
              <div className="user-menu" >
                <NavLink to="/profile/promotion" className="user-nav-item" activeClassName={urlElements[4] === "promotion" ? "user-nav-item-active" : null}>Баннеры</NavLink>
                <NavLink to="#widget" className="user-nav-item" activeClassName={urlElements[4] === "promotion#widget" ? "user-nav-item-active" : null}>Виджет</NavLink>
                <NavLink to="#logo" className="user-nav-item" activeClassName={urlElements[4] === "promotion#logo" ? "user-nav-item-active" : null}>Логотип</NavLink>
              </div>
              {urlElements[4] === "promotion" ?
                <div>
                  <p>Для привлечения новых пользователей в рамках нашей партнерской программы воспользуйтесь рекламными баннерами с Вашей персональной партнерской ссылкой.</p>
                  <div className="promo">
                    <div className="promo-item">
                      <div>Баннер 240x400</div>
                      <div className="promo-image"><img src="https://www.wmsim.com/media/promo/wmsim240x400.png" style={{ maxWidth: '240px', maxHeight: '400px' }} alt="Центр обмена электронных валют и криптовалют" /></div>
                      <div className="promo-code">
                        <div className="promo-code-block">
                          <div className="promo-code-html">
                            <p>HTML-код баннера 240x400</p>
                            <p><textarea id="banner240x400" readOnly value={'<a target="_blank" href="https://www.wmsim.com/?pid=' + this.props.verifyToken + '"><img src="https://www.wmsim.com/media/promo/wmsim240x400.png" width="240" height="400" /></a>'}></textarea></p>
                            <button className="button" onClick={() => this.copyText(document.getElementById('banner240x400').value)}>Скопировать код</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="promo-item">
                      <div>Баннер 300x250</div>
                      <div className="promo-image"><img src="https://www.wmsim.com/media/promo/wmsim300x250.png" style={{ maxWidth: '300px', maxHeight: '250px' }} alt="Центр обмена электронных валют и криптовалют" /></div>
                      <div className="promo-code">
                        <div className="promo-code-block">
                          <div className="promo-code-html">
                            <p>HTML-код баннера 300x250</p>
                            <p><textarea id="banner300x250" readOnly value={'<a target="_blank" href="https://www.wmsim.com/?pid=' + this.props.verifyToken + '"><img src="https://www.wmsim.com/media/promo/wmsim300x250.png" width="300" height="250" /></a>'}></textarea></p>
                            <button className="button" onClick={() => this.copyText(document.getElementById('banner300x250').value)}>Скопировать код</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="promo-item">
                    <div>Баннер 728x90</div>
                    <div className="promo-image"><img src="https://www.wmsim.com/media/promo/wmsim728x90.png" style={{ maxWidth: '728px', maxHeight: '90px' }} alt="Центр обмена электронных валют и криптовалют" /></div>
                    <div className="promo-code">
                      <div className="promo-code-block">
                        <div className="promo-code-html banner728x90">
                          <p>HTML-код баннера 728x90</p>
                          <p><textarea id="banner728x90" readOnly value={'<a target="_blank" href="https://www.wmsim.com/?pid=' + this.props.verifyToken + '"><img src="https://www.wmsim.com/media/promo/wmsim728x90.png" width="728" height="90" /></a>'}></textarea></p>
                          <button className="button" onClick={() => this.copyText(document.getElementById('banner728x90').value)}>Скопировать код</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="promo-item">
                    <div>Баннер 468x60</div>
                    <div className="promo-image"><img src="https://www.wmsim.com/media/promo/wmsim468x60.png" style={{ maxWidth: '468px', maxHeight: '60px' }} alt="Центр обмена электронных валют и криптовалют" /></div>
                    <div className="promo-code">
                      <div className="promo-code-block">
                        <div className="promo-code-html banner468x60">
                          <p>HTML-код баннера 468x60</p>
                          <p><textarea id="banner468x60" readOnly value={'<a target="_blank" href="https://www.wmsim.com/?pid=' + this.props.verifyToken + '"><img src="https://www.wmsim.com/media/promo/wmsim468x60.png" width="468" height="60" /></a>'}></textarea></p>
                          <button className="button" onClick={() => this.copyText(document.getElementById('banner468x60').value)}>Скопировать код</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                :
                urlElements[4] === "promotion#widget" ?
                  <div className="promo-widget-container">
                    <p>Для увеличения Вашего вознаграждения в рамках нашей партнерской программы настройте внешний вид и разместите рекламный виджет с Вашей персональной партнерской ссылкой на страницах Вашего сайта.</p>
                    {this.state.widgetType === 'horizontal' ? widget : ''}
                    <div className="promo-widget">
                      <div className="promo-widget-item">
                        <h2>Настройки</h2>
                        <p>Формат</p>
                        <div className="field">
                          <label className="checkbox-content">
                            <input type="checkbox" className="checkbox" id="typeVertical" name="typeVertical" value="true" checked={this.state.isCheckedTypeVertical} onChange={this.changeToggleTypeVertical} />
                            <div className="checkbox-area" aria-checked={this.state.isCheckedTypeVertical ? "true" : "false"}>
                              <span className={"checkbox-toggle" + (this.state.isCheckedTypeVertical ? " active" : "")}></span>
                            </div>
                            Вертикальный
                          </label>
                        </div>
                        <div className="field">
                          <label className="checkbox-content">
                            <input type="checkbox" className="checkbox" id="typeHorizontal" name="typeHorizontal" value="false" checked={this.state.isCheckedTypeHorizontal} onChange={this.changeToggleTypeHorizontal} />
                            <div className="checkbox-area" aria-checked={this.state.isCheckedTypeHorizontal ? "true" : "false"}>
                              <span className={"checkbox-toggle" + (this.state.isCheckedTypeHorizontal ? " active" : "")}></span>
                            </div>
                            Горизонтальный
                          </label>
                        </div>
                        <p>Обводка</p>
                        <div className="field">
                          <label className="checkbox-content">
                            <input type="checkbox" className="checkbox" id="outlineBorder" name="outlineBorder" value="true" checked={this.state.isCheckedOutlineBorder} onChange={this.changeToggleOutlineBorder} />
                            <div className="checkbox-area" aria-checked={this.state.isCheckedOutlineBorder ? "true" : "false"}>
                              <span className={"checkbox-toggle" + (this.state.isCheckedOutlineBorder ? " active" : "")}></span>
                            </div>
                            Рамка
                          </label>
                        </div>
                        <div className="field">
                          <label className="checkbox-content">
                            <input type="checkbox" className="checkbox" id="outlineShadow" name="outlineShadow" value="false" checked={this.state.isCheckedOutlineShadow} onChange={this.changeToggleOutlineShadow} />
                            <div className="checkbox-area" aria-checked={this.state.isCheckedOutlineShadow ? "true" : "false"}>
                              <span className={"checkbox-toggle" + (this.state.isCheckedOutlineShadow ? " active" : "")}></span>
                            </div>
                            Тень
                          </label>
                        </div>
                        <div className="field">
                          <label className="checkbox-content">
                            <input type="checkbox" className="checkbox" id="outlineNone" name="outlineNone" value="false" checked={this.state.isCheckedOutlineNone} onChange={this.changeToggleOutlineNone} />
                            <div className="checkbox-area" aria-checked={this.state.isCheckedOutlineNone ? "true" : "false"}>
                              <span className={"checkbox-toggle" + (this.state.isCheckedOutlineNone ? " active" : "")}></span>
                            </div>
                            Без обводки
                          </label>
                        </div>
                        <p>Действие</p>
                        <div className="field">
                          <label className="checkbox-content">
                            <input type="checkbox" className="checkbox" id="targetBlank" name="targetBlank" value="true" checked={this.state.isCheckedTargetBlank} onChange={this.changeToggleTargetBlank} />
                            <div className="checkbox-area" aria-checked={this.state.isCheckedTargetBlank ? "true" : "false"}>
                              <span className={"checkbox-toggle" + (this.state.isCheckedTargetBlank ? " active" : "")}></span>
                            </div>
                            В новой вкладке
                          </label>
                        </div>
                        <div className="field">
                          <label className="checkbox-content">
                            <input type="checkbox" className="checkbox" id="targetLink" name="targetLink" value="false" checked={this.state.isCheckedTargetLink} onChange={this.changeToggleTargetLink} />
                            <div className="checkbox-area" aria-checked={this.state.isCheckedTargetLink ? "true" : "false"}>
                              <span className={"checkbox-toggle" + (this.state.isCheckedTargetLink ? " active" : "")}></span>
                            </div>
                            В том же окне
                          </label>
                        </div>
                        <p>К обмену по умолчанию</p>
                        <div className="field select" onClick={this.showIncomeSystemPromo} ref={this.incomeSelectPromo}>
                          <div className="field-icon left"><span className={this.state.incomeClass + ' icon-medium'}></span></div>
                          <input type="text" className="input input-select-white" id="incomeSystemPromo" name="incomeSystemPromo" autoComplete="off" value={this.state.incomeSystem} readOnly />
                          <div className="field-icon"><span className="icon-arrow icon-medium"></span></div>
                        </div>
                        {this.state.isOpenedIncomeSystemPromo ?
                          <div className="systems">
                            <div className={"select-system" + (this.state.selectTop ? " top" : "")} style={{ marginTop: this.state.selectPosition }} ref={this.incomeSectionPromo}>
                              <ul>{incomeList}</ul>
                            </div>
                          </div>
                          :
                          null
                        }
                        <p>К получению по умолчанию</p>
                        <div className="field select" onClick={this.showOutcomeSystemPromo} ref={this.outcomeSelectPromo}>
                          <div className="field-icon left"><span className={this.state.outcomeClass + ' icon-medium'}></span></div>
                          <input type="text" className="input input-select-white" id="outcomeSystemPromo" name="outcomeSystemPromo" autoComplete="off" value={this.state.outcomeSystem} readOnly />
                          <div className="field-icon"><span className="icon-arrow icon-medium"></span></div>
                        </div>
                        {this.state.isOpenedOutcomeSystemPromo ?
                          <div className="systems">
                            <div className={"select-system" + (this.state.selectTop ? " top" : "")} style={{ marginTop: this.state.selectPosition }} ref={this.outcomeSectionPromo}>
                              <ul>{outcomeList}</ul>
                            </div>
                          </div>
                          :
                          null
                        }
                      </div>
                      <div className="promo-widget-item">
                        {this.state.widgetType === 'vertical' ? widget : ''}
                        {this.state.widgetType === 'horizontal' ? widgetCode : ''}
                      </div>
                    </div>
                    {this.state.widgetType === 'vertical' ? widgetCode : ''}
                  </div>
                  :
                  <div>
                    <p>Для разработки рекламных материалов используйте один из наших логотипов, доступных для скачивания в популярных форматах AI (с возможностью редактирования размера и фона), PNG, JPG.</p>
                    <div className="promo">
                      <div className="promo-item">
                        <div>Логотип 260x60</div>
                        <div className="promo-image first"><img src="https://www.wmsim.com/media/promo/wmsim260x60.png" style={{ maxWidth: '260px', maxHeight: '60px' }} alt="Центр обмена электронных валют и криптовалют" /></div>
                        <div className="banner-download">
                          <span className="icon-download icon-big"></span>
                          <a className="banner-link" href="https://www.wmsim.com/media/promo/wmsim260x60.ai" download><span>AI [357 Kb]</span></a>
                          <a className="banner-link" href="https://www.wmsim.com/media/promo/wmsim260x60.png" download><span>PNG [5 Kb]</span></a>
                          <a className="banner-link last" href="https://www.wmsim.com/media/promo/wmsim260x60.jpg" download><span>JPG [10 Kb]</span></a>
                        </div>
                      </div>
                      <div className="promo-item">
                        <div>Логотип 240x200</div>
                        <div className="promo-image"><img src="https://www.wmsim.com/media/promo/wmsim240x200.png" style={{ maxWidth: '240px', maxHeight: '200px' }} alt="Центр обмена электронных валют и криптовалют" /></div>
                        <div className="banner-download">
                          <span className="icon-download icon-big"></span>
                          <a className="banner-link" href="https://www.wmsim.com/media/promo/wmsim240x200.ai" download><span>AI [372 Kb]</span></a>
                          <a className="banner-link" href="https://www.wmsim.com/media/promo/wmsim240x200.png" download><span>PNG [8 Kb]</span></a>
                          <a className="banner-link last" href="https://www.wmsim.com/media/promo/wmsim240x200.jpg" download><span>JPG [20 Kb]</span></a>
                        </div>
                      </div>
                    </div>
                  </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Promotion;