import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class Notfound extends Component {
  componentDidMount() {
    document.title = 'Страница не найдена | ' + process.env.REACT_APP_NAME;
    let meta = document.createElement("meta");
    meta.setAttribute("name", "prerender-status-code");
    meta.setAttribute("content", "404");
    document.getElementsByTagName("head")[0].appendChild(meta);
  }

  render() {
    return (
      <div className="page">
        <div className="page-content">
          <div className="default">
            <div className="default-column">
              <div className="default-menu">
                <div>
                  <NavLink to="/about-us" className="default-nav-item">О платформе {process.env.REACT_APP_NAME}</NavLink>
                  <NavLink to="/about-us#instruction" className="default-nav-item">Инструкция по обмену</NavLink>
                  <NavLink to="/about-us#directions" className="default-nav-item">Направления обмена</NavLink>
                </div>
              </div>
            </div>
            <div className="default-column">
              <div className="default-content">
                <h1>Страница не найдена</h1>
                <div className="icon-about icon-main"></div>
              </div>
              <div className="default-submenu" >
                <NavLink to="/about-us" className="user-nav-item">О платформе {process.env.REACT_APP_NAME}</NavLink>
                <NavLink to="/about-us#instruction" className="user-nav-item">Инструкция по обмену</NavLink>
                <NavLink to="/about-us#directions" className="user-nav-item">Направления обмена</NavLink>
              </div>
              <div>
                <div className="notfound-content">
                  <i>- Где я сейчас нахожусь???<br />- Вы зашли на секретную страницу, но здесь пока еще ничего нет...<br />- Пожалуй, мне нужно <a href="/">вернуться на главную</a>!</i>

                  <div className="notfound-arrow"></div>

                </div>


               


                <div className="notfound"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Notfound;