import React, { Component, Suspense } from 'react';
import { NavLink } from 'react-router-dom';
const AgreementLazy = React.lazy(() => import('./lazy/agreement'));
const PrivacyPolicyLazy = React.lazy(() => import('./lazy/privacy-policy'));
const CookiesPolicyLazy = React.lazy(() => import('./lazy/cookies-policy'));
const AmlPolicyLazy = React.lazy(() => import('./lazy/aml-policy'));

class Agreement extends Component {
  componentDidMount() {
    let title = 'Пользовательское Соглашение | ' + process.env.REACT_APP_NAME;
    let description = 'Настоящее Соглашение определяет условия покупки, продажи и обмена электронных валют, на основании которых предоставляются услуги на платформе WMSIM.';
    document.title = title;
    document.querySelector("meta[name='description']").setAttribute('content', description);
    document.querySelector("meta[property='og:title']").setAttribute('content', title);
    document.querySelector("meta[property='og:description']").setAttribute('content', description);
    document.querySelector("meta[property='og:url']").setAttribute('content', window.location.href);
    document.querySelector("link[rel='canonical']").setAttribute('href', window.location.href);
  }

  componentDidUpdate() {
    let urlElements = window.location.href.split('/');
    let title = urlElements[3] === "agreement#aml-policy" ? 'Политика AML/KYC/KYT | ' + process.env.REACT_APP_NAME : urlElements[3] === "agreement#privacy-policy" ? 'Политика конфиденциальности | ' + process.env.REACT_APP_NAME : urlElements[3] === "agreement#cookies-policy" ? 'Политика использования cookie | ' + process.env.REACT_APP_NAME : 'Пользовательское Соглашение | ' + process.env.REACT_APP_NAME;
    document.title = title;
    document.querySelector("meta[property='og:title']").setAttribute('content', title);
    document.querySelector("meta[property='og:url']").setAttribute('content', window.location.href);
    document.querySelector("link[rel='canonical']").setAttribute('href', window.location.href);
  }

  render() {
    let urlElements = window.location.href.split('/');
    var contentTitle;
    if (urlElements[3] === "agreement#aml-policy") {
      contentTitle = 'Политика AML/KYC/KYT';
    } else if (urlElements[3] === "agreement#privacy-policy") {
      contentTitle = 'Политика конфиденциальности';
    } else if (urlElements[3] === "agreement#cookies-policy") {
      contentTitle = 'Политика использования cookie';
    } else {
      contentTitle = 'Пользовательское Соглашение';
    }
    return (
      <div className="page">
        <div className="page-content">
          <div className="default">
            <div className="default-column">
              <div className="default-menu">
                <div>
                  <NavLink to="/agreement" className="default-nav-item" activeClassName={urlElements[3] === "agreement" ? "default-nav-item-active" : null}>Пользовательское Соглашение</NavLink>
                  <NavLink to="#privacy-policy" className="default-nav-item" activeClassName={urlElements[3] === "agreement#privacy-policy" ? "default-nav-item-active" : null}>Политика конфиденциальности</NavLink>
                  <NavLink to="#cookies-policy" className="default-nav-item" activeClassName={urlElements[3] === "agreement#cookies-policy" ? "default-nav-item-active" : null}>Политика использования cookie</NavLink>
                  <NavLink to="#aml-policy" className="default-nav-item" activeClassName={urlElements[3] === "agreement#aml-policy" ? "default-nav-item-active" : null}>Политика AML/KYC/KYT</NavLink>
                </div>
              </div>
            </div>
            <div className="default-column">
              <div className="default-content">
                <h1>{contentTitle}</h1>
                <div className="icon-data icon-main"></div>
              </div>
              <div className="default-submenu" >
                <NavLink to="/agreement" className="user-nav-item" activeClassName={urlElements[3] === "agreement" ? "user-nav-item-active" : null}>Соглашение</NavLink>
                <NavLink to="#privacy-policy" className="user-nav-item" activeClassName={urlElements[3] === "agreement#privacy-policy" ? "user-nav-item-active" : null}>Конфиденциальность</NavLink>
                <NavLink to="#cookies-policy" className="user-nav-item" activeClassName={urlElements[3] === "agreement#cookies-policy" ? "user-nav-item-active" : null}>Cookie</NavLink>
                <NavLink to="#aml-policy" className="user-nav-item" activeClassName={urlElements[3] === "agreement#aml-policy" ? "user-nav-item-active" : null}>AML/KYC/KYT</NavLink>
              </div>
              {urlElements[3] === "agreement#privacy-policy" ?
                <Suspense fallback={<div className="data-loading"></div>}>
                  <PrivacyPolicyLazy />
                </Suspense>
                : urlElements[3] === "agreement#cookies-policy" ?
                  <Suspense fallback={<div className="data-loading"></div>}>
                    <CookiesPolicyLazy />
                  </Suspense>
                  : urlElements[3] === "agreement#aml-policy" ?
                    <Suspense fallback={<div className="data-loading"></div>}>
                      <AmlPolicyLazy />
                    </Suspense>
                    :
                    <Suspense fallback={<div className="data-loading"></div>}>
                      <AgreementLazy />
                    </Suspense>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Agreement;
