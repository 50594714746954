import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class News extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      isFetching: true,
      currentItem: 0,
      totalLoading: 0,
      totalCount: 0,
    };
  }

  loadingData() {
    if (this.state.isFetching) {
      fetch('/api/news', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 'limit': 10, 'item': this.state.currentItem }),
      })
        .then(response => { this.setState({ totalCount: response.headers.get('total-count') }); return response.json() })
        .then(result => {
          if (this.state.data.length > 0) {
            this.setState({ ...this.state.data, ...this.state.data[0].news.push(...result.data[0].news), loading: false, totalLoading: this.state.totalLoading + result.data[0].news.length });
          } else {
            this.setState({ data: result.data, loading: false, totalLoading: result.data[0].news.length });
          }
        })
        .finally(() => this.setState({ isFetching: false }));
    }
  }

  scrollHadler = (e) => {
    if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) <= 240 && this.state.totalLoading < this.state.totalCount && !this.state.isFetching) {
      this.setState({ isFetching: true, currentItem: this.state.currentItem + 10 });
      this.loadingData();
    }
  }

  componentDidMount() {
    let title = 'Новости | ' + process.env.REACT_APP_NAME;
    let description = 'Главные новости об электронных деньгах и криптовалютах Bitcoin, Ethereum, USDT, альткоинах и многом другом. Будьте в курсе последних событий в мире криптовалюты.';
    document.title = title;
    document.querySelector("meta[name='description']").setAttribute('content', description);
    document.querySelector("meta[property='og:title']").setAttribute('content', title);
    document.querySelector("meta[property='og:description']").setAttribute('content', description);
    document.querySelector("meta[property='og:url']").setAttribute('content', window.location.href);
    document.querySelector("link[rel='canonical']").setAttribute('href', window.location.href);
    this.loadingData();
    document.addEventListener('scroll', this.scrollHadler);
  }

  componentDidUpdate() {
    let urlElements = window.location.href.split('/');
    let title = urlElements[3] === "news#promotions" ? 'Акции | ' + process.env.REACT_APP_NAME : urlElements[3] === "news#contests" ? 'Конкурсы | ' + process.env.REACT_APP_NAME : 'Новости | ' + process.env.REACT_APP_NAME;
    document.title = title;
    document.querySelector("meta[property='og:title']").setAttribute('content', title);
    document.querySelector("meta[property='og:url']").setAttribute('content', window.location.href);
    document.querySelector("link[rel='canonical']").setAttribute('href', window.location.href);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.scrollHadler);
  }

  openLink = (url, width, height) => {
    window.open(url, "", "scrollbars=1,resizable=1,menubar=0,toolbar=0,status=0,left=" + ((window.innerWidth - width) / 2) + ",top=" + ((window.innerHeight - height) / 2) + ",width=" + width + ",height=" + height);
  }

  render() {
    const news = this.state.data.map(item => item.news.sort((a, b) => b.id - a.id).map((item, index) => {
      return (
        <div key={index}>
          <div className="news-date-block">
            <span className="icon-date icon-default"></span>
            <span className="news-date">{new Intl.DateTimeFormat('ru', { year: 'numeric', month: 'long', day: '2-digit' }).format(item.timestamp + '000').slice(0, -3)}</span>
            <div className="news-social">
              <span className="icon-telegram icon-default" onClick={() => this.openLink('https://t.me/share/url?url=' + encodeURIComponent(this.props.verifyToken ? process.env.REACT_APP_URL + '/?pid=' + this.props.verifyToken : process.env.REACT_APP_URL) + '&text=' + encodeURIComponent(item.title + '\n\n' + item.new), '600', '400')}></span>
              <span className="icon-twitter icon-default" onClick={() => this.openLink('https://twitter.com/intent/tweet?text=' + encodeURIComponent(item.title) + '&url=' + encodeURIComponent(this.props.verifyToken ? process.env.REACT_APP_URL + '/?pid=' + this.props.verifyToken : process.env.REACT_APP_URL), '600', '400')}></span>
              <span className="icon-facebook icon-default" onClick={() => this.openLink('https://www.facebook.com/sharer.php?src=sp&u=' + encodeURIComponent(this.props.verifyToken ? process.env.REACT_APP_URL + '/?pid=' + this.props.verifyToken : process.env.REACT_APP_URL) + '&title=' + encodeURIComponent(item.title), '600', '400')}></span>
              <span className="icon-vk icon-default" onClick={() => this.openLink('https://vk.com/share.php?url=' + encodeURIComponent(this.props.verifyToken ? process.env.REACT_APP_URL + '/?pid=' + this.props.verifyToken : process.env.REACT_APP_URL) + '&title=' + encodeURIComponent(item.title), '600', '400')}></span>
            </div>
          </div>
          <h2 className="news-title">{item.title}</h2>
          <p>{item.new}</p>
        </div>
      )
    }));

    let urlElements = window.location.href.split('/');
    var contentTitle;
    if (urlElements[3] === "news#promotions") {
      contentTitle = 'Акции';
    } else if (urlElements[3] === "news#contests") {
      contentTitle = 'Конкурсы';
    } else {
      contentTitle = 'Новости';
    }
    return (
      <div className="page">
        <div className="page-content">
          <div className="default">
            <div className="default-column">
              <div className="default-menu">
                <div>
                  <NavLink to="/news" className="default-nav-item" activeClassName={urlElements[3] === "news" ? "default-nav-item-active" : null}>Новости</NavLink>
                  <NavLink to="#promotions" className="default-nav-item" activeClassName={urlElements[3] === "news#promotions" ? "default-nav-item-active" : null}>Акции</NavLink>
                  <NavLink to="#contests" className="default-nav-item" activeClassName={urlElements[3] === "news#contests" ? "default-nav-item-active" : null}>Конкурсы</NavLink>
                </div>
              </div>
            </div>
            <div className="default-column">
              <div className="default-content">
                <h1>{contentTitle}</h1>
                <div className="icon-news icon-main"></div>
              </div>
              <div className="default-submenu" >
                <NavLink to="/news" className="user-nav-item" activeClassName={urlElements[3] === "news" ? "user-nav-item-active" : null}>Новости</NavLink>
                <NavLink to="#promotions" className="user-nav-item" activeClassName={urlElements[3] === "news#promotions" ? "user-nav-item-active" : null}>Акции</NavLink>
                <NavLink to="#contests" className="user-nav-item" activeClassName={urlElements[3] === "news#contests" ? "user-nav-item-active" : null}>Конкурсы</NavLink>
              </div>
              {urlElements[3] === "news#promotions" ?
                <div>
                  <p>Раздел Акции обновляется...</p>
                </div>
                : urlElements[3] === "news#contests" ?
                  <div>
                    <p>Раздел Конкурсы обновляется...</p>
                  </div>
                  :
                  <div>
                    {this.state.loading ?
                      <div className="data-loading"></div>
                      :
                      <div>
                        {news}
                        {this.state.totalLoading < this.state.totalCount ?
                          <div className="data-loading next"></div>
                          :
                          null}
                      </div>
                    }
                  </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default News;