import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Captcha from '../../captcha';
import Tooltip from '../../tooltip';
import { UserName, Password, AuthPassword, Telegram, Phone } from '../../components/constants';
import { AccountMenu } from '../../components/account_menu';

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      settingsEmail: '',
      settingsLastName: '',
      settingsFirstName: '',
      settingsSecondName: '',
      settingsPhoneNumber: '',
      settingsTelegram: '',
      settingsConfirm: '',
      settingsAuth: '',
      settingsAuthSecret: '',
      inputTypeCurrent: false,
      inputTypeNew: false,
      inputTypeRepeat: false,
      settingsCurrentPassword: '',
      settingsNewPassword: '',
      settingsRepeatPassword: '',
      settingsAuthCode: '',
      showNotice: false,
      isSubmitted: {},
      isDisabled: false,
      notification: '',
      showToolTip: [],
      showDanger: [],
      pattern: [["settingsLastName", false], ["settingsFirstName", false], ["settingsSecondName", false], ["settingsPhoneNumber", false], ["settingsTelegram", false]],
      patternPass: [["settingsCurrentPassword", false], ["settingsNewPassword", false], ["settingsRepeatPassword", false]],
      patternAuth: [["settingsAuthCode", false]],
      isOpenedMenu: false,
      isOpened: false
    }
    this.openToolTip = this.openToolTip.bind(this);
    this.closeToolTip = this.closeToolTip.bind(this);
    this.resetInputData = this.resetInputData.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.submitPassForm = this.submitPassForm.bind(this);
    this.submit2FaForm = this.submit2FaForm.bind(this);
    this.submitRequest = this.submitRequest.bind(this);
    this.accountSection = React.createRef();
    this.menuSection = React.createRef();
    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  loadingData() {
    fetch('/api/profile/settings', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.accessToken,
      },
      body: JSON.stringify({ 'loading': true }),
    })
      .then(response => response.json())
      .then(result => {
        this.setState({
          data: result.data,
          settingsEmail: result.data.map(item => item.settings.map(item => item.login)).toString(),
          settingsLastName: result.data.map(item => item.settings.map(item => item.lname)).toString(),
          settingsFirstName: result.data.map(item => item.settings.map(item => item.fname)).toString(),
          settingsSecondName: result.data.map(item => item.settings.map(item => item.sname)).toString(),
          settingsPhoneNumber: result.data.map(item => item.settings.map(item => item.phone)).toString(),
          settingsTelegram: result.data.map(item => item.settings.map(item => item.telegram)).toString(),
          settingsAuthSecret: result.data.map(item => item.settings.map(item => item.authenticator)),
          settingsAuth: result.data.map(item => item.settings.map(item => item.auth)).toString(),
          settingsConfirm: result.data.map(item => item.settings.map(item => item.email_confirm)).toString(),
          loading: false
        });
      }, (error) => {
        if (error) {
          localStorage.removeItem('token');
          window.location.href = '/?login=true';
        }
      });
  }

  componentDidMount() {
    if (!this.props.accessToken) {
      window.location.href = '/?login=true';
    }
    this.resetInputData();
    this.loadingData();
    document.title = 'Настройки | ' + process.env.REACT_APP_NAME;
    document.addEventListener('mousedown', this.closeMenu);
  }

  componentDidUpdate() {
    if (!this.props.accessToken) {
      window.location.href = '/?login=true';
    }
    if (this.state.showNotice) {
      this.timer = setTimeout(() => this.setState({ showNotice: false }), 5000);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
    document.removeEventListener('mousedown', this.closeMenu);
  }

  resetInputData() {
    document.querySelectorAll('input').forEach(function (item) {
      item.value = "";
    });
    this.closeDanger();
    this.setState({ inputTypeCurrent: false, inputTypeNew: false, inputTypeRepeat: false, settingsCurrentPassword: '', settingsNewPassword: '', settingsRepeatPassword: '', settingsAuthCode: '' });
  }

  changeModal = () => {
    this.setState({ isOpened: !this.state.isOpened });
  }

  openToolTip(i) {
    const openStatus = this.state.showToolTip.slice();
    openStatus[i] = true;
    this.setState({ showToolTip: openStatus });
  }

  closeToolTip(i) {
    if (i === undefined) {
      this.setState({ showToolTip: [] });
    } else {
      const closeStatus = this.state.showToolTip.slice();
      closeStatus[i] = false;
      this.setState({ showToolTip: closeStatus });
    }
  }

  openDanger(i) {
    const openStatus = this.state.showDanger;
    openStatus[i] = true;
    this.setState({ showDanger: openStatus });
  }

  closeDanger(i) {
    if (i === undefined) {
      this.setState({ showDanger: [] });
    } else {
      const closeStatus = this.state.showDanger;
      closeStatus[i] = false;
      this.setState({ showDanger: closeStatus });
    }
  }

  changeInputType = (i) => {
    const inputType = !this.state[i];
    this.setState({ [i]: inputType });
  }

  clickForm = a => event => {
    this.closeToolTip(event.target.name);
    const pattern = new RegExp(a);
    if (event.target.value !== '' || (event.target.value === '' && this.state.showDanger[event.target.name] === true)) {
      if (!pattern.test(event.target.value)) {
        this.openDanger(event.target.name);
        this.openToolTip(event.target.name);
      } else {
        this.closeDanger(event.target.name);
        this.closeToolTip(event.target.name);
      }
    }
  }

  blurForm = a => event => {
    this.closeToolTip();
    const pattern = new RegExp(a);
    if (!pattern.test(event.target.value)) {
      if (event.target.name !== 'settingsTelegram' && event.target.name !== 'settingsPhoneNumber' && event.target.name !== 'settingsSecondName') {
        this.openDanger(event.target.name);
      } else if (event.target.value !== '') {
        this.openDanger(event.target.name);
      }
    }
  }

  changeForm = a => event => {
    if (event.target.name === 'settingsAuthCode' || event.target.name === 'settingsPhoneNumber') {
      event.target.value = event.target.value.replace(/[^\d]*/g, '');
    }
    if (event.target.name === 'settingsLastName' || event.target.name === 'settingsFirstName' || event.target.name === 'settingsSecondName') {
      event.target.value = event.target.value.replace(/[^a-zA-Zа-яА-ЯЁё\s]*/g, '').toLowerCase().replace(/^./, u => u.toUpperCase());
    }
    if (event.target.name === 'settingsTelegram') {
      event.target.value = event.target.value.replace(/[^@a-zA-Z_0-9]*/g, '').toLowerCase();
    }
    this.setState({ [event.target.name]: event.target.value })
    const pattern = new RegExp(a);
    if (event.target.value !== '') {
      if (pattern.test(event.target.value)) {
        this.closeDanger(event.target.name);
        this.closeToolTip(event.target.name);
      }
    } else {
      this.closeToolTip(event.target.name);
      this.closeDanger(event.target.name);
    }
  }

  submitForm(e) {
    e.preventDefault();

    if (this.state.settingsLastName === '') {
      this.openDanger('settingsLastName');
    }
    if (this.state.settingsFirstName === '') {
      this.openDanger('settingsFirstName');
    }

    var sortDanger = this.state.pattern.map(pattern => Object.entries(this.state.showDanger).find(item => item[0] === pattern[0])).filter(item => item !== undefined);
    var trueProps = sortDanger.filter(([key, value]) => value === true);

    if (trueProps.length > 0) {
      this.openToolTip(trueProps[0][0]);
    } else {
      this.setState({ isDisabled: true, isSubmitted: { submitForm: true } });
      fetch('/api/profile/settings-edit', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.props.accessToken,
        },
        body: JSON.stringify({ 'settingsLastName': this.state.settingsLastName, 'settingsFirstName': this.state.settingsFirstName, 'settingsSecondName': this.state.settingsSecondName, 'settingsPhoneNumber': this.state.settingsPhoneNumber, 'settingsTelegram': this.state.settingsTelegram }),
      })
        .then(response => response.json())
        .then((result) => {
          if (result.status) {
            this.setState({ notification: 'Данные успешно сохранены.', showNotice: true, isDisabled: false, isSubmitted: {} });
          }
        }, (error) => {
          if (error) {
            localStorage.removeItem('token');
            window.location.href = '/?login=true';
          }
        })
    }
  }

  submitPassForm(e) {
    e.preventDefault();

    if (this.state.settingsCurrentPassword === '') {
      this.openDanger('settingsCurrentPassword');
    }
    if (this.state.settingsNewPassword === '') {
      this.openDanger('settingsNewPassword');
    }
    if (this.state.settingsRepeatPassword === '') {
      this.openDanger('settingsRepeatPassword');
    }

    var sortDanger = this.state.patternPass.map(pattern => Object.entries(this.state.showDanger).find(item => item[0] === pattern[0])).filter(item => item !== undefined);
    var trueProps = sortDanger.filter(([key, value]) => value === true);

    if (trueProps.length > 0) {
      this.openToolTip(trueProps[0][0]);
    } else {
      this.setState({ isDisabled: true, isSubmitted: { submitPassForm: true } });
      fetch('/api/profile/settings-password', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.props.accessToken,
        },
        body: JSON.stringify({ 'settingsEmail': this.state.settingsEmail, 'settingsCurrentPassword': this.state.settingsCurrentPassword, 'settingsNewPassword': this.state.settingsNewPassword, 'settingsRepeatPassword': this.state.settingsRepeatPassword }),
      })
        .then(response => response.json())
        .then((result) => {
          if (this.state.settingsNewPassword !== this.state.settingsRepeatPassword) {
            this.openToolTip('settingsMatchPassword');
            this.openDanger('settingsRepeatPassword');
          } else if (!result.status) {
            this.openToolTip('settingsErrorCurrentPassword');
            this.openDanger('settingsCurrentPassword');
          } else {
            this.setState({ notification: 'Пароль успешно изменен.', showNotice: true, isDisabled: false, isSubmitted: {} });
            this.resetInputData();
          }
          this.setState({ isDisabled: false, isSubmitted: {} });
        }, (error) => {
          if (error) {
            localStorage.removeItem('token');
            window.location.href = '/?login=true';
          }
        })
    }
  }

  submit2FaForm(e) {
    e.preventDefault();

    if (this.state.settingsAuthCode === '') {
      this.openDanger('settingsAuthCode');
    }

    var sortDanger = this.state.patternAuth.map(pattern => Object.entries(this.state.showDanger).find(item => item[0] === pattern[0])).filter(item => item !== undefined);
    var trueProps = sortDanger.filter(([key, value]) => value === true);

    if (trueProps.length > 0) {
      this.openToolTip(trueProps[0][0]);
    } else {
      this.setState({ isDisabled: true, isSubmitted: { submit2FaForm: true } });
      fetch('/api/profile/settings-2fa', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.props.accessToken,
        },
        body: JSON.stringify({ 'settingsEmail': this.state.settingsEmail, 'settingsAuthCode': this.state.settingsAuthCode }),
      })
        .then(response => response.json())
        .then((result) => {
          if (!result.status) {
            this.openToolTip('settingsCheckAuthCode');
            this.openDanger('settingsAuthCode');
          } else {
            this.setState({ notification: this.state.settingsAuth === "0" ? 'Google Authenticator включен.' : 'Google Authenticator отключен.', showNotice: true, isDisabled: false, isSubmitted: {} });
            this.resetInputData();
            this.loadingData();
          }
          this.setState({ isDisabled: false, isSubmitted: {} });
        }, (error) => {
          if (error) {
            localStorage.removeItem('token');
            window.location.href = '/?login=true';
          }
        })
    }
  }

  submitCaptcha() {
    fetch('/api/profile/settings-email', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.accessToken,
      },
      body: JSON.stringify({ 'loading': true }),
    })
      .then(response => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ notification: 'Письмо успешно отправлено.', showNotice: true });
        }
      }, (error) => {
        if (error) {
          localStorage.removeItem('token');
          window.location.href = '/?login=true';
        }
      })
  }

  submitRequest() {
    this.setState({ isOpened: true });
  }

  showMenu() {
    this.setState({ isOpenedMenu: !this.state.isOpenedMenu });
  }

  closeMenu(e) {
    if (this.accountSection.current && !this.accountSection.current.contains(e.target)) {
      if (this.state.isOpenedMenu && this.menuSection.current && !this.menuSection.current.contains(e.target)) {
        this.setState({ isOpenedMenu: false });
      }
    }
  }

  render() {
    let urlElements = window.location.href.split('/');
    return (
      <div className="page">
        <div className="page-content">
          <div className={"notice fade" + (this.state.showNotice ? " in" : "")}>{this.state.notification}</div>
          <div className="account">
            <div className="account-column">
              <div className="account-section" onClick={this.showMenu} ref={this.accountSection}>
                <span className="account-section-title">Кабинет</span>
                <span className={'icon-arrow icon-default' + (this.state.isOpenedMenu ? ' rotate' : '')}></span>
              </div>
              <div className={'account-menu' + (this.state.isOpenedMenu ? ' open' : '')} ref={this.menuSection}>{AccountMenu}</div>
            </div>
            <div className="account-column">
              <div className="account-content">
                <h1>Настройки</h1>
                <div className="icon-settings icon-main"></div>
              </div>
              <div className="user-menu">
                <NavLink to="/profile/settings" onClick={this.resetInputData} className="user-nav-item" activeClassName={urlElements[4] === "settings" ? "user-nav-item-active" : null}>Данные пользователя</NavLink>
                <NavLink to="#security" onClick={() => this.loadingData()} className="user-nav-item" activeClassName={urlElements[4] === "settings#security" ? "user-nav-item-active" : null}>Безопасность</NavLink>
              </div>
              {urlElements[4] === "settings" ?
                this.state.loading ?
                  <div className="data-loading"></div>
                  :
                  <div>
                    <div className="user-settings">
                      <div className="user-settings-item">
                        <form autoComplete="off" onSubmit={this.submitForm}>
                          <div className="field">
                            <input type="text" className={"input" + (this.state.showDanger['settingsLastName'] ? " input-danger" : "")} id="settingsLastName" name="settingsLastName" value={this.state.settingsLastName} autoComplete="off" placeholder="Фамилия" onClick={this.clickForm(UserName)} onBlur={this.blurForm(UserName)} onChange={this.changeForm(UserName)} />
                            <div className="field-icon"><span className="icon-user icon-medium"></span></div>
                            <Tooltip status={this.state.showToolTip['settingsLastName']} closeToolTip={() => this.closeToolTip('settingsLastName')} text="Введите корректную Фамилию." />
                          </div>
                          <div className="field">
                            <input type="text" className={"input" + (this.state.showDanger['settingsFirstName'] ? " input-danger" : "")} id="settingsFirstName" name="settingsFirstName" value={this.state.settingsFirstName} autoComplete="off" placeholder="Имя" onClick={this.clickForm(UserName)} onBlur={this.blurForm(UserName)} onChange={this.changeForm(UserName)} />
                            <div className="field-icon"><span className="icon-user icon-medium"></span></div>
                            <Tooltip status={this.state.showToolTip['settingsFirstName']} closeToolTip={() => this.closeToolTip('settingsFirstName')} text="Введите корректное Имя." />
                          </div>
                          <div className="field">
                            <input type="text" className={"input" + (this.state.showDanger['settingsSecondName'] ? " input-danger" : "")} id="settingsSecondName" name="settingsSecondName" value={this.state.settingsSecondName} autoComplete="off" placeholder="Отчество (если есть)" onClick={this.clickForm(UserName)} onBlur={this.blurForm(UserName)} onChange={this.changeForm(UserName)} />
                            <div className="field-icon"><span className="icon-user icon-medium"></span></div>
                            <Tooltip status={this.state.showToolTip['settingsSecondName']} closeToolTip={() => this.closeToolTip('settingsSecondName')} text="Введите корректное Отчество. Если нет Отчества, оставьте данное поле пустым." />
                          </div>
                          <div className="field">
                            <input type="text" className="input input-disabled" id="settingsEmail" name="settingsEmail" value={this.state.settingsEmail} autoComplete="off" placeholder="E-mail" disabled="disabled" />
                            <div className="field-icon"><span className="icon-email icon-medium"></span></div>
                          </div>
                          <div className="field">
                            <input type="text" className={"input" + (this.state.showDanger['settingsPhoneNumber'] ? " input-danger" : "")} id="settingsPhoneNumber" name="settingsPhoneNumber" value={this.state.settingsPhoneNumber} autoComplete="off" placeholder="Номер телефона, 79111222333" onClick={this.clickForm(Phone)} onBlur={this.blurForm(Phone)} onChange={this.changeForm(Phone)} />
                            <div className="field-icon"><span className="icon-phone icon-medium"></span></div>
                            <Tooltip status={this.state.showToolTip['settingsPhoneNumber']} closeToolTip={() => this.closeToolTip('settingsPhoneNumber')} text="Введите корректный номер телефона в международном формате без + и пробелов." />
                          </div>
                          <div className="field">
                            <input type="text" className={"input" + (this.state.showDanger['settingsTelegram'] ? " input-danger" : "")} id="settingsTelegram" name="settingsTelegram" value={this.state.settingsTelegram} autoComplete="off" placeholder="Telegram, @username" onClick={this.clickForm(Telegram)} onBlur={this.blurForm(Telegram)} onChange={this.changeForm(Telegram)} />
                            <div className="field-icon"><span className="icon-tgram icon-medium"></span></div>
                            <Tooltip status={this.state.showToolTip['settingsTelegram']} closeToolTip={() => this.closeToolTip('settingsTelegram')} text="Введите корректное имя пользователя в Telegram в формате @username." />
                          </div>
                          <button className={"button" + (this.state.isSubmitted['submitForm'] ? " button-loading" : "")} disabled={this.state.isDisabled} type="submit" onBlur={this.blurForm()}>Сохранить</button>
                        </form>
                      </div>
                      <div className="user-settings-item">
                        <div className="notification">
                          <div className="notification-title">{this.state.settingsConfirm ? this.state.settingsConfirm === "1" ? "Ваш E-mail верифицирован" : "Ваш E-mail еще не верифицирован" : null}</div>
                          <div className="field-notification-icon">
                            <div className="icon-notification icon-big"></div>
                          </div>
                          <div className="notification-text">{this.state.settingsConfirm ? this.state.settingsConfirm === "1" ? "Если Вы хотите изменить свой E-mail, по соображениям безопасности обратитесь в Центр поддержки." : <span>На Ваш E-mail отправлено письмо со ссылкой для верифицикации. <span id="request" onClick={this.submitRequest}>Отправить еще раз</span></span> : null}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                :
                <div>
                  <h2>Изменение пароля</h2>
                  <div className="user-settings">
                    <div className="user-settings-item">
                      <form autoComplete="off" onSubmit={this.submitPassForm}>

                        <div className="field">
                          <input type={this.state.inputTypeCurrent ? "text" : "password"} className={"input" + (this.state.showDanger['settingsCurrentPassword'] ? " input-danger" : "")} id="settingsCurrentPassword" name="settingsCurrentPassword" autoComplete="off" placeholder="Текущий пароль" onClick={this.clickForm(Password)} onBlur={this.blurForm(Password)} onChange={this.changeForm(Password)} />
                          <div className="field-icon"><span className={(this.state.inputTypeCurrent ? "icon-show-password" : "icon-hide-password") + " icon-medium"} onClick={() => this.changeInputType('inputTypeCurrent')}></span></div>
                          <Tooltip status={this.state.showToolTip['settingsCurrentPassword']} closeToolTip={() => this.closeToolTip('settingsCurrentPassword')} text="Введите текущий пароль." />
                          <Tooltip status={this.state.showToolTip['settingsErrorCurrentPassword']} closeToolTip={() => this.closeToolTip('settingsErrorCurrentPassword')} text="Введите корректный текущий пароль." />
                        </div>
                        <div className="field">
                          <input type={this.state.inputTypeNew ? "text" : "password"} className={"input" + (this.state.showDanger['settingsNewPassword'] ? " input-danger" : "")} id="settingsNewPassword" name="settingsNewPassword" autoComplete="off" placeholder="Новый пароль" onClick={this.clickForm(Password)} onBlur={this.blurForm(Password)} onChange={this.changeForm(Password)} />
                          <div className="field-icon"><span className={(this.state.inputTypeNew ? "icon-show-password" : "icon-hide-password") + " icon-medium"} onClick={() => this.changeInputType('inputTypeNew')}></span></div>
                          <Tooltip status={this.state.showToolTip['settingsNewPassword']} closeToolTip={() => this.closeToolTip('settingsNewPassword')} text="Новый пароль должен содержать не менее 8 символов и включать в себя цифры, латинские строчные и прописные буквы." />
                        </div>
                        <div className="field">
                          <input type={this.state.inputTypeRepeat ? "text" : "password"} className={"input" + (this.state.showDanger['settingsRepeatPassword'] ? " input-danger" : "")} id="settingsRepeatPassword" name="settingsRepeatPassword" autoComplete="off" placeholder="Повтор нового пароля" onClick={this.clickForm(Password)} onBlur={this.blurForm(Password)} onChange={this.changeForm(Password)} />
                          <div className="field-icon"><span className={(this.state.inputTypeRepeat ? "icon-show-password" : "icon-hide-password") + " icon-medium"} onClick={() => this.changeInputType('inputTypeRepeat')}></span></div>
                          <Tooltip status={this.state.showToolTip['settingsRepeatPassword']} closeToolTip={() => this.closeToolTip('settingsRepeatPassword')} text="Введите повторно новый пароль." />
                          <Tooltip status={this.state.showToolTip['settingsMatchPassword']} closeToolTip={() => this.closeToolTip('settingsMatchPassword')} text="Введенные пароли не совпадают." />
                        </div>
                        <button className={"button" + (this.state.isSubmitted['submitPassForm'] ? " button-loading" : "")} disabled={this.state.isDisabled} type="submit" onBlur={this.blurForm()}>Сохранить</button>
                      </form>
                    </div>
                    <div className="user-settings-item">
                      <div className="notification">
                        <div className="notification-title">Требования к паролю</div>
                        <div className="field-notification-icon">
                          <div className="icon-notification icon-big"></div>
                        </div>
                        <div className="notification-text">Пароль должен содержать не менее 8 символов и включать в себя цифры, латинские строчные и прописные буквы.</div>
                      </div>
                    </div>
                  </div>
                  <h2>Google Authenticator</h2>
                  {this.state.settingsAuth === "0" ?
                    <div className="user-settings">
                      <div className="user-settings-item">
                        <div className="note">
                          Вы можете обеспечить дополнительную защиту Вашего личного кабинета с помощью Двухфакторной Аутентификации через мобильное приложение Google Authenticator.
                          <p>Ключ - <span style={{ fontWeight: 'bold' }}>{this.state.settingsAuthSecret}</span></p>
                        </div>
                        <form autoComplete="off" onSubmit={this.submit2FaForm}>
                          <div className="field">
                            <input type="text" inputMode="numeric" className={"input" + (this.state.showDanger['settingsAuthCode'] ? " input-danger" : "")} id="settingsAuthCode" name="settingsAuthCode" autoComplete="off" maxLength="6" placeholder="Код подтверждения, 6 цифр" onClick={this.clickForm(AuthPassword)} onBlur={this.blurForm(AuthPassword)} onChange={this.changeForm(AuthPassword)} />
                            <div className="field-icon"><span className="icon-numeric icon-medium"></span></div>
                            <Tooltip status={this.state.showToolTip['settingsAuthCode']} closeToolTip={() => this.closeToolTip('settingsAuthCode')} text="Код подтверждения Google Authenticator должен содержать 6 цифр." />
                            <Tooltip status={this.state.showToolTip['settingsCheckAuthCode']} closeToolTip={() => this.closeToolTip('settingsCheckAuthCode')} text="Введите корректный код подтверждения Google Authenticator." />
                          </div>
                          <button className={"button" + (this.state.isSubmitted['submit2FaForm'] ? " button-loading" : "")} disabled={this.state.isDisabled} type="submit" onBlur={this.blurForm()}>Включить</button>
                        </form>
                      </div>
                      <div className="user-settings-item">
                        <div className="notification qr">
                          <div className="notification-title">Сканировать QR-код</div>
                          <img class="img-qr" src={"https://chart.googleapis.com/chart?chs=200x200&chld=M|0&cht=qr&chl=otpauth%3A%2F%2Ftotp%2Fwmsim.com+%28" + this.state.settingsEmail + "%29%3Fsecret%3D" + this.state.settingsAuthSecret} alt="" width="200" height="200"></img>
                        </div>
                      </div>
                    </div>
                    :
                    <div className="user-settings">
                      <div className="user-settings-item">
                        <form autoComplete="off" onSubmit={this.submit2FaForm}>
                          <div className="field">
                            <input type="text" inputMode="numeric" className={"input" + (this.state.showDanger['settingsAuthCode'] ? " input-danger" : "")} id="settingsAuthCode" name="settingsAuthCode" autoComplete="off" maxLength="6" placeholder="Код подтверждения, 6 цифр" onClick={this.clickForm(AuthPassword)} onBlur={this.blurForm(AuthPassword)} onChange={this.changeForm(AuthPassword)} />
                            <div className="field-icon"><span className="icon-numeric icon-medium"></span></div>
                            <Tooltip status={this.state.showToolTip['settingsAuthCode']} closeToolTip={() => this.closeToolTip('settingsAuthCode')} text="Код подтверждения Google Authenticator должен содержать 6 цифр." />
                            <Tooltip status={this.state.showToolTip['settingsCheckAuthCode']} closeToolTip={() => this.closeToolTip('settingsCheckAuthCode')} text="Введите корректный код подтверждения Google Authenticator." />
                          </div>
                          <button className={"button" + (this.state.isSubmitted['submit2FaForm'] ? " button-loading" : "")} disabled={this.state.isDisabled} type="submit" onBlur={this.blurForm()}>Отключить</button>
                        </form>
                      </div>
                      <div className="user-settings-item">
                        <div className="notification">
                          <div className="notification-title">Двухфакторная Аутентификация</div>
                          <div className="field-notification-icon">
                            <div className="icon-notification icon-big"></div>
                          </div>
                          <div className="notification-text">Вход в Ваш личный кабинет дополнительно защищен кодом подтверждения Google Authenticator.</div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              }
            </div>
          </div>
        </div>
        {this.state.isOpened ? <Captcha isOpened={this.state.isOpened} submitCaptcha={() => this.submitCaptcha(this)} changeModal={() => this.changeModal(this)} /> : null}
      </div>
    );
  }
}

export default Settings;