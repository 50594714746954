export const Telegram = '^[@]{1}[a-zA-Z_0-9]+$';
export const Message = '^[\\s\\S]{15,500}$';
export const Phone = '^\\d+$';
export const UserName = '^[a-zA-Zа-яА-ЯёЁ\\s]+$';
export const Email = '^([a-z0-9_-]+\\.)*[a-z0-9_-]+@[a-z0-9_-]+(\\.[a-z0-9_-]+)*\\.[a-z]{2,6}$';
export const Password = '^(?=.*\\d)(?=.*[A-Z])(?=.*[a-z])[0-9A-Za-z]{8,64}$';
export const Promo = '^[0-9A-Z]{16}$';
export const Amount = '^\\d+([.]\\d{0,8})?$';
export const CardNumber = '^[0-9\\s]{19}$';
export const CardDate = '^[0-9/]{5}$';
export const CardHolder = '^[a-zA-Z\\s]+$';
export const CardPhoto = '^[^<]\\.jpeg|jpg|png|gif|JPEG|JPG|PNG|GIF$';
export const AuthPassword = '^\\d{6}$';

export const verifyAccessToken = async (token) => {
  if (token) {
    return await fetch('/api/token-verify', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 'token': token }),
    })
      .then(response => response.json())
      .then((result) => {
        return result.uid;
      }, (error) => {
        if (error) {
          return false;
        }
      });
  } else {
    return false;
  }
}