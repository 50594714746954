import React, { Component } from 'react';
import Tooltip from './tooltip';
import { Password } from './components/constants';

class Confirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputTypeNew: false,
      inputTypeRepeat: false,
      typeConfirm: '',
      confirmCode: '',
      confirmNewPassword: '',
      confirmRepeatPassword: '',
      showToolTip: [],
      showDanger: [],
      isConfirm: false,
      isConfirmPass: false,
      isRequest: false,
      isSubmitted: {},
      isDisabled: false,
      isOpened: false,
      pattern: [["confirmNewPassword", false], ["confirmRepeatPassword", false]]
    }
    this.submitForm = this.submitForm.bind(this);
    this.submitFormPass = this.submitFormPass.bind(this);
    this.submitRequest = this.submitRequest.bind(this);
  }

  componentDidMount() {
    this.timer = setTimeout(() => this.setState({ isOpened: this.props.isOpened ? true : false }), 1);
    const query = new URLSearchParams(this.props.location.search);
    if (query.get('email-confirm') !== null) {
      this.setState({ confirmCode: query.get('email-confirm'), typeConfirm: 'emailConfirm' });
    } else if (query.get('recovery-confirm') !== null) {
      this.setState({ confirmCode: query.get('recovery-confirm'), typeConfirm: 'recoveryConfirm' });
    }
  }

  openToolTip(i) {
    const openStatus = this.state.showToolTip.slice();
    openStatus[i] = true;
    this.setState({ showToolTip: openStatus });
  }

  closeToolTip(i) {
    if (i === undefined) {
      this.setState({ showToolTip: [] });
    } else {
      const closeStatus = this.state.showToolTip.slice();
      closeStatus[i] = false;
      this.setState({ showToolTip: closeStatus });
    }
  }

  openDanger(i) {
    const openStatus = this.state.showDanger;
    openStatus[i] = true;
    this.setState({ showDanger: openStatus });
  }

  closeDanger(i) {
    const closeStatus = this.state.showDanger;
    closeStatus[i] = false;
    this.setState({ showDanger: closeStatus });
  }

  changeInputType = (i) => {
    const inputType = !this.state[i];
    this.setState({ [i]: inputType });
  }

  clickForm = a => event => {
    this.closeToolTip(event.target.name);
    const pattern = new RegExp(a);
    if (event.target.value !== '' || (event.target.value === '' && this.state.showDanger[event.target.name] === true)) {
      if (!pattern.test(event.target.value)) {
        this.openDanger(event.target.name);
        this.openToolTip(event.target.name);
      } else {
        this.closeDanger(event.target.name);
        this.closeToolTip(event.target.name);
      }
    }
  }

  blurForm = a => event => {
    this.closeToolTip();
    const pattern = new RegExp(a);
    if (!pattern.test(event.target.value)) {
      this.openDanger(event.target.name);
    }
  }

  changeForm = a => event => {
    this.setState({ [event.target.name]: event.target.value })
    const pattern = new RegExp(a);
    if (event.target.value !== '') {
      if (pattern.test(event.target.value)) {
        this.closeDanger(event.target.name);
        this.closeToolTip(event.target.name);
      }
    } else {
      this.closeToolTip(event.target.name);
      this.closeDanger(event.target.name);
    }
  }

  submitForm(e) {
    e.preventDefault();

    this.setState({ isDisabled: true, isSubmitted: { submitForm: true } });
    const url = this.state.typeConfirm === 'emailConfirm' ? '/api/email-confirm' : '/api/recovery-confirm';
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 'confirmCode': this.state.confirmCode }),
    })
      .then(response => response.json())
      .then((result) => {
        if (!result.token) {
          this.openToolTip('confirmCode');
        } else if (!result.status) {
          this.openToolTip('confirmCodeCheck');
        } else {
          if (this.state.typeConfirm === 'emailConfirm') {
            localStorage.setItem('token', result.accesstoken);
          }
          this.setState({ isConfirm: true });
        }
        this.setState({ isDisabled: false, isSubmitted: {} });
      })
  }

  submitFormPass(e) {
    e.preventDefault();

    if (this.state.confirmNewPassword === '') {
      this.openDanger('confirmNewPassword');
    }
    if (this.state.confirmRepeatPassword === '') {
      this.openDanger('confirmRepeatPassword');
    }

    var sortDanger = this.state.pattern.map(pattern => Object.entries(this.state.showDanger).find(item => item[0] === pattern[0])).filter(item => item !== undefined);
    var trueProps = sortDanger.filter(([key, value]) => value === true);

    if (trueProps.length > 0) {
      this.openToolTip(trueProps[0][0]);
    } else {
      this.setState({ isDisabled: true, isSubmitted: { submitFormPass: true } });
      fetch('/api/recovery-password', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 'confirmCode': this.state.confirmCode, 'confirmNewPassword': this.state.confirmNewPassword, 'confirmRepeatPassword': this.state.confirmRepeatPassword }),
      })
        .then(response => response.json())
        .then((result) => {
          if (this.state.confirmNewPassword !== this.state.confirmRepeatPassword) {
            this.openToolTip('confirmMatchPassword');
            this.openDanger('confirmRepeatPassword');
          } else if (!result.token) {
            this.setState({ isConfirm: false });
          } else {
            localStorage.setItem('token', result.accesstoken);
            this.setState({ isConfirmPass: true });
          }
          this.setState({ isDisabled: false, isSubmitted: {} });
        })
    }
  }

  submitRequest() {
    const url = this.state.typeConfirm === 'emailConfirm' ? '/api/request-email-confirm' : '/api/request-recovery-confirm';
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 'confirmCode': this.state.confirmCode }),
    })
      .then(response => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ isRequest: true });
        }
      })
  }

  render() {
    return (
      <div className={"modal-overlay" + (this.props.isOpened ? " open" : " close")}>
        {!this.state.isConfirm ?
          !this.state.isRequest ?
            <form autoComplete="off" onSubmit={this.submitForm}>
              <div className={"modal-content" + (this.state.isOpened ? " open" : "")}>
                <div className="modal-close" onClick={() => this.props.changeModal()}><span className="icon-close icon-small"></span></div>
                <div className="modal-title">{this.state.typeConfirm === 'emailConfirm' ? 'Подтверждение E-mail' : 'Восстановление пароля'}</div>
                <div className="modal-note ssl-note">Убедитесь, что находитесь на странице<span className="icon-ssl"></span><span className="ssl">https://</span>www.wmsim.com</div>
                <div className="field">
                  <input type="text" className="input input-disabled" id="confirmCode" name="confirmCode" value={this.state.confirmCode} autoComplete="off" placeholder="Код подтверждения" disabled="disabled" />
                  <div className="field-icon"><span className="icon-email icon-medium"></span></div>
                  <Tooltip status={this.state.showToolTip['confirmCode']} closeToolTip={() => this.closeToolTip('confirmCode')} text="Указанный код подтверждения недействителен или истек срок действия ссылки для подтверждения." />
                  <Tooltip status={this.state.showToolTip['confirmCodeCheck']} closeToolTip={() => this.closeToolTip('confirmCodeCheck')} text="Ваш E-mail уже подтвержден и Вы можете войти в Ваш личный кабинет." />
                </div>
                <button className={"button" + (this.state.isSubmitted['submitForm'] ? " button-loading" : "")} disabled={this.state.isDisabled} type="submit">Подтвердить</button>
                <div className="modal-note last"><span id="request" onClick={this.submitRequest}>Запросить новый код подтверждения</span></div>
              </div>
            </form>
            :
            <div className="modal-content open">
              <div className="modal-close" onClick={() => this.props.changeModal()}><span className="icon-close icon-small"></span></div>
              <div className="modal-title">{this.state.typeConfirm === 'emailConfirm' ? 'Подтверждение E-mail' : 'Восстановление пароля'}</div>
              <div className="modal-note">Письмо с инструкциями отправлено на Вашу почту.</div>
              <div className="modal-block">
                <div className="modal-column">
                  <span className="icon-lock icon-big icon-modal"></span>
                </div>
                <div className="modal-column">
                  <span className="icon-mail icon-big icon-modal"></span>
                </div>
                <div className="modal-column">
                  <span className="icon-user icon-big icon-modal"></span>
                </div>
              </div>
              <div className="modal-note">{this.state.typeConfirm === 'emailConfirm' ? 'Для подтверждения E-mail перейдите по ссылке в письме.' : 'Для подтверждения сброса пароля перейдите по ссылке в письме.'}</div>
              <button className="button" onClick={() => this.props.changeModal()}>Закрыть</button>
            </div>
          :
          this.state.typeConfirm === 'emailConfirm' ?
            <div className="modal-content open">
              <div className="modal-close" onClick={() => this.props.changeModal()}><span className="icon-close icon-small"></span></div>
              <div className="modal-title">Подтверждение E-mail</div>
              <div className="modal-note">Ваш E-mail успешно подтвержден.</div>
              <div className="modal-block">
                <div className="modal-column">
                  <span className="icon-lock icon-big icon-modal"></span>
                </div>
                <div className="modal-column">
                  <span className="icon-success icon-big icon-modal"></span>
                </div>
                <div className="modal-column">
                  <span className="icon-user icon-big icon-modal"></span>
                </div>
              </div>
              <div className="modal-note">Вы можете войти в Ваш личный кабинет.</div>
              <a href="/profile/orders" onClick={() => this.props.changeModal()} className="button">Личный кабинет</a>
            </div>
            :
            !this.state.isConfirmPass ?
              <form autoComplete="off" onSubmit={this.submitFormPass}>
                <div className={"modal-content" + (this.state.isOpened ? " open" : "")}>
                  <div className="modal-close" onClick={() => this.props.changeModal()}><span className="icon-close icon-small"></span></div>
                  <div className="modal-title">Восстановление пароля</div>
                  <div className="modal-note ssl-note">Убедитесь, что находитесь на странице<span className="icon-ssl"></span><span className="ssl">https://</span>www.wmsim.com</div>
                  <div className="field">
                    <input type="hidden" className="input" id="confirmCode" name="confirmCode" value={this.state.confirmCode} />
                    <input type={this.state.inputTypeNew ? "text" : "password"} className={"input" + (this.state.showDanger['confirmNewPassword'] ? " input-danger" : "")} id="confirmNewPassword" name="confirmNewPassword" autoComplete="off" placeholder="Новый пароль" onClick={this.clickForm(Password)} onBlur={this.blurForm(Password)} onChange={this.changeForm(Password)} />
                    <div className="field-icon"><span className={(this.state.inputTypeNew ? "icon-show-password" : "icon-hide-password") + " icon-medium"} onClick={() => this.changeInputType('inputTypeNew')}></span></div>
                    <Tooltip status={this.state.showToolTip['confirmNewPassword']} closeToolTip={() => this.closeToolTip('confirmNewPassword')} text="Новый пароль должен содержать не менее 8 символов и включать в себя цифры, латинские строчные и прописные буквы." />
                  </div>
                  <div className="field">
                    <input type={this.state.inputTypeRepeat ? "text" : "password"} className={"input" + (this.state.showDanger['confirmRepeatPassword'] ? " input-danger" : "")} id="confirmRepeatPassword" name="confirmRepeatPassword" autoComplete="off" placeholder="Повтор нового пароля" onClick={this.clickForm(Password)} onBlur={this.blurForm(Password)} onChange={this.changeForm(Password)} />
                    <div className="field-icon"><span className={(this.state.inputTypeRepeat ? "icon-show-password" : "icon-hide-password") + " icon-medium"} onClick={() => this.changeInputType('inputTypeRepeat')}></span></div>
                    <Tooltip status={this.state.showToolTip['confirmRepeatPassword']} closeToolTip={() => this.closeToolTip('confirmRepeatPassword')} text="Введите повторно новый пароль." />
                    <Tooltip status={this.state.showToolTip['confirmMatchPassword']} closeToolTip={() => this.closeToolTip('confirmMatchPassword')} text="Введенные пароли не совпадают." />
                  </div>
                  <div className="modal-note">Пароль должен содержать не менее 8 символов и включать в себя цифры, латинские строчные и прописные буквы.</div>
                  <button className={"button" + (this.state.isSubmitted['submitFormPass'] ? " button-loading" : "")} disabled={this.state.isDisabled} type="submit">Подтвердить</button>
                </div>
              </form>
              :
              <div className="modal-content open">
                <div className="modal-close" onClick={() => this.props.changeModal()}><span className="icon-close icon-small"></span></div>
                <div className="modal-title">Восстановление пароля</div>
                <div className="modal-note">Ваш пароль успешно изменен.</div>
                <div className="modal-block">
                  <div className="modal-column">
                    <span className="icon-lock icon-big icon-modal"></span>
                  </div>
                  <div className="modal-column">
                    <span className="icon-success icon-big icon-modal"></span>
                  </div>
                  <div className="modal-column">
                    <span className="icon-user icon-big icon-modal"></span>
                  </div>
                </div>
                <div className="modal-note">Вы можете войти в Ваш личный кабинет.</div>
                <a href="/profile/orders" onClick={() => this.props.changeModal()} className="button">Личный кабинет</a>
              </div>
        }
      </div>
    )
  }
}

export default Confirm;