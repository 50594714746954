import React, { Component, Suspense } from 'react';
import { NavLink } from 'react-router-dom';
import Captcha from '../captcha';
import Tooltip from '../tooltip';
import { Email, Message } from '../components/constants';
const SupportLazy = React.lazy(() => import('./lazy/support'));

class Support extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpened: false,
      isDisabled: false,
      isSubmitted: {},
      showToolTip: [],
      showDanger: [],
      supportEmail: '',
      supportMessage: '',
      showNotice: false,
      notification: [],
      pattern: [["supportEmail", false], ["supportMessage", false]]
    };
    this.openToolTip = this.openToolTip.bind(this);
    this.closeToolTip = this.closeToolTip.bind(this);
    this.resetInputData = this.resetInputData.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount() {
    let title = 'Центр поддержки | ' + process.env.REACT_APP_NAME;
    let description = 'Требуется помощь? Ознакомьтесь с быстрыми ответами на часто задаваемые вопросы о платформе WMSIM или отправьте запрос в Центр поддержки.';
    document.title = title;
    document.querySelector("meta[name='description']").setAttribute('content', description);
    document.querySelector("meta[property='og:title']").setAttribute('content', title);
    document.querySelector("meta[property='og:description']").setAttribute('content', description);
    document.querySelector("meta[property='og:url']").setAttribute('content', window.location.href);
    document.querySelector("link[rel='canonical']").setAttribute('href', window.location.href);
    this.resetInputData();
  }

  componentDidUpdate() {
    if (this.state.isOpened) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.removeAttribute("style");
    }
    if (this.state.showNotice) {
      this.timer = setTimeout(() => this.setState({ showNotice: false }), 5000);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  resetInputData() {
    document.querySelectorAll('input,textarea').forEach(function (item) {
      item.value = "";
    });
    this.closeDanger();
    this.setState({ supportEmail: '', supportMessage: '' });
  }

  changeModal = () => {
    this.setState({ isOpened: !this.state.isOpened });
  }

  openToolTip(i) {
    const openStatus = this.state.showToolTip.slice();
    openStatus[i] = true;
    this.setState({ showToolTip: openStatus });
  }

  closeToolTip(i) {
    if (i === undefined) {
      this.setState({ showToolTip: [] });
    } else {
      const closeStatus = this.state.showToolTip.slice();
      closeStatus[i] = false;
      this.setState({ showToolTip: closeStatus });
    }
  }

  openDanger(i) {
    const openStatus = this.state.showDanger;
    openStatus[i] = true;
    this.setState({ showDanger: openStatus });
  }

  closeDanger(i) {
    if (i === undefined) {
      this.setState({ showDanger: [] });
    } else {
      const closeStatus = this.state.showDanger;
      closeStatus[i] = false;
      this.setState({ showDanger: closeStatus });
    }
  }

  clickForm = a => event => {
    const pattern = new RegExp(a);
    if (event.target.value !== '' || (event.target.value === '' && this.state.showDanger[event.target.name] === true)) {
      if (!pattern.test(event.target.value)) {
        this.openDanger(event.target.name);
        this.openToolTip(event.target.name);
      } else {
        this.closeDanger(event.target.name);
        this.closeToolTip(event.target.name);
      }
    }
  }

  blurForm = a => event => {
    this.closeToolTip();
    const pattern = new RegExp(a);
    if (!pattern.test(event.target.value)) {
      this.openDanger(event.target.name);
    }
  }

  changeForm = a => event => {
    if (event.target.name === 'supportEmail') {
      event.target.value = event.target.value.toLowerCase().replace(/\s/g, '');
    }
    if (event.target.name === 'supportMessage') {
      event.target.value = event.target.value.replace(/^./, u => u.toUpperCase());
    }
    this.setState({ [event.target.name]: event.target.value })
    const pattern = new RegExp(a);
    if (event.target.value !== '') {
      if (pattern.test(event.target.value)) {
        this.closeDanger(event.target.name);
        this.closeToolTip(event.target.name);
      }
    } else {
      this.closeToolTip(event.target.name);
      this.closeDanger(event.target.name);
    }
  }

  submitCaptcha() {
    this.setState({ isDisabled: true, isSubmitted: { submitForm: true } });
    fetch('/api/support', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 'supportEmail': this.state.supportEmail, 'supportMessage': this.state.supportMessage }),
    })
      .then(response => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ notification: 'Запрос успешно отправлен.', showNotice: true });
          this.resetInputData();
        }
        this.setState({ isDisabled: false, isSubmitted: {} });
      })
  }

  submitForm(e) {
    e.preventDefault();
    if (this.state.supportEmail === '') {
      this.openDanger('supportEmail');
      document.getElementById('supportEmail').focus();
    }
    if (this.state.supportMessage === '') {
      this.openDanger('supportMessage');
      document.getElementById('supportMessage').focus();
    }

    var sortDanger = this.state.pattern.map(pattern => Object.entries(this.state.showDanger).find(item => item[0] === pattern[0])).filter(item => item !== undefined);
    var trueProps = sortDanger.filter(([key, value]) => value === true);

    if (trueProps.length > 0) {
      document.getElementById(trueProps[0][0]).focus();
      this.openToolTip(trueProps[0][0]);
    } else if (!this.state.isOpened) {
      this.setState({ isOpened: true });
    }
  }

  render() {
    let urlElements = window.location.href.split('/');
    var supportMail = <div>
      <h2>Отправить запрос</h2>
      <form autoComplete="off" onSubmit={this.submitForm}>
        <div className="field">
          <input type="text" className={"input" + (this.state.showDanger['supportEmail'] ? " input-danger" : "")} id="supportEmail" name="supportEmail" placeholder="E-mail" autoComplete="off" onClick={this.clickForm(Email)} onBlur={this.blurForm(Email)} onChange={this.changeForm(Email)} />
          <div className="field-icon"><span className="icon-email icon-medium"></span></div>
          <Tooltip status={this.state.showToolTip['supportEmail']} closeToolTip={() => this.closeToolTip('supportEmail')} text="Введите корректный E-mail." />
        </div>
        <div className="field">
          <textarea className={"input" + (this.state.showDanger['supportMessage'] ? " input-danger" : "")} id="supportMessage" name="supportMessage" placeholder="Сообщение: Сформулируйте Ваш запрос максимально точно." autoComplete="off" onClick={this.clickForm(Message)} onBlur={this.blurForm(Message)} onChange={this.changeForm(Message)} />
          <Tooltip status={this.state.showToolTip['supportMessage']} closeToolTip={() => this.closeToolTip('supportMessage')} text="Введите Ваше сообщение от 15 до 500 символов." />
        </div>
        <button className={"button" + (this.state.isSubmitted['submitForm'] ? " button-loading" : "")} disabled={this.state.isDisabled} type="submit" onBlur={this.blurForm()}>Отправить</button>
      </form>
    </div>;
    var supportChats = <div>
      <h2>Альтернативные способы связи</h2>
      <p>При обращении через Telegram, Skype и ICQ будьте внимательны - в данных приложениях могут быть похожие аккаунты посторонних лиц, которые не имеют отношения к нашему сервису.</p>
      <p>Чтобы избежать ошибки и обратиться именно к нам, переходите по ссылкам ниже и не пользуйтесь поиском в данных приложениях.</p>
      <div className="default-chats">
        <div className="default-chat-item">
          <button className="button" onClick={() => window.open('tg://resolve?domain=wmsimcom', '_self')}>Telegram</button>
        </div>
        <div className="default-chat-item">
          <button className="button" onClick={() => window.open('skype:wmsimru?chat', '_self')}>Skype</button>
        </div>
        <div className="default-chat-item">
          <button className="button" onClick={() => window.open('icq:385356505', '_self')}>ICQ</button>
        </div>
      </div>
    </div>;

    return (
      <div className="page">
        <div className="page-content">
          <div className={"notice fade" + (this.state.showNotice ? " in" : "")}>{this.state.notification}</div>
          <div className="default">
            <div className="default-column">
              <div className="default-menu">
                <div>
                  <NavLink to="/support" onClick={this.resetInputData} className="default-nav-item" activeClassName={urlElements[3] === "support" || urlElements[3] === "support#order" || urlElements[3] === "support#profile" || urlElements[3] === "support#webmoney" || urlElements[3] === "support#other" ? "default-nav-item-active" : null}>Общие вопросы</NavLink>
                  <a href="#order" className="default-nav-item default-nav-subitem">Заявка</a>
                  <a href="#profile" className="default-nav-item default-nav-subitem">Личный кабинет</a>
                  <a href="#webmoney" className="default-nav-item default-nav-subitem">WebMoney</a>
                  <a href="#other" className="default-nav-item default-nav-subitem">Разное</a>
                  <NavLink to="#help" onClick={this.resetInputData} className="default-nav-item" activeClassName={urlElements[3] === "support#help" ? "default-nav-item-active" : null}>Помощь</NavLink>
                  <NavLink to="#cooperation" onClick={this.resetInputData} className="default-nav-item" activeClassName={urlElements[3] === "support#cooperation" ? "default-nav-item-active" : null}>Сотрудничество</NavLink>
                </div>
              </div>
            </div>
            <div className="default-column">
              <div className="default-content">
                <h1>Центр поддержки</h1>
                <div className="icon-faq icon-main"></div>
              </div>
              <div className="default-submenu" >
                <NavLink to="/support" className="user-nav-item" activeClassName={urlElements[3] === "support" ? "user-nav-item-active" : null}>Общие вопросы</NavLink>
                <NavLink to="#help" className="user-nav-item" activeClassName={urlElements[3] === "support#help" ? "user-nav-item-active" : null}>Помощь</NavLink>
                <NavLink to="#cooperation" className="user-nav-item" activeClassName={urlElements[3] === "support#cooperation" ? "user-nav-item-active" : null}>Сотрудничество</NavLink>
              </div>
              {urlElements[3] === "support#help" ?
                <div>
                  <p>Перед отправкой запроса в Центр поддержки рекомендуется ознакомиться с разделом <NavLink to="support">Общие вопросы</NavLink>, возможно там уже есть ответ на Ваш вопрос.</p>
                  <p>Если Вы не нашли ответ на свой вопрос и Вам требуется помощь, свяжитесь с нами одним из доступных способов для получения быстрого ответа. Обычно мы отвечаем в течение 1-60 минут в зависимости от загрузки операторов.</p>
                  <h2>Онлайн-чат</h2>
                  <p>Предпочтительным способом связи с Центром поддержки является Онлайн-чат на нашем сайте.</p>
                  <button className="button" onClick={() => document.getElementById('jivo_custom_widget').click()}>Онлайн-чат</button>
                  {supportMail}
                  {supportChats}
                </div>
                : urlElements[3] === "support#cooperation" ?
                  <div>
                    <p>Мы всегда открыты к сотрудничеству и заинтересованы в развитии нашего сервиса.</p>
                    <p>Если у Вас есть предложения по улучшению сервиса, сотрудничеству, подключению мониторинга обменных курсов, размещению рекламы, а также другие предложения - напишите нам и мы с удовольствием рассмотрим Ваше обращение!</p>
                    {supportMail}
                    {supportChats}
                  </div>
                  :
                  <Suspense fallback={<div className="data-loading"></div>}>
                    <SupportLazy />
                  </Suspense>
              }
            </div>
          </div>
        </div>
        {this.state.isOpened ? <Captcha isOpened={this.state.isOpened} submitCaptcha={() => this.submitCaptcha(this)} changeModal={() => this.changeModal(this)} /> : null}
      </div>
    );
  }
}

export default Support;