import React, { Component } from 'react';
import Captcha from './captcha';
import Tooltip from './tooltip';
import { Email } from './components/constants';

class Recovery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recoveryEmail: '',
      showToolTip: [],
      showDanger: [],
      isOpened: false,
      isOpenedCaptcha: false,
      isRecovery: false,
      isSubmitted: {},
      isDisabled: false,
      pattern: [["recoveryEmail", false]]
    }
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount() {
    this.timer = setTimeout(() => this.setState({ isOpened: this.props.isOpened ? true : false }), 1);
  }

  changeModal = () => {
    this.setState({ isOpenedCaptcha: !this.state.isOpenedCaptcha });
  }

  openToolTip(i) {
    const openStatus = this.state.showToolTip.slice();
    openStatus[i] = true;
    this.setState({ showToolTip: openStatus });
  }

  closeToolTip(i) {
    if (i === undefined) {
      this.setState({ showToolTip: [] });
    } else {
      const closeStatus = this.state.showToolTip.slice();
      closeStatus[i] = false;
      this.setState({ showToolTip: closeStatus });
    }
  }

  openDanger(i) {
    const openStatus = this.state.showDanger;
    openStatus[i] = true;
    this.setState({ showDanger: openStatus });
  }

  closeDanger(i) {
    const closeStatus = this.state.showDanger;
    closeStatus[i] = false;
    this.setState({ showDanger: closeStatus });
  }

  clickForm = a => event => {
    this.closeToolTip(event.target.name);
    const pattern = new RegExp(a);
    if (event.target.value !== '' || (event.target.value === '' && this.state.showDanger[event.target.name] === true)) {
      if (!pattern.test(event.target.value)) {
        this.openDanger(event.target.name);
        this.openToolTip(event.target.name);
      } else {
        this.closeDanger(event.target.name);
        this.closeToolTip(event.target.name);
      }
    }
  }

  blurForm = a => event => {
    this.closeToolTip();
    const pattern = new RegExp(a);
    if (!pattern.test(event.target.value)) {
      this.openDanger(event.target.name);
    }
  }

  changeForm = a => event => {
    event.target.value = event.target.value.toLowerCase().replace(/\s/g, '');
    this.setState({ [event.target.name]: event.target.value })
    const pattern = new RegExp(a);
    if (event.target.value !== '') {
      if (pattern.test(event.target.value)) {
        this.closeDanger(event.target.name);
        this.closeToolTip(event.target.name);
      }
    } else {
      this.closeToolTip(event.target.name);
      this.closeDanger(event.target.name);
    }
  }

  submitCaptcha = () => {
    this.setState({ isDisabled: true, isSubmitted: { submitForm: true } });
    fetch('/api/recovery', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 'recoveryEmail': this.state.recoveryEmail }),
    })
      .then(response => response.json())
      .then((result) => {
        if (!result.status) {
          this.openToolTip('recoveryEmailCheck');
          this.openDanger('recoveryEmail');
        } else {
          this.setState({ isRecovery: true });
        }
        this.setState({ isDisabled: false, isSubmitted: {} });
      })
  }

  submitForm(e) {
    e.preventDefault();

    if (this.state.recoveryEmail === '') {
      this.openDanger('recoveryEmail');
    }

    var sortDanger = this.state.pattern.map(pattern => Object.entries(this.state.showDanger).find(item => item[0] === pattern[0])).filter(item => item !== undefined);
    var trueProps = sortDanger.filter(([key, value]) => value === true);

    if (trueProps.length > 0) {
      this.openToolTip(trueProps[0][0]);
    } else if (!this.state.isOpenedCaptcha) {
      this.setState({ isOpenedCaptcha: true });
    }
  }

  render() {
    return (
      <div className={"modal-overlay" + (this.props.isOpened ? " open" : " close")}>
        {!this.state.isRecovery ?
          <form autoComplete="off" onSubmit={this.submitForm}>
            <div className={"modal-content" + (this.state.isOpened ? " open" : "")}>
              <div className="modal-close" onClick={() => this.props.changeModal()}><span className="icon-close icon-small"></span></div>
              <div className="modal-title">Восстановление пароля</div>
              <div className="modal-note ssl-note">Убедитесь, что находитесь на странице<span className="icon-ssl"></span><span className="ssl">https://</span>www.wmsim.com</div>
              <div className="field">
                <input type="text" className={"input" + (this.state.showDanger['recoveryEmail'] ? " input-danger" : "")} id="recoveryEmail" name="recoveryEmail" autoComplete="off" placeholder="E-mail" onClick={this.clickForm(Email)} onBlur={this.blurForm(Email)} onChange={this.changeForm(Email)} />
                <div className="field-icon"><span className="icon-email icon-medium"></span></div>
                <Tooltip status={this.state.showToolTip['recoveryEmail']} closeToolTip={() => this.closeToolTip('recoveryEmail')} text="Введите корректный E-mail." />
                <Tooltip status={this.state.showToolTip['recoveryEmailCheck']} closeToolTip={() => this.closeToolTip('recoveryEmailCheck')} text="Указанный E-mail не зарегистрирован." />
              </div>
              <button className={"button" + (this.state.isSubmitted['submitForm'] ? " button-loading" : "")} disabled={this.state.isDisabled} type="submit" onBlur={this.blurForm()}>Сбросить пароль</button>
              <div className="modal-note last">У Вас еще нет аккаунта? <span id="recovery-signup">Регистрация</span> <span id="recovery-signin">Вход</span></div>
            </div>
          </form>
          :
          <div className="modal-content open">
            <div className="modal-close" onClick={() => this.props.changeModal()}><span className="icon-close icon-small"></span></div>
            <div className="modal-title">Восстановление пароля</div>
            <div className="modal-note">Письмо с инструкциями отправлено на Вашу почту.</div>
            <div className="modal-block">
              <div className="modal-column">
                <span className="icon-lock icon-big icon-modal"></span>
              </div>
              <div className="modal-column">
                <span className="icon-mail icon-big icon-modal"></span>
              </div>
              <div className="modal-column">
                <span className="icon-user icon-big icon-modal"></span>
              </div>
            </div>
            <div className="modal-note">Для подтверждения сброса пароля перейдите по ссылке в письме.</div>
            <button className="button" onClick={() => this.props.changeModal()}>Закрыть</button>
          </div>
        }
        {this.state.isOpenedCaptcha ? <Captcha isOpened={this.state.isOpenedCaptcha} submitCaptcha={() => this.submitCaptcha(this)} changeModal={() => this.changeModal(this)} /> : null}
      </div>
    )
  }
}

export default Recovery;