import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Withdraw from '../../withdraw';
import { AccountMenu } from '../../components/account_menu';

class Referrals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      isFetching: true,
      isSorting: false,
      currentItem: 0,
      totalLoading: 0,
      totalCount: 0,
      referralsFollow: '',
      referralsCount: '',
      referralsVolume: '',
      referralsEarned: '',
      referralsPaid: '',
      referralsAvailable: '',
      isOpened: false,
      notification: '',
      showNotice: false,
      isOpenedMenu: false
    }
    this.withdrawFundsConfirm = this.withdrawFundsConfirm.bind(this);
    this.accountSection = React.createRef();
    this.menuSection = React.createRef();
    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  loadingData() {
    let urlElements = window.location.href.split('/');
    if (urlElements[4] === "referrals") {
      fetch('/api/profile/referrals', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.props.accessToken,
        },
        body: JSON.stringify({ 'loading': true }),
      })
        .then(response => response.json())
        .then((result) => {
            this.setState({
              data: result.data,
              referralsFollow: result.data.map(item => item.referrals.map(item => item.ref_follow)),
              referralsCount: result.data.map(item => item.referrals.map(item => item.ref_count)),
              referralsVolume: result.data.map(item => item.referrals.map(item => item.ref_volume)),
              referralsEarned: result.data.map(item => item.referrals.map(item => item.ref_earned)),
              referralsPaid: result.data.map(item => item.referrals.map(item => item.ref_paid)),
              referralsAvailable: parseFloat(result.data.map(item => item.referrals.map(item => item.ref_available))).toFixed(2),
              loading: false
            });
        }, (error) => {
          if (error) {
            localStorage.removeItem('token');
            window.location.href = '/?login=true';
          }
        });
    } else {
      const url = urlElements[4] === "referrals#withdrawals" ? 'referrals-withdrawals' : urlElements[4] === "referrals#registrations" ? 'referrals-registrations' : 'referrals-earnings';
      if (this.state.isFetching) {
        fetch('/api/profile/' + url, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.props.accessToken,
          },
          body: JSON.stringify({ 'limit': 10, 'item': this.state.currentItem }),
        })
          .then(response => { this.setState({ totalCount: response.headers.get('total-count') }); return response.json() })
          .then(result => {
              if (this.state.data.length > 0) {
                this.setState({ ...this.state.data, ...this.state.data[0].referrals.push(...result.data[0].referrals), loading: false, totalLoading: this.state.totalLoading + result.data[0].referrals.length });
              } else {
                this.setState({ data: result.data, loading: false, totalLoading: result.data[0].referrals.length });
              }
          }, (error) => {
            if (error) {
              localStorage.removeItem('token');
              window.location.href = '/?login=true';
            }
          })
          .finally(() => this.setState({ isFetching: false }));
      }

    }
  }

  scrollHadler = (e) => {
    if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) <= 240 && this.state.totalLoading < this.state.totalCount && !this.state.isFetching) {
      this.setState({ isFetching: true, currentItem: this.state.currentItem + 10 });
      this.loadingData();
    }
  }

  componentDidMount() {
    if (!this.props.accessToken) {
      window.location.href = '/?login=true';
    }
    this.loadingData();
    document.title = 'Партнерская программа | ' + process.env.REACT_APP_NAME;
    document.addEventListener('scroll', this.scrollHadler);
    document.addEventListener('mousedown', this.closeMenu);
  }

  componentDidUpdate() {
    if (!this.props.accessToken) {
      window.location.href = '/?login=true';
    }
    if (this.state.isOpened) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.removeAttribute("style");
    }
    if (this.state.isSorting) {
      this.setState({ isSorting: false });
      this.loadingData();
    }
    if (this.state.showNotice) {
      this.timer = setTimeout(() => this.setState({ showNotice: false }), 5000);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
    document.removeEventListener('scroll', this.scrollHadler);
    document.removeEventListener('mousedown', this.closeMenu);
  }

  changeSort() {
    this.setState({ isFetching: true, isSorting: true, currentItem: 0, data: [], loading: true });
  }

  changeModal = () => {
    this.setState({ isOpened: !this.state.isOpened });
  }

  copyText = () => {
    navigator.clipboard.writeText(process.env.REACT_APP_URL + '/?pid=' + this.props.verifyToken);
    this.setState({ notification: 'Партнерская ссылка скопирована.', showNotice: true });
  }

  openLink = (url, width, height) => {
    window.open(url, "", "scrollbars=1,resizable=1,menubar=0,toolbar=0,status=0,left=" + ((window.innerWidth - width) / 2) + ",top=" + ((window.innerHeight - height) / 2) + ",width=" + width + ",height=" + height);
  }

  withdrawFundsConfirm() {
    this.setState({ isOpened: !this.state.isOpened });
  }

  withdrawFunds = (a, b, c) => {
    fetch('/api/profile/referrals-withdraw', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.accessToken,
      },
      body: JSON.stringify({ 'amount': a, 'wallet': b, 'system': c }),
    })
      .then(response => response.json())
      .then((result) => {
          if (result.status) {
            this.setState({ isOpened: !this.state.isOpened, notification: 'Вывод средств успешно завершен.', showNotice: true });
            this.loadingData();
          } else if (!result.status) {
            this.setState({ isOpened: !this.state.isOpened, notification: 'Произошла ошибка.', showNotice: true });
            this.loadingData();
          }
      }, (error) => {
        if (error) {
          localStorage.removeItem('token');
          window.location.href = '/?login=true';
        }
      })
  }

  showMenu() {
    this.setState({ isOpenedMenu: !this.state.isOpenedMenu });
  }

  closeMenu(e) {
    if (this.accountSection.current && !this.accountSection.current.contains(e.target)) {
      if (this.state.isOpenedMenu && this.menuSection.current && !this.menuSection.current.contains(e.target)) {
        this.setState({ isOpenedMenu: false });
      }
    }
  }

  render() {
    let refText = 'Рекомендую надежный сервис для обмена электронных валют! Регистрируйся по моей ссылке и получи скидку на обмен!';
    let urlElements = window.location.href.split('/');

    if (urlElements[4] === "referrals#withdrawals") {
      var count = this.state.data.map(obj => Object.keys(obj.referrals).length)[0];
      var referrals = this.state.data.map(item => item.referrals.sort((a, b) => b.id - a.id).map((item, index) => {
        return (
          <div className="user-order-item" key={index}>
            <div className="user-order-block">
              <div className="user-order-column">
                <div className="user-order-outcome"><span className={"icon-default" + (item.system === 'WebMoney WMZ' ? " icon-webmoney" : " icon-qiwi")}></span><span className="user-order-amount">{item.amount} {item.system === 'WebMoney WMZ' ? "WMZ" : "RUB"}</span></div>
              </div>
              <div className="user-order-column">
                <div className="user-order-wallet">{item.wallet}</div>
              </div>
              <div className="user-order-column">
                <div className="user-order-number">{(item.date).replace(/([^<]*)\s([^<]*)/, '$1')}</div>
              </div>
              <div className="user-order-column">
                <div className="user-order-status">{item.status === '1' ? 'Успешно' : null}</div>
              </div>
            </div>
          </div>
        )
      }));
    } else if (urlElements[4] === "referrals#registrations") {
      count = this.state.data.map(obj => Object.keys(obj.referrals).length)[0];
      referrals = this.state.data.map(item => item.referrals.sort((a, b) => b.id - a.id).map((item, index) => {
        return (
          <div className="user-order-item" key={index}>
            <div className="user-order-block">
              <div className="user-order-column">
                <div className="user-order-number">{item.id}</div>
              </div>
              <div className="user-order-column">
                <div className="user-order-number">{item.login}</div>
              </div>
              <div className="user-order-column">
                <div className="user-order-number">{(item.date).replace(/([^<]*)\s([^<]*)/, '$1')}</div>
              </div>
              <div className="user-order-column">
                <div className="user-order-number">{item.total_amount} USD</div>
              </div>
            </div>
          </div>
        )
      }));
    } else if (urlElements[4] === "referrals#earnings") {
      count = this.state.data.map(obj => Object.keys(obj.referrals).length)[0];
      referrals = this.state.data.map(item => item.referrals.sort((a, b) => b.id - a.id).map((item, index) => {
        return (
          <div className="user-order-item" key={index}>
            <div className="user-order-block">
              <div className="user-order-column">
                <div className="user-order-number">{item.amount} USD</div>
              </div>
              <div className="user-order-column">
                <div className="user-order-wallet">{item.rid}</div>
              </div>
              <div className="user-order-column">
                <div className="user-order-number">{(item.date).replace(/([^<]*)\s([^<]*)/, '$1')}</div>
              </div>
              <div className="user-order-column">
                <div className="user-order-status">{item.status === '1' ? 'Успешно' : null}</div>
              </div>
            </div>
          </div>
        )
      }));
    }
    return (
      <div className="page">
        <div className="page-content">
          <div className={"notice fade" + (this.state.showNotice ? " in" : "")}>{this.state.notification}</div>
          <div className="account">
            <div className="account-column">
              <div className="account-section" onClick={this.showMenu} ref={this.accountSection}>
                <span className="account-section-title">Кабинет</span>
                <span className={'icon-arrow icon-default' + (this.state.isOpenedMenu ? ' rotate' : '')}></span>
              </div>
              <div className={'account-menu' + (this.state.isOpenedMenu ? ' open' : '')} ref={this.menuSection}>{AccountMenu}</div>
            </div>
            <div className="account-column">
              <div className="account-content">
                <h1>Партнерская программа</h1>
                <div className="icon-referrals icon-main"></div>
              </div>
              <div className="user-menu">
                <NavLink to="/profile/referrals" onClick={() => this.changeSort()} className="user-nav-item" activeClassName={urlElements[4] === "referrals" ? "user-nav-item-active" : null}>Стастистика</NavLink>
                <NavLink to="#registrations" onClick={() => this.changeSort()} className="user-nav-item" activeClassName={urlElements[4] === "referrals#registrations" ? "user-nav-item-active" : null}>Рефералы</NavLink>
                <NavLink to="#earnings" onClick={() => this.changeSort()} className="user-nav-item" activeClassName={urlElements[4] === "referrals#earnings" ? "user-nav-item-active" : null}>История начислений</NavLink>
                <NavLink to="#withdrawals" onClick={() => this.changeSort()} className="user-nav-item" activeClassName={urlElements[4] === "referrals#withdrawals" ? "user-nav-item-active" : null}>История выплат</NavLink>
                <NavLink to="/partners" className="user-nav-item">О программе</NavLink>
              </div>
              {this.state.loading ?
                <div className="data-loading"></div>
                :
                urlElements[4] === "referrals#registrations" ?
                  count !== 0 ?
                    <div>
                      <div className="user-orders">
                        <div className="user-order-menu">
                          <div className="user-order-block">
                            <div className="user-order-column">ID</div>
                            <div className="user-order-column">Логин</div>
                            <div className="user-order-column">Дата</div>
                            <div className="user-order-column">Оборот</div>
                          </div>
                        </div>
                      </div>
                      <div className="user-orders">
                        {referrals}
                      </div>
                      {this.state.totalLoading < this.state.totalCount ?
                        <div className="data-loading next"></div>
                        :
                        null}
                    </div>
                    :
                    <div className="account-description-empty">
                      <div className="field"><span className="icon-empty icon-big"></span></div>
                      <div className="field">У Вас еще нет рефералов</div>
                    </div>
                  :
                  urlElements[4] === "referrals#earnings" ?
                    count !== 0 ?
                      <div>
                        <div className="user-orders">
                          <div className="user-order-menu">
                            <div className="user-order-block">
                              <div className="user-order-column">Сумма</div>
                              <div className="user-order-column">ID реферала</div>
                              <div className="user-order-column">Дата</div>
                              <div className="user-order-column">Статус</div>
                            </div>
                          </div>
                        </div>
                        <div className="user-orders">
                          {referrals}
                        </div>
                        {this.state.totalLoading < this.state.totalCount ?
                          <div className="data-loading next"></div>
                          :
                          null}
                      </div>
                      :
                      <div className="account-description-empty">
                        <div className="field"><span className="icon-empty icon-big"></span></div>
                        <div className="field">У Вас еще нет начислений</div>
                      </div> :
                    urlElements[4] === "referrals#withdrawals" ?
                      count !== 0 ?
                        <div>
                          <div className="user-orders">
                            <div className="user-order-menu">
                              <div className="user-order-block">
                                <div className="user-order-column">Сумма</div>
                                <div className="user-order-column">Кошелек</div>
                                <div className="user-order-column">Дата</div>
                                <div className="user-order-column">Статус</div>
                              </div>
                            </div>
                          </div>
                          <div className="user-orders">
                            {referrals}
                          </div>
                          {this.state.totalLoading < this.state.totalCount ?
                            <div className="data-loading next"></div>
                            :
                            null}
                        </div>
                        :
                        <div className="account-description-empty">
                          <div className="field"><span className="icon-empty icon-big"></span></div>
                          <div className="field">У Вас еще нет выплат</div>
                        </div>
                      :
                      <div>
                        <h2>Рефералы</h2>
                        <div className="user-data">
                          <div className="user-data-menu">
                            <div className="user-data-block">
                              <div className="user-data-column">Переходы</div>
                              <div className="user-data-column">Регистрации</div>
                              <div className="user-data-column">Оборот</div>
                            </div>
                          </div>
                        </div>
                        <div className="user-data">
                          <div className="user-data-item">
                            <div className="user-data-block">
                              <div className="user-data-column">
                                <div className="user-data-info">{this.state.referralsFollow}</div>
                              </div>
                              <div className="user-data-column">
                                <div className="user-data-info">{this.state.referralsCount}</div>
                              </div>
                              <div className="user-data-column">
                                <div className="user-data-info">{this.state.referralsVolume} USD</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <h2>Вознаграждение</h2>
                        <div className="user-data">
                          <div className="user-data-menu">
                            <div className="user-data-block">
                              <div className="user-data-column">Начислено</div>
                              <div className="user-data-column">Выплачено</div>
                              <div className="user-data-column">Доступно</div>
                            </div>
                          </div>
                        </div>
                        <div className="user-data field">
                          <div className="user-data-item">
                            <div className="user-data-block">
                              <div className="user-data-column">
                                <div className="user-data-info">{this.state.referralsEarned} USD</div>
                              </div>
                              <div className="user-data-column">
                                <div className="user-data-info">{this.state.referralsPaid} USD</div>
                              </div>
                              <div className="user-data-column">
                                <div className="user-data-info">{this.state.referralsAvailable} USD</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <button className="button" onClick={() => this.withdrawFundsConfirm()}>Вывод средств</button>
                        <h2>Партнерская ссылка</h2>
                        <div className="user-link">
                          <div className="field-copy-icon"><span className="icon-copy icon-medium" data-tooltip="скопировать" onClick={() => this.copyText()}></span></div>
                          {process.env.REACT_APP_URL}/?pid={this.props.verifyToken}
                        </div>
                        <div className="note">
                          <p>Разместите Вашу партнерскую ссылку на страницах Вашего сайта или на любой странице в сети интернет (социальные сети, блоги, форумы и т.д.) и начните получать доход от обменов привлеченных Вами новых пользователей.</p>
                          <span style={{ verticalAlign: 'middle', marginRight: '0.25rem' }}>Поделиться ссылкой в социальных сетях:</span>
                          <span className="icon-telegram icon-big" onClick={() => this.openLink('https://t.me/share/url?url=' + encodeURIComponent(process.env.REACT_APP_URL + '/?pid=' + this.props.verifyToken) + '&text=' + encodeURIComponent(refText), '600', '400')}></span>
                          <span className="icon-twitter icon-big" onClick={() => this.openLink('https://twitter.com/intent/tweet?text=' + encodeURIComponent(refText) + '&url=' + encodeURIComponent(process.env.REACT_APP_URL + '/?pid=' + this.props.verifyToken), '600', '400')}></span>
                          <span className="icon-facebook icon-big" onClick={() => this.openLink('https://www.facebook.com/sharer.php?src=sp&u=' + encodeURIComponent(process.env.REACT_APP_URL + '/?pid=' + this.props.verifyToken) + '&title=' + encodeURIComponent(refText), '600', '400')}></span>
                          <span className="icon-vk icon-big" onClick={() => this.openLink('https://vk.com/share.php?url=' + encodeURIComponent(process.env.REACT_APP_URL + '/?pid=' + this.props.verifyToken) + '&title=' + encodeURIComponent(refText), '600', '400')}></span>
                        </div>
                      </div>
              }
            </div>
          </div>
        </div>
        {this.state.isOpened ? <Withdraw referralsAvailable={this.state.referralsAvailable} isOpened={this.state.isOpened} withdrawFunds={(a, b, c) => this.withdrawFunds(a, b, c)} changeModal={() => this.changeModal(this)} /> : null}
      </div>
    );
  }
}

export default Referrals;