import React, { Component } from 'react';

class Language extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpened: false
        }
    }

    componentDidMount() {
        this.timer = setTimeout(() => this.setState({ isOpened: this.props.isOpened ? true : false }), 1);
    }

    render() {
        return (
            <div className={"modal-overlay" + (this.props.isOpened ? " open" : " close")}>
                <div className={"modal-content" + (this.state.isOpened ? " open" : "")}>
                    <div className="modal-close" onClick={this.props.changeModal}><span className="icon-close icon-small"></span></div>
                    <div className="modal-title"><span className="icon-globe icon-big"></span>Выбор языка</div>
                    <div className="languages">
                        <div className="language-item" onClick={() => window.location.href = '/'}>
                            <span className="ru icon-big"></span>
                            <span>Русский</span>
                        </div>
                        <div className="language-item">
                        </div>
                        <div className="language-item" onClick={() => window.location.href = '/en'}>
                            <span className="gb icon-big"></span>
                            <span>English</span>
                        </div>
                        <div className="language-item" onClick={() => window.location.href = '/de'}>
                            <span className="de icon-big"></span>
                            <span>Deutsch</span>
                        </div>
                        <div className="language-item">
                        </div>
                        <div className="language-item" onClick={() => window.location.href = '/fr'}>
                            <span className="fr icon-big"></span>
                            <span>Français</span>
                        </div>
                        <div className="language-item" onClick={() => window.location.href = '/es'}>
                            <span className="es icon-big"></span>
                            <span>Español</span>
                        </div>
                        <div className="language-item">
                        </div>
                        <div className="language-item" onClick={() => window.location.href = '/tr'}>
                            <span className="tr icon-big"></span>
                            <span>Türkçe</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Language;