import React, { Component, Suspense } from 'react';
import { NavLink } from 'react-router-dom';
const PartnersLazy = React.lazy(() => import('./lazy/partners'));
const AdsLazy = React.lazy(() => import('./lazy/ads'));
const MonitoringLazy = React.lazy(() => import('./lazy/monitoring'));

class Partners extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notification: '',
      showNotice: false
    }
  }

  componentDidMount() {
    let title = 'Партнерская программа | ' + process.env.REACT_APP_NAME;
    let description = 'Присоединяйтесь к Партнерской программе WMSIM и начните зарабатывать, рекомендуя наши услуги новым пользователям.';
    document.title = title;
    document.querySelector("meta[name='description']").setAttribute('content', description);
    document.querySelector("meta[property='og:title']").setAttribute('content', title);
    document.querySelector("meta[property='og:description']").setAttribute('content', description);
    document.querySelector("meta[property='og:url']").setAttribute('content', window.location.href);
    document.querySelector("link[rel='canonical']").setAttribute('href', window.location.href);
  }

  componentDidUpdate() {
    let urlElements = window.location.href.split('/');
    let title = urlElements[3] === "partners#monitoring" ? 'Предложение для мониторингов | ' + process.env.REACT_APP_NAME : urlElements[3] === "partners#ads" ? 'Реклама на сайте | ' + process.env.REACT_APP_NAME : 'Партнерская программа | ' + process.env.REACT_APP_NAME;
    document.title = title;
    document.querySelector("meta[property='og:title']").setAttribute('content', title);
    document.querySelector("meta[property='og:url']").setAttribute('content', window.location.href);
    document.querySelector("link[rel='canonical']").setAttribute('href', window.location.href);
    if (this.state.showNotice) {
      this.timer = setTimeout(() => this.setState({ showNotice: false }), 5000);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  copyText = () => {
    navigator.clipboard.writeText(process.env.REACT_APP_URL + '/rates.xml');
    this.setState({ notification: 'Ссылка на экспортный файл скопирована.', showNotice: true });
  }

  render() {
    let urlElements = window.location.href.split('/');
    var contentTitle;
    if (urlElements[3] === "partners#monitoring") {
      contentTitle = 'Предложение для мониторингов';
    } else if (urlElements[3] === "partners#ads") {
      contentTitle = 'Реклама на сайте';
    } else {
      contentTitle = 'Партнерская программа';
    }
    return (
      <div className="page">
        <div className="page-content">
          <div className={"notice fade" + (this.state.showNotice ? " in" : "")}>{this.state.notification}</div>
          <div className="default">
            <div className="default-column">
              <div className="default-menu">
                <div>
                  <NavLink to="/partners" className="default-nav-item" activeClassName={urlElements[3] === "partners" ? "default-nav-item-active" : null}>Партнерская программа</NavLink>
                  <NavLink to="#monitoring" className="default-nav-item" activeClassName={urlElements[3] === "partners#monitoring" ? "default-nav-item-active" : null}>Предложение для мониторингов</NavLink>
                  <NavLink to="#ads" className="default-nav-item" activeClassName={urlElements[3] === "partners#ads" ? "default-nav-item-active" : null}>Реклама на сайте</NavLink>
                </div>
              </div>
            </div>
            <div className="default-column">
              <div className="default-content">
                <h1>{contentTitle}</h1>
                <div className="icon-referrals icon-main"></div>
              </div>
              <div className="default-submenu" >
                <NavLink to="/partners" className="user-nav-item" activeClassName={urlElements[3] === "partners" ? "user-nav-item-active" : null}>Пользователям</NavLink>
                <NavLink to="#monitoring" className="user-nav-item" activeClassName={urlElements[3] === "partners#monitoring" ? "user-nav-item-active" : null}>Мониторингам</NavLink>
                <NavLink to="#ads" className="user-nav-item" activeClassName={urlElements[3] === "partners#ads" ? "user-nav-item-active" : null}>Реклама</NavLink>
              </div>
              {urlElements[3] === "partners#monitoring" ?
                <Suspense fallback={<div className="data-loading"></div>}>
                  <MonitoringLazy accessToken={this.props.accessToken} verifyToken={this.props.verifyToken} changeModal={() => this.props.changeModal()} copyText={() => this.copyText()} />
                </Suspense>
                : urlElements[3] === "partners#ads" ?
                  <Suspense fallback={<div className="data-loading"></div>}>
                    <AdsLazy />
                  </Suspense>
                  :
                  <Suspense fallback={<div className="data-loading"></div>}>
                    <PartnersLazy accessToken={this.props.accessToken} changeModal={() => this.props.changeModal()} />
                  </Suspense>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Partners;