import React, { Component } from 'react';
import Captcha from './captcha';
import Tooltip from './tooltip';
import { Email, Password } from './components/constants';
import { NavLink } from 'react-router-dom';

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputType: false,
      signupEmail: '',
      signupPassword: '',
      showToolTip: [],
      showDanger: [],
      isOpened: false,
      isOpenedCaptcha: false,
      isChecked: true,
      isSignup: false,
      isSubmitted: {},
      isDisabled: false,
      pattern: [["signupEmail", false], ["signupPassword", false]]
    }
    this.changeToggle = this.changeToggle.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount() {
    this.timer = setTimeout(() => this.setState({ isOpened: this.props.isOpened ? true : false }), 1);
  }

  changeModal = () => {
    this.setState({ isOpenedCaptcha: !this.state.isOpenedCaptcha });
  }

  openToolTip(i) {
    const openStatus = this.state.showToolTip.slice();
    openStatus[i] = true;
    this.setState({ showToolTip: openStatus });
  }

  closeToolTip(i) {
    if (i === undefined) {
      this.setState({ showToolTip: [] });
    } else {
      const closeStatus = this.state.showToolTip.slice();
      closeStatus[i] = false;
      this.setState({ showToolTip: closeStatus });
    }
  }

  openDanger(i) {
    const openStatus = this.state.showDanger;
    openStatus[i] = true;
    this.setState({ showDanger: openStatus });
  }

  closeDanger(i) {
    const closeStatus = this.state.showDanger;
    closeStatus[i] = false;
    this.setState({ showDanger: closeStatus });
  }

  changeInputType = () => {
    this.setState({ inputType: !this.state.inputType });
  }

  changeToggle() {
    this.setState({ isChecked: !this.state.isChecked });
    if (!this.state.isChecked === false) {
      this.openToolTip('agree');
    } else {
      this.closeToolTip('agree');
    }
  }

  clickForm = a => event => {
    this.closeToolTip(event.target.name);
    const pattern = new RegExp(a);
    if (event.target.value !== '' || (event.target.value === '' && this.state.showDanger[event.target.name] === true)) {
      if (!pattern.test(event.target.value)) {
        this.openDanger(event.target.name);
        this.openToolTip(event.target.name);
      } else {
        this.closeDanger(event.target.name);
        this.closeToolTip(event.target.name);
      }
    }
  }

  blurForm = a => event => {
    this.closeToolTip();
    const pattern = new RegExp(a);
    if (!pattern.test(event.target.value)) {
      this.openDanger(event.target.name);
    }
  }

  changeForm = a => event => {
    event.target.value = event.target.value.replace(/\s/g, '');
    if (event.target.name === 'signupEmail') {
      event.target.value = event.target.value.toLowerCase();
    }
    this.setState({ [event.target.name]: event.target.value });
    const pattern = new RegExp(a);
    if (event.target.value !== '') {
      if (pattern.test(event.target.value)) {
        this.closeDanger(event.target.name);
        this.closeToolTip(event.target.name);
      }
    } else {
      this.closeToolTip(event.target.name);
      this.closeDanger(event.target.name);
    }
  }

  submitCaptcha = () => {
    this.setState({ isDisabled: true, isSubmitted: { submitForm: true } });
    fetch('/api/signup', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 'pid': localStorage.getItem('partner'), 'signupEmail': this.state.signupEmail, 'signupPassword': this.state.signupPassword }),
    })
      .then(response => response.json())
      .then((result) => {
        if (!result.login) {
          this.openToolTip('signupEmailCheck');
          this.openDanger('signupEmail');
        } else {
          this.setState({ isSignup: true });
        }
        this.setState({ isDisabled: false, isSubmitted: {} });
      })
  }

  submitForm(e) {
    e.preventDefault();

    if (this.state.signupEmail === '') {
      this.openDanger('signupEmail');
    }
    if (this.state.signupPassword === '') {
      this.openDanger('signupPassword');
    }

    var sortDanger = this.state.pattern.map(pattern => Object.entries(this.state.showDanger).find(item => item[0] === pattern[0])).filter(item => item !== undefined);
    var trueProps = sortDanger.filter(([key, value]) => value === true);

    if (trueProps.length > 0) {
      this.openToolTip(trueProps[0][0]);
    } else if (this.state.isChecked === false) {
      this.openToolTip('agree');
    } else if (!this.state.isOpenedCaptcha) {
      this.setState({ isOpenedCaptcha: true });
    }
  }

  render() {
    return (
      <div className={"modal-overlay" + (this.props.isOpened ? " open" : " close")}>
        {!this.state.isSignup ?
          <form autoComplete="off" onSubmit={this.submitForm}>
            <div className={"modal-content" + (this.state.isOpened ? " open" : "")}>
              <div className="modal-close" onClick={() => this.props.changeModal()}><span className="icon-close icon-small"></span></div>
              <div className="modal-title">Регистрация</div>
              <div className="modal-note ssl-note">Убедитесь, что находитесь на странице<span className="icon-ssl"></span><span className="ssl">https://</span>www.wmsim.com</div>
              <div className="field">
                <input type="text" className={"input" + (this.state.showDanger['signupEmail'] ? " input-danger" : "")} id="signupEmail" name="signupEmail" autoComplete="off" placeholder="E-mail" onClick={this.clickForm(Email)} onBlur={this.blurForm(Email)} onChange={this.changeForm(Email)} />
                <div className="field-icon"><span className="icon-email icon-medium"></span></div>
                <Tooltip status={this.state.showToolTip['signupEmail']} closeToolTip={() => this.closeToolTip('signupEmail')} text="Введите корректный E-mail." />
                <Tooltip status={this.state.showToolTip['signupEmailCheck']} closeToolTip={() => this.closeToolTip('signupEmailCheck')} text="Указанный E-mail уже зарегистрирован." />
              </div>
              <div className="field">
                <input type={this.state.inputType ? "text" : "password"} className={"input" + (this.state.showDanger['signupPassword'] ? " input-danger" : "")} id="signupPassword" name="signupPassword" autoComplete="off" placeholder="Пароль" onClick={this.clickForm(Password)} onBlur={this.blurForm(Password)} onChange={this.changeForm(Password)} />
                <div className="field-icon"><span className={(this.state.inputType ? "icon-show-password" : "icon-hide-password") + " icon-medium"} onClick={() => this.changeInputType()}></span></div>
                <Tooltip status={this.state.showToolTip['signupPassword']} closeToolTip={() => this.closeToolTip('signupPassword')} text="Пароль должен содержать не менее 8 символов и включать в себя цифры, латинские строчные и прописные буквы." />
              </div>
              <div className="modal-note">Пароль должен содержать не менее 8 символов и включать в себя цифры, латинские строчные и прописные буквы.</div>
              <div className="field">
                <label className="checkbox-content signup">
                  <input type="checkbox" className="checkbox" id="signupAgree" name="signupAgree" value="true" checked={this.state.isChecked} onChange={this.changeToggle} onBlur={this.blurForm()} />
                  <div className="checkbox-area" aria-checked={this.state.isChecked ? "true" : "false"}>
                    <span className={"checkbox-toggle" + (this.state.isChecked ? " active" : "")}></span>
                  </div>
                  Я принимаю <NavLink to="/agreement" onClick={() => this.props.changeModal()}>Пользовательское Соглашение</NavLink>
                </label>
                <div className="tooltip-agree">
                  <Tooltip status={this.state.showToolTip['agree']} closeToolTip={() => this.closeToolTip('agree')} text="Вам необходимо ознакомиться и принять Пользовательское Соглашение." />
                </div>
              </div>
              <button className={"button" + (this.state.isSubmitted['submitForm'] ? " button-loading" : "")} disabled={this.state.isDisabled} type="submit" onBlur={this.blurForm()}>Зарегистрироваться</button>
              <div className="modal-note last">У Вас уже есть аккаунт? <span id="signin">Вход</span></div>
            </div>
          </form>
          :
          <div className="modal-content open">
            <div className="modal-close" onClick={() => this.props.changeModal()}><span className="icon-close icon-small"></span></div>
            <div className="modal-title">Регистрация</div>
            <div className="modal-note">Письмо с инструкциями отправлено на Вашу почту.</div>
            <div className="modal-block">
              <div className="modal-column">
                <span className="icon-lock icon-big icon-modal"></span>
              </div>
              <div className="modal-column">
                <span className="icon-mail icon-big icon-modal"></span>
              </div>
              <div className="modal-column">
                <span className="icon-user icon-big icon-modal"></span>
              </div>
            </div>
            <div className="modal-note">Для завершения регистрации перейдите по ссылке в письме.</div>
            <button className="button" onClick={() => this.props.changeModal()}>Закрыть</button>
          </div>
        }
        {this.state.isOpenedCaptcha ? <Captcha isOpened={this.state.isOpenedCaptcha} submitCaptcha={() => this.submitCaptcha(this)} changeModal={() => this.changeModal(this)} /> : null}
      </div>
    )
  }
}

export default Signup;