import React, { Component } from 'react';
import Tooltip from './tooltip';

class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: false,
      incomeAmount: '',
      outcomeAmount: '',
      counter: '',
      sound: '',
      paymentCode: '',
      modalPayment: false,
      isSubmitted: {},
      isDisabled: false,
      showToolTip: [],
      showDanger: [],
      isOpenedDetails: false,
      isOpened: false
    };
    this.openToolTip = this.openToolTip.bind(this);
    this.closeToolTip = this.closeToolTip.bind(this);
    this.openDetails = this.openDetails.bind(this);
    this.payOrder = this.payOrder.bind(this);
    this.confirmOrder = this.confirmOrder.bind(this);
    this.cancelOrder = this.cancelOrder.bind(this);
  }

  loadingData() {
    fetch('/api/order', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 'orderHash': this.props.orderHash }),
    })
      .then(response => response.json())
      .then((result) => {
        this.setState({ incomeAmount: result.incomeAmount, outcomeAmount: result.outcomeAmount, counter: result.counter, modalPayment: false, status: false, isOpenedDetails: false, paymentCode: '', showToolTip: [], showDanger: [], isOpened: true });
      })
  }

  loadingCheck() {
    if (this.state.modalPayment && this.state.status < 4) {
      fetch('/api/order-check', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 'orderHash': this.props.orderHash, 'orderEmail': this.props.orderEmail, 'incomeClass': this.props.incomeClass, 'outcomeClass': this.props.outcomeClass, 'incomeAmount': this.state.incomeAmount, 'outcomeAmount': this.state.outcomeAmount, 'incomeAccount': this.props.incomeAccount, 'outcomeAccount': this.props.outcomeAccount, 'incomeAmountCurrency': this.props.incomeAmountCurrency, 'outcomeAmountCurrency': this.props.outcomeAmountCurrency, 'incomeDirection': this.props.incomeDirection, 'outcomeDirection': this.props.outcomeDirection }),
      })
        .then(response => response.json())
        .then((result) => {
          if (result.status > 1) {
            this.setState({ status: +result.status });
          }
        })
    }
  }

  loadingPayment() {
    fetch('/payway/' + this.props.incomeLetterCode.toLowerCase() + '.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 'orderHash': this.props.orderHash }),
    })
      .then(response => response.json())
      .then((result) => {
        document.getElementById('modal-payment').innerHTML = result.paymentData;
      })
  }

  componentDidMount() {
    this.timer = setInterval(() => {
      this.setState((prevState) => ({ counter: prevState.counter > 0 ? prevState.counter - 1 : 0, sound: prevState.counter - 1 }));
    }, 1000);
    this.timer2 = setInterval(() => {
      this.loadingCheck();
    }, 10000);
  }

  componentDidUpdate(prevProps, prevState) {
    document.getElementById('payment-data') && document.getElementById('payment-data').addEventListener('click', () => navigator.clipboard.writeText(document.getElementById('payment-data').textContent));
    if (prevProps.isOpened !== this.props.isOpened) {
      this.setState({ isOpened: false });
      if (this.props.isOpened) {
        this.loadingData();
      }
      if (this.props.isOpened && this.props.manualConfirm) {
        this.loadingPayment();
      }
    }
    if (this.props.isOpened && this.state.sound === 0 && !this.state.status) {
      this.cancelOrder();
      document.getElementById("end").play();
    } else if (this.props.isOpened && this.state.status && this.state.status === 5) {
      if (prevState.status !== this.state.status) {
        document.getElementById("end").play();
      }
    } else if (this.props.isOpened && this.state.status && this.state.status < 5) {
      if (prevState.status !== this.state.status) {
        document.getElementById("success").play();
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    clearInterval(this.timer2);
  }

  openDetails() {
    this.setState({ isOpenedDetails: !this.state.isOpenedDetails });
  }

  payOrder() {
    this.setState({ isDisabled: true, isSubmitted: { submitForm: true }, modalPayment: true });
    window.open('/payway/' + this.props.incomeLetterCode.toLowerCase() + '.php?' + this.props.orderHash, '_blank');
    this.setState({ isDisabled: false, isSubmitted: {} });
  }

  confirmOrder() {
    this.setState({ isDisabled: true, isSubmitted: { submitForm: true } });
    if ((this.state.paymentCode === '' && this.props.incomeDirection === 'Western Union') || (this.state.paymentCode === '' && this.props.incomeDirection === 'KoronaPay')) {
      this.openDanger('paymentCode');
    } else {
      fetch('/api/order-confirm', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 'orderHash': this.props.orderHash, 'paymentCode': this.state.paymentCode, 'orderEmail': this.props.orderEmail, 'incomeClass': this.props.incomeClass, 'outcomeClass': this.props.outcomeClass, 'incomeAmount': this.state.incomeAmount, 'outcomeAmount': this.state.outcomeAmount, 'incomeAccount': this.props.incomeAccount, 'outcomeAccount': this.props.outcomeAccount, 'incomeAmountCurrency': this.props.incomeAmountCurrency, 'outcomeAmountCurrency': this.props.outcomeAmountCurrency, 'incomeDirection': this.props.incomeDirection, 'outcomeDirection': this.props.outcomeDirection }),
      })
        .then(response => response.json())
        .then((result) => {
          if (result.status) {
            this.setState({ modalPayment: true });
            this.setState({ status: 2 });
          }
        })
    }
    this.setState({ isDisabled: false, isSubmitted: {} });
  }

  cancelOrder() {
    fetch('/api/order-cancel', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 'orderHash': this.props.orderHash }),
    })
      .then(response => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ status: 5 });
        }
      })
  }

  openToolTip(i) {
    const openStatus = this.state.showToolTip.slice();
    openStatus[i] = true;
    this.setState({ showToolTip: openStatus });
  }

  closeToolTip(i) {
    if (i === undefined) {
      this.setState({ showToolTip: [] });
    } else {
      const closeStatus = this.state.showToolTip.slice();
      closeStatus[i] = false;
      this.setState({ showToolTip: closeStatus });
    }
  }

  openDanger(i) {
    const openStatus = this.state.showDanger;
    openStatus[i] = true;
    this.setState({ showDanger: openStatus });
  }

  closeDanger(i) {
    const closeStatus = this.state.showDanger;
    closeStatus[i] = false;
    this.setState({ showDanger: closeStatus });
  }

  blurForm = a => event => {
    this.closeToolTip();
    const pattern = new RegExp(a);
    if (!pattern.test(event.target.value)) {
      this.openDanger(event.target.name);
    }
  }

  clickForm = a => event => {
    this.closeToolTip(event.target.name);
    const pattern = new RegExp(a);
    if (event.target.value !== '' || (event.target.value === '' && this.state.showDanger[event.target.name] === true)) {
      if (!pattern.test(event.target.value)) {
        this.openDanger(event.target.name);
        this.openToolTip(event.target.name);
      } else {
        this.closeDanger(event.target.name);
        this.closeToolTip(event.target.name);
      }
    }
  }

  changeForm = (a, b) => event => {
    event.target.value = event.target.value.replace(/\s/g, '');
    if (b === 123) {
      event.target.value = event.target.value.replace(/[^\d.]*/g, '');
    }
    this.setState({ [event.target.name]: event.target.value });
    const pattern = new RegExp(a);
    if (event.target.value !== '') {
      if (pattern.test(event.target.value)) {
        this.closeDanger(event.target.name);
        this.closeToolTip(event.target.name);
      }
    } else {
      this.closeToolTip(event.target.name);
      this.closeDanger(event.target.name);
    }
  }

  render() {
    const padTime = time => {
      return String(time).length === 1 ? `0${time}` : `${time}`;
    };

    const format = time => {
      const minutes = Math.floor(time / 60);
      const seconds = time % 60;
      return `${minutes} мин ${padTime(seconds)} сек`;
    };

    const Code = this.props.incomeDirection === 'Western Union' ? '^[0-9]{10}$' : this.props.incomeDirection === 'KoronaPay' ? '^[0-9]{9}$' : '';
    const maxLength = this.props.incomeDirection === 'Western Union' ? 10 : this.props.incomeDirection === 'KoronaPay' ? 9 : '';

    return (
      this.props.isOpened && (
        <div className={"modal-overlay" + (this.props.isOpened ? " open" : " close")}>
          <div className={"modal-content-order" + (this.state.isOpened ? " open" : "")}>
            {!this.state.modalPayment || (this.state.modalPayment && this.state.status) || (this.state.modalPayment && this.state.counter === 0) ?
              <div className='modal-close' onClick={!this.state.status ? () => { this.cancelOrder(); this.props.changeModal() } : () => this.props.changeModal()}><span className="icon-close icon-small"></span></div>
              : null}
            <div className="modal-title">{this.state.status ? this.state.status < 5 ? this.props.manualConfirm ? 'Подтверждение принято' : 'Оплата поступила' : 'Оплата не поступила' : this.state.counter === 0 ? 'Оплата не поступила' : this.state.modalPayment ? 'Ожидание оплаты' : 'Переход к оплате'}</div>
            <div className="modal-note">{this.state.status ? this.state.status === 2 ? 'Заявка проверяется' : this.state.status === 3 ? 'Заявка обрабатывается' : this.state.status === 4 ? 'Заявка завершена' : 'Заявка отменена' : this.state.counter === 0 ? 'Заявка отменена' : 'Заявка действительна ' + format(this.state.counter)}</div>
            <div className="modal-block-order">
              <div className="modal-table">
                <div className="modal-column">
                  <div>
                    <span className={this.props.incomeClass + ' icon-medium icon-modal'}></span>
                    <span className="modal-amount">{this.state.incomeAmount} {this.props.incomeAmountCurrency}</span>
                  </div>
                  <div className="modal-system">{this.props.incomeDirection}</div>
                </div>
                <div className="modal-column">
                  <span className={(this.state.status ? this.state.status === 2 ? "icon-check" : this.state.status === 3 ? "icon-process" : this.state.status === 4 ? "icon-success" : "icon-cancel" : this.state.counter === 0 ? "icon-cancel" : "icon-timer") + " icon-big"}></span>
                </div>
                <div className="modal-column">
                  <div>
                    <span className={this.props.outcomeClass + ' icon-medium icon-modal'}></span>
                    <span className="modal-amount">{this.state.outcomeAmount} {this.props.outcomeAmountCurrency}</span>
                  </div>
                  <div className="modal-system">{this.props.outcomeDirection}</div>
                </div>
              </div>
              <div className="modal-block-details">
                <div className="modal-more" onClick={this.openDetails}>{!this.state.isOpenedDetails ? 'Показать детали заявки' : 'Скрыть детали заявки'}</div>
                <div className={"modal-details" + (this.state.isOpenedDetails ? " open" : " close")}>
                  <div className="modal-details-item">
                    <div className="modal-details-title">{this.props.incomeAccountLabel}</div>
                    <div className="modal-details-data">{this.props.incomeAccount}</div>
                  </div>
                  {this.props.incomeFnameLabel !== '' && this.props.incomeFname !== '' ?
                    <div className="modal-details-item">
                      <div className="modal-details-title">{this.props.incomeFnameLabel}</div>
                      <div className="modal-details-data">{this.props.incomeFname}</div>
                    </div>
                    :
                    null
                  }
                  {this.props.incomeInameLabel !== '' && this.props.incomeIname !== '' ?
                    <div className="modal-details-item">
                      <div className="modal-details-title">{this.props.incomeInameLabel}</div>
                      <div className="modal-details-data">{this.props.incomeIname}</div>
                    </div>
                    :
                    null
                  }
                  {this.props.incomeOnameLabel !== '' && this.props.incomeOname !== '' ?
                    <div className="modal-details-item">
                      <div className="modal-details-title">{this.props.incomeOnameLabel}</div>
                      <div className="modal-details-data">{this.props.incomeOname}</div>
                    </div>
                    :
                    null
                  }
                  <div className="modal-details-item">
                    <div className="modal-details-title">{this.props.outcomeAccountLabel}</div>
                    <div className="modal-details-data">{this.props.outcomeAccount}</div>
                  </div>
                  {this.props.outcomeFnameLabel !== '' && this.props.outcomeFname !== '' ?
                    <div className="modal-details-item">
                      <div className="modal-details-title">{this.props.outcomeFnameLabel}</div>
                      <div className="modal-details-data">{this.props.outcomeFname}</div>
                    </div>
                    :
                    null
                  }
                  {this.props.outcomeInameLabel !== '' && this.props.outcomeIname !== '' ?
                    <div className="modal-details-item">
                      <div className="modal-details-title">{this.props.outcomeInameLabel}</div>
                      <div className="modal-details-data">{this.props.outcomeIname}</div>
                    </div>
                    :
                    null
                  }
                  {this.props.outcomeOnameLabel !== '' && this.props.outcomeOname !== '' ?
                    <div className="modal-details-item">
                      <div className="modal-details-title">{this.props.outcomeOnameLabel}</div>
                      <div className="modal-details-data">{this.props.outcomeOname}</div>
                    </div>
                    :
                    null
                  }
                </div>
                {this.props.manualConfirm && !this.state.status ?
                  <div id="modal-payment" className="modal-payment">
                    <div className="data-loading"></div>
                  </div>
                  :
                  null
                }
              </div>
            </div>

            {this.props.incomeDirection === 'Western Union' && !this.state.status ?
              <div className="field" style={{ marginTop: '0.8rem' }}>
                <input type="text" className={"input" + (this.state.showDanger['paymentCode'] ? " input-danger" : "")} id="paymentCode" name="paymentCode" placeholder="Номер перевода MTCN, 10 цифр" autoComplete="off" maxLength={maxLength} onBlur={this.blurForm(Code)} onClick={this.clickForm(Code)} onChange={this.changeForm(Code, '123')} />
                <Tooltip status={this.state.showToolTip['paymentCode']} closeToolTip={() => this.closeToolTip('paymentCode')} text="Введите корректный MTCN, 10 цифр." />
              </div>
              : null}

            {this.props.incomeDirection === 'KoronaPay' && !this.state.status ?
              <div className="field" style={{ marginTop: '0.8rem' }}>
                <input type="text" className={"input" + (this.state.showDanger['paymentCode'] ? " input-danger" : "")} id="paymentCode" name="paymentCode" placeholder="Номер перевода, 9 цифр" autoComplete="off" maxLength={maxLength} onBlur={this.blurForm(Code)} onClick={this.clickForm(Code)} onChange={this.changeForm(Code, '123')} />
                <Tooltip status={this.state.showToolTip['paymentCode']} closeToolTip={() => this.closeToolTip('paymentCode')} text="Введите корректный номер перевода, 9 цифр." />
              </div>
              : null}

            <div className="modal-note">{this.state.status ? this.state.status === 2 ? <div>Ваша заявка находится на проверке.<br />Вы можете отслеживать статус заявки в Личном кабинете.</div> : this.state.status === 3 ? <div>Ваша заявка в обработке и скоро завершится.<br />Вы можете отслеживать статус заявки в Личном кабинете.</div> : this.state.status === 4 ? <div>Ваша заявка успешно завершена.<br />Поделитесь Вашим мнением - это поможет нам стать еще лучше!</div> : <div>Ваша заявка была отменена.<br />Вам необходимо создать новую заявку.</div> : this.state.counter === 0 ? <div>Вы не успели совершить оплату до окончания таймера.<br />Вам необходимо создать новую заявку.</div> : this.state.modalPayment ? <div>После оплаты Вы будете перенаправлены в Личный кабинет.<br />Вам необходимо совершить оплату до окончания таймера.</div> : <div>Внимательно проверьте указанные данные перед оплатой.<br />Вам необходимо совершить оплату до окончания таймера.</div>}</div>
            {this.state.status ? this.state.status < 4 ? <a href="/profile/orders" className="button button-modal">Мои заявки</a> : this.state.status === 4 ? <a href="https://www.trustpilot.com/review/www.wmsim.com" target="_blank" rel="nofollow noopener noreferrer" className="button button-modal">Оставить отзыв</a> : <button className="button button-modal" onClick={() => this.props.changeModal()}>Закрыть</button> : this.state.counter === 0 ? <button className="button button-modal" onClick={() => this.props.changeModal()}>Закрыть</button> : !this.state.modalPayment ?
              this.props.manualConfirm ?
                <div>
                  <button className={"button button-modal" + (this.state.isSubmitted['submitForm'] ? " button-loading" : "")} disabled={this.state.isDisabled} onClick={this.confirmOrder}>Я оплатил</button>
                  <div className="modal-note last"><span id="cancel" onClick={() => { this.cancelOrder(); this.props.changeModal() }}>Отменить</span></div>
                </div>
                :
                <div>
                  <button className={"button button-modal" + (this.state.isSubmitted['submitForm'] ? " button-loading" : "")} disabled={this.state.isDisabled} onClick={this.payOrder}>Оплатить</button>
                  <div className="modal-note last"><span id="cancel" onClick={() => { this.cancelOrder(); this.props.changeModal() }}>Отменить</span></div>
                </div>
              : null}
            <audio id="end" preload="auto" src="/sound/cancel.wav"></audio>
            <audio id="success" preload="auto" src="/sound/success.mp3"></audio>
          </div>
        </div >
      )
    )
  }
}

export default Order;