import React, { Component } from 'react';
import Tooltip from './tooltip';
import { Email, Password, AuthPassword } from './components/constants';

class Signin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputType: false,
      signinEmail: '',
      signinPassword: '',
      signinAuthCode: '',
      showToolTip: [],
      showDanger: [],
      isAuth: false,
      isSubmitted: {},
      isDisabled: false,
      isOpened: false,
      patternLogin: [["signinEmail", false], ["signinPassword", false]]
    }
    this.autoSubmitForm = React.createRef();
    this.submitForm = this.submitForm.bind(this);
    this.submitAuthForm = this.submitAuthForm.bind(this);
  }

  componentDidMount() {
    this.timer = setTimeout(() => this.setState({ isOpened: this.props.isOpened ? true : false }), 1);
  }

  componentDidUpdate() {
    if (this.state.signinAuthCode.length === 6) {
      this.autoSubmitForm.current.click();
    }
  }

  openToolTip(i) {
    const openStatus = this.state.showToolTip.slice();
    openStatus[i] = true;
    this.setState({ showToolTip: openStatus });
  }

  closeToolTip(i) {
    if (i === undefined) {
      this.setState({ showToolTip: [] });
    } else {
      const closeStatus = this.state.showToolTip.slice();
      closeStatus[i] = false;
      this.setState({ showToolTip: closeStatus });
    }
  }

  openDanger(i) {
    const openStatus = this.state.showDanger;
    openStatus[i] = true;
    this.setState({ showDanger: openStatus });
  }

  closeDanger(i) {
    const closeStatus = this.state.showDanger;
    closeStatus[i] = false;
    this.setState({ showDanger: closeStatus });
  }

  changeInputType = () => {
    this.setState({ inputType: !this.state.inputType });
  }

  clickForm = a => event => {
    this.closeToolTip(event.target.name);
    const pattern = new RegExp(a);
    if (event.target.value !== '' || (event.target.value === '' && this.state.showDanger[event.target.name] === true)) {
      if (!pattern.test(event.target.value)) {
        this.openDanger(event.target.name);
        this.openToolTip(event.target.name);
      } else {
        this.closeDanger(event.target.name);
        this.closeToolTip(event.target.name);
      }
    }
  }

  blurForm = a => event => {
    this.closeToolTip();
    const pattern = new RegExp(a);
    if (!pattern.test(event.target.value)) {
      this.openDanger(event.target.name);
    }
  }

  changeForm = a => event => {
    event.target.value = event.target.value.replace(/\s/g, '');
    if (event.target.name === 'signinAuthCode') {
      event.target.value = event.target.value.replace(/[^\d]*/g, '');
    }
    if (event.target.name === 'signinEmail') {
      event.target.value = event.target.value.toLowerCase();
    }
    this.setState({ [event.target.name]: event.target.value })
    const pattern = new RegExp(a);
    if (event.target.value !== '') {
      if (pattern.test(event.target.value)) {
        this.closeDanger(event.target.name);
        this.closeToolTip(event.target.name);
      }
    } else {
      this.closeToolTip(event.target.name);
      this.closeDanger(event.target.name);
    }
  }

  submitForm(e) {
    e.preventDefault();

    if (this.state.signinEmail === '') {
      this.openDanger('signinEmail');
    }
    if (this.state.signinPassword === '') {
      this.openDanger('signinPassword');
    }

    var sortDanger = this.state.patternLogin.map(pattern => Object.entries(this.state.showDanger).find(item => item[0] === pattern[0])).filter(item => item !== undefined);
    var trueProps = sortDanger.filter(([key, value]) => value === true);

    if (trueProps.length > 0) {
      this.openToolTip(trueProps[0][0]);
    } else {
      this.setState({ isDisabled: true, isSubmitted: { submitForm: true } });
       fetch('/api/signin', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 'signinEmail': this.state.signinEmail, 'signinPassword': this.state.signinPassword }),
      })
        .then(response => response.json())
        .then((result) => {
          if (!result.login) {
            this.openToolTip('signinEmailCheck');
            this.openDanger('signinEmail');
          } else if (!result.password) {
            this.openToolTip('signinPassword');
            this.openDanger('signinPassword');
          } else if (result.auth) {
            this.setState({ isAuth: true });
          } else if (result.login && result.password && !result.auth) {
            localStorage.setItem('token', result.token);
            this.props.changeModal();
            let urlElements = window.location.href.split('/');
            if (urlElements[3] === 'bonus#diamonds' || urlElements[3] === 'bonus#rewards') {
              window.location.reload();
            } else if (urlElements[3] === 'partners' || urlElements[3] === 'partners#monitoring') {
              window.location.href = '/profile/referrals';
            } else {
              window.location.href = '/profile/orders';
            }
          }
          this.setState({ isDisabled: false, isSubmitted: {} });
        })
    }
  }

  submitAuthForm(e) {
    e.preventDefault();

    if (this.state.signinAuthCode === '') {
      this.openToolTip('signinAuthCode');
      this.openDanger('signinAuthCode');
    } else {
      this.setState({ isDisabled: true, isSubmitted: { submitAuthForm: true } });
      fetch('/api/signinauth', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 'signinEmail': this.state.signinEmail, 'signinPassword': this.state.signinPassword, 'signinAuthCode': this.state.signinAuthCode }),
      })
        .then(response => response.json())
        .then((result) => {
          if (!result.authcode) {
            this.openToolTip('signinCheckAuthCode');
            this.openDanger('signinAuthCode');
            this.setState({ signinAuthCode: '' });
          } else if (result.authcode) {
            localStorage.setItem('token', result.token);
            this.props.changeModal();
            let urlElements = window.location.href.split('/');
            if (urlElements[3] === 'bonus#diamonds' || urlElements[3] === 'bonus#rewards') {
              window.location.reload();
            } else if (urlElements[3] === 'partners' || urlElements[3] === 'partners#monitoring') {
              window.location.href = '/profile/referrals';
            } else {
              window.location.href = '/profile/orders';
            }
          }
          this.setState({ isDisabled: false, isSubmitted: {} });
        })
    }
  }

  render() {
    return (
      <div className={"modal-overlay" + (this.props.isOpened ? " open" : " close")}>
        {!this.state.isAuth ?
          <form autoComplete="off" onSubmit={this.submitForm}>
          <div className={"modal-content" + (this.state.isOpened ? " open" : "")}>
              <div className="modal-close" onClick={() => { this.props.changeModal(); }}><span className="icon-close icon-small"></span></div>
              <div className="modal-title">Вход</div>
              <div className="modal-note ssl-note">Убедитесь, что находитесь на странице<span className="icon-ssl"></span><span className="ssl">https://</span>www.wmsim.com</div>
              <div className="field">
                <input type="text" className={"input" + (this.state.showDanger['signinEmail'] ? " input-danger" : "")} id="signinEmail" name="signinEmail" autoComplete="off" placeholder="E-mail" onClick={this.clickForm(Email)} onBlur={this.blurForm(Email)} onChange={this.changeForm(Email)} />
                <div className="field-icon"><span className="icon-email icon-medium"></span></div>
                <Tooltip status={this.state.showToolTip['signinEmail']} closeToolTip={() => this.closeToolTip('signinEmail')} text="Введите корректный E-mail." />
                <Tooltip status={this.state.showToolTip['signinEmailCheck']} closeToolTip={() => this.closeToolTip('signinEmailCheck')} text="Указанный E-mail не зарегистрирован." />
              </div>
              <div className="field">
                <input type={this.state.inputType ? "text" : "password"} className={"input" + (this.state.showDanger['signinPassword'] ? " input-danger" : "")} id="signinPassword" name="signinPassword" autoComplete="off" placeholder="Пароль" onClick={this.clickForm(Password)} onBlur={this.blurForm(Password)} onChange={this.changeForm(Password)} />
                <div className="field-icon"><span className={(this.state.inputType ? "icon-show-password" : "icon-hide-password") + " icon-medium"} onClick={() => this.changeInputType()}></span></div>
                <Tooltip status={this.state.showToolTip['signinPassword']} closeToolTip={() => this.closeToolTip('signinPassword')} text="Введите корректный пароль." />
              </div>
              <button className={"button" + (this.state.isSubmitted['submitForm'] ? " button-loading" : "")} disabled={this.state.isDisabled} type="submit" onBlur={this.blurForm()}>Войти</button>
              <div className="modal-note last">У Вас еще нет аккаунта? <span id="signup">Регистрация</span> <span id="recovery">Забыли пароль</span></div>
            </div>
          </form>
          :
          <form autoComplete="off" onSubmit={this.submitAuthForm}>
            <div className="modal-content open">
              <div className="modal-close" onClick={() => { this.props.changeModal(); }}><span className="icon-close icon-small"></span></div>
              <div className="modal-title">Google Authenticator</div>
              <div className="modal-note ssl-note">Убедитесь, что находитесь на странице<span className="icon-ssl"></span><span className="ssl">https://</span>www.wmsim.com</div>
              <div className="field">
                <input type="hidden" id="login" name="login" value={this.state.signinEmail} />
                <input type="hidden" id="password" name="password" value={this.state.signinPassword} />
                <input type="text" autoFocus="autofocus" inputMode="numeric" className={"input" + (this.state.showDanger['signinAuthCode'] ? " input-danger" : "")} id="signinAuthCode" name="signinAuthCode" autoComplete="off" maxLength="6" placeholder="Код подтверждения, 6 цифр" onClick={this.clickForm(AuthPassword)} onBlur={this.blurForm(AuthPassword)} onChange={this.changeForm(AuthPassword)} />
                <div className="field-icon"><span className="icon-numeric icon-medium"></span></div>
                <Tooltip status={this.state.showToolTip['signinAuthCode']} closeToolTip={() => this.closeToolTip('signinAuthCode')} text="Код подтверждения Google Authenticator должен содержать 6 цифр." />
                <Tooltip status={this.state.showToolTip['signinCheckAuthCode']} closeToolTip={() => this.closeToolTip('signinCheckAuthCode')} text="Введите корректный код подтверждения Google Authenticator." />
              </div>
              <button ref={this.autoSubmitForm} className={"button" + (this.state.isSubmitted['submitAuthForm'] ? " button-loading" : "")} disabled={this.state.isDisabled} type="submit" onBlur={this.blurForm()}>Войти</button>
              <div className="modal-note last">Вход в Ваш личный кабинет дополнительно защищен кодом подтверждения Google Authenticator.</div>
            </div>
          </form>
        }
      </div>
    )
  }
}

export default Signin;