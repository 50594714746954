import React, { Component } from 'react';
import Tooltip from '../tooltip';
import Order from '../order';
import { Email, Promo, Amount } from '../components/constants';
import { NavLink } from 'react-router-dom';

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dataCountries: [],
      lang: 'ru',
      incomePaymentSystemId: '',
      outcomePaymentSystemId: '',
      incomePaymentSystemName: '',
      outcomePaymentSystemName: '',
      isDirectLink: false,
      directionRate: '',
      directionType: '',
      incomeMinAmount: '',
      incomeMaxAmount: '',
      incomeAmount: 0,
      outcomeAmount: 0,
      incomeAmountUser: '',
      outcomeAmountUser: '',
      incomeAmountCurrency: '',
      outcomeAmountCurrency: '',
      incomeListCurrency: '',
      outcomeListCurrency: '',
      incomeCrypto: false,
      outcomeCrypto: false,
      outcomeReserve: '',
      isChecked: true,
      isCheckedPromo: false,
      promoDiscount: 1,
      userDiscount: 1,
      isOpened: false,
      isIncomeOpened: false,
      isOutcomeOpened: false,
      isDisabled: false,
      isUpdating: false,
      isSubmitted: {},
      showToolTip: [],
      showDanger: [],
      orderHash: '',
      exchangeEmail: '',
      promoCode: '',
      incomeAccount: '',
      outcomeAccount: '',
      incomeFname: '',
      incomeIname: '',
      incomeOname: '',
      outcomeFname: '',
      outcomeIname: '',
      outcomeOname: '',
      checkIncomeAccount: false,
      checkOutcomeAccount: false,
      isValidIncomeAccount: true,
      isValidOutcomeAccount: true,
      incomeAccountLabel: '',
      outcomeAccountLabel: '',
      incomeFnameLabel: '',
      incomeInameLabel: '',
      incomeOnameLabel: '',
      outcomeFnameLabel: '',
      outcomeInameLabel: '',
      outcomeOnameLabel: '',
      notification: '',
      isOpenedCountry: false,
      incomeCountry: false,
      outcomeCountry: false,
      pattern: [["incomeAmount", false], ["outcomeAmount", false], ["incomeAccount", false], ["incomeFname", false], ["incomeIname", false], ["outcomeAccount", false], ["outcomeFname", false], ["outcomeIname", false], ["exchangeEmail", false], ["promoCode", false]]
    };
    this.openToolTip = this.openToolTip.bind(this);
    this.closeToolTip = this.closeToolTip.bind(this);
    this.changeAmount = this.changeAmount.bind(this);
    this.changeToggle = this.changeToggle.bind(this);
    this.changeTogglePromo = this.changeTogglePromo.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.selectCountry = this.selectCountry.bind(this);
    this.incomeAccountSection = React.createRef();
    this.outcomeAccountSection = React.createRef();
    this.countrySection = React.createRef();
    this.showCountry = this.showCountry.bind(this);
    this.closeCountry = this.closeCountry.bind(this);
  }

  referralFollow = () => {
    const query = new URLSearchParams(window.location.search);
    const pid = query.get('pid');
    if (pid !== null && pid !== '') {
      localStorage.setItem('partner', pid);
      fetch('/api/referral-follow', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 'pid': localStorage.getItem('partner') }),
      })
    }
  }

  loadingData() {
    this.setState({ isDisabled: true, isUpdating: true });
    const query = new URLSearchParams(window.location.search);
    const cur_from = query.get('cur_from');
    const cur_to = query.get('cur_to');
    fetch('/api/global.json?nocache=' + (new Date()).getTime())
      .then(response => response.json())
      .then(result => {
        const curFrom = result.data.map(item => item.paymentSystems.filter(item => item.attributes.income_enabled === true).find(item2 => item2.attributes.letter_code === cur_from) ? item.paymentSystems.find(item2 => item2.attributes.letter_code === cur_from).id : null)[0];
        const curTo = result.data.map(item => item.paymentSystems.filter(item => item.attributes.outcome_enabled === true).find(item2 => item2.attributes.letter_code === cur_to) ? item.paymentSystems.find(item2 => item2.attributes.letter_code === cur_to).id : null)[0];
        const checkCur = curFrom && curTo ? result.data.map(item => item.direction_rates[curFrom][curTo] ? true : false)[0] : false;
        const incomePaymentSystemId = this.state.incomePaymentSystemId === '' ? checkCur ? curFrom : result.data.map(item => item.initialDirection.incomePaymentSystemId)[0] : this.state.incomePaymentSystemId;
        const outcomePaymentSystemId = this.state.outcomePaymentSystemId === '' ? checkCur ? curTo : result.data.map(item => item.initialDirection.outcomePaymentSystemId)[0] : this.state.outcomePaymentSystemId;
        const directionRate = result.data.map(item => item.direction_rates[incomePaymentSystemId][outcomePaymentSystemId].v)[0];
        const incomeCrypto = this.state.incomePaymentSystemId === '' ? result.data.map(item => item.paymentSystems.find(item2 => item2.id === incomePaymentSystemId).attributes.currency.is_crypto)[0] : this.state.incomeCrypto;
        const outcomeCrypto = this.state.outcomePaymentSystemId === '' ? result.data.map(item => item.paymentSystems.find(item2 => item2.id === outcomePaymentSystemId).attributes.currency.is_crypto)[0] : this.state.outcomeCrypto;
        const siteBreak = result.data.map(item => item.site_technical_break)[0] === 1 ? true : false;
        const incomeMinAmount = this.state.incomeMinAmount === '' ? result.data.map(item => item.direction_rates[incomePaymentSystemId][outcomePaymentSystemId].min_in)[0] : this.state.incomeMinAmount;
        const incomeMaxAmount = this.state.incomeMaxAmount === '' ? result.data.map(item => item.direction_rates[incomePaymentSystemId][outcomePaymentSystemId].max_in)[0] : this.state.incomeMaxAmount;
        const directionType = this.state.directionType === '' ? result.data.map(item => item.direction_rates[incomePaymentSystemId][outcomePaymentSystemId].type)[0] === 'auto' ? 'автомат' : 'ручной' : this.state.directionType;
        const outcomeReserve = result.data.map(item => item.reserves[outcomePaymentSystemId])[0];
        const incomeAmount = +(this.state.incomeAmount <= 0 ? result.data.map(item => item.direction_rates[incomePaymentSystemId][outcomePaymentSystemId].min_in)[0] : this.state.incomeAmount);
        const outcomeAmount = +(this.state.outcomeAmount <= 0 ? (result.data.map(item => item.direction_rates[incomePaymentSystemId][outcomePaymentSystemId].min_in)[0] * directionRate * this.state.promoDiscount * this.state.userDiscount).toFixed(outcomeCrypto ? 8 : 2) : this.state.outcomeAmountUser !== '' ? this.state.outcomeAmount : (this.state.incomeAmount * directionRate * this.state.promoDiscount * this.state.userDiscount).toFixed(outcomeCrypto ? 8 : 2));
        this.checkAmount(incomeAmount, incomeMinAmount, incomeMaxAmount, outcomeAmount, outcomeReserve, this.state.incomeAmount <= 0 ? 'incomeAmount' : 'outcomeAmount');
        localStorage.setItem('site-break', siteBreak);
        this.setState({
          data: result.data,
          incomePaymentSystemId: incomePaymentSystemId,
          outcomePaymentSystemId: outcomePaymentSystemId,
          incomePaymentSystemName: this.state.incomePaymentSystemName === '' ? result.data.map(item => item.paymentSystems.find(item => item.id === incomePaymentSystemId).attributes.name)[0] : this.state.incomePaymentSystemName,
          outcomePaymentSystemName: this.state.outcomePaymentSystemName === '' ? result.data.map(item => item.paymentSystems.find(item => item.id === outcomePaymentSystemId).attributes.name)[0] : this.state.outcomePaymentSystemName,
          isDirectLink: checkCur,
          directionRate: directionRate,
          incomeMinAmount: incomeMinAmount,
          incomeMaxAmount: incomeMaxAmount,
          directionType: directionType,
          outcomeReserve: outcomeReserve,
          incomeAmount: incomeAmount,
          outcomeAmount: outcomeAmount,
          incomeAmountCurrency: this.state.incomePaymentSystemId === '' ? result.data.map(item => item.paymentSystems.find(item2 => item2.id === incomePaymentSystemId).attributes.currency_iso_code)[0] : this.state.incomeAmountCurrency,
          outcomeAmountCurrency: this.state.outcomePaymentSystemId === '' ? result.data.map(item => item.paymentSystems.find(item2 => item2.id === outcomePaymentSystemId).attributes.currency_iso_code)[0] : this.state.outcomeAmountCurrency,
          incomeCrypto: incomeCrypto,
          outcomeCrypto: outcomeCrypto,
          siteBreak: siteBreak,
          isDisabled: false,
          isUpdating: false
        })
      });
  }

  updateData() {
    this.setState({ outcomeAmount: +((this.state.incomeAmount * this.state.directionRate * this.state.promoDiscount * this.state.userDiscount).toFixed(this.state.outcomeCrypto ? 8 : 2)) })
  }

  loadingUser() {
    fetch('/api/profile/settings', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.accessToken,
      },
      body: JSON.stringify({ 'loading': true }),
    })
      .then(response => response.json())
      .then(result => {
        this.setState({ exchangeEmail: result.data.map(item => item.settings.map(item => item.login)).toString() });
      }, (error) => {
        if (error) {
          localStorage.removeItem('token');
        }
      });
  }

  loadingDiscount() {
    fetch('/api/profile/discount', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.accessToken,
      },
      body: JSON.stringify({ 'loading': true }),
    })
      .then(response => response.json())
      .then((result) => {
        this.setState({ userDiscount: result.data.map(item => item.level.map(item => item.discount_current > 1 ? 1 + (item.discount_current / 100) : 1)) });
      }, (error) => {
        if (error) {
          localStorage.removeItem('token');
        }
      });
  }

  loadingCountries() {
    fetch('/api/countries.json')
      .then(response => response.json())
      .then(result => {
        this.setState({
          dataCountries: result.data
        });
      })
  }

  componentDidMount() {
    this.referralFollow();
    if (this.props.accessToken) {
      this.loadingUser();
      this.loadingDiscount();
    }
    this.loadingData();
    this.loadingCountries();
    this.timer = setInterval(() => this.loadingData(), 15000);
    document.addEventListener('mousedown', this.closeCountry);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isOpened !== this.state.isOpened || prevState.isIncomeOpened !== this.state.isIncomeOpened || prevState.isOutcomeOpened !== this.state.isOutcomeOpened) {
      if (this.state.isOpened || this.state.isIncomeOpened || this.state.isOutcomeOpened) {
        document.body.style.overflow = "hidden";
      } else if (!this.state.isOpened || !this.state.isIncomeOpened || !this.state.isOutcomeOpened) {
        document.body.removeAttribute("style");
      }
    }
    if (this.state.isDirectLink) {
      let title = 'Обмен ' + this.state.incomePaymentSystemName + ' на ' + this.state.outcomePaymentSystemName + ' | ' + process.env.REACT_APP_NAME;
      let description = 'Обменять ' + this.state.incomePaymentSystemName + ' на ' + this.state.outcomePaymentSystemName + ' по выгодному курсу при высочайшем уровне надежности, безопасности и высоких резервах.';
      document.title = title;
      document.querySelector("meta[name='description']").setAttribute('content', description);
      document.querySelector("meta[property='og:title']").setAttribute('content', title);
      document.querySelector("meta[property='og:description']").setAttribute('content', description);
      document.querySelector("meta[property='og:url']").setAttribute('content', window.location.href);
      document.querySelector("link[rel='canonical']").setAttribute('href', window.location.href);
    }
    if (prevProps.accessToken !== this.props.accessToken) {
      if (!this.props.accessToken) {
        this.setState({ userDiscount: 1, promoDiscount: 1, outcomeAmount: +((this.state.incomeAmount * this.state.directionRate).toFixed(this.state.outcomeCrypto ? 8 : 2)), exchangeEmail: '', promoCode: '', isCheckedPromo: false });
        document.getElementById('promoCode').value = '';
      }
    }
    if (prevState.siteBreak !== undefined && prevState.siteBreak !== this.state.siteBreak) {
      localStorage.setItem('site-break', this.state.siteBreak);
      window.location.reload();
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    document.removeEventListener('mousedown', this.closeCountry);
  }

  changeAmount(event) {
    const pattern = new RegExp((event.target.name === 'incomeAmount' ? this.state.incomeCrypto : this.state.outcomeCrypto) ? /^[^\d]*(\d+([.]\d{0,8})?).*$/g : /^[^\d]*(\d+([.]\d{0,2})?).*$/g);
    event.target.value = event.target.value.replace(/^[^\d]*/g, '').replace(/^(0)([0-9])/g, '$2').replace(pattern, '$1');
    const incomeAmount = event.target.name === 'incomeAmount' ? event.target.value : +(parseFloat(this.state.data.map(item => (event.target.value / (item.direction_rates[this.state.incomePaymentSystemId][this.state.outcomePaymentSystemId].v * this.state.promoDiscount * this.state.userDiscount)))[0]).toFixed(this.state.incomeCrypto ? 8 : 2));
    const outcomeAmount = event.target.name === 'outcomeAmount' ? event.target.value : +(parseFloat(this.state.data.map(item => (event.target.value * item.direction_rates[this.state.incomePaymentSystemId][this.state.outcomePaymentSystemId].v * this.state.promoDiscount * this.state.userDiscount))[0]).toFixed(this.state.outcomeCrypto ? 8 : 2));
    this.setState({ incomeAmount: incomeAmount === '' ? '' : incomeAmount, outcomeAmount: outcomeAmount === '' ? '' : outcomeAmount, incomeAmountUser: event.target.name === 'incomeAmount' ? +event.target.value : '', outcomeAmountUser: event.target.name === 'outcomeAmount' ? +event.target.value : '' });
    this.checkAmount(incomeAmount, this.state.incomeMinAmount, this.state.incomeMaxAmount, outcomeAmount, this.state.outcomeReserve, event.target.name);
  }

  checkAmount(a, b, c, d, e, f) {
    if (a < b || a > c) {
      this.openDanger('incomeAmount');
      if (f === 'incomeAmount') { this.openToolTip(f); }
    } else {
      this.closeDanger('incomeAmount');
      if (f === 'incomeAmount') { this.closeToolTip(f); }
    }
    if (d > e) {
      this.openDanger('outcomeAmount');
      if (f === 'outcomeAmount') { this.openToolTip(f); }
    } else {
      this.closeDanger('outcomeAmount');
      if (f === 'outcomeAmount') { this.closeToolTip(f); }
    }
  }

  clearIncomeAccount() {
    document.getElementById("incomeAccount").value = "";
    this.closeDanger("incomeAccount");
    this.setState({ incomeAccount: '', incomeAccountLabel: '' });
    if (document.getElementById("incomeFname")) {
      document.getElementById("incomeFname").value = "";
      this.closeDanger("incomeFname");
      this.setState({ incomeFname: '', incomeFnameLabel: '' });
    }
    if (document.getElementById("incomeIname")) {
      document.getElementById("incomeIname").value = "";
      this.closeDanger("incomeIname");
      this.setState({ incomeIname: '', incomeInameLabel: '' });
    }
    if (document.getElementById("incomeOname")) {
      document.getElementById("incomeOname").value = "";
      this.closeDanger("incomeOname");
      this.setState({ incomeOname: '', incomeOnameLabel: '' });
    }
  }

  clearOutcomeAccount() {
    document.getElementById("outcomeAccount").value = "";
    this.closeDanger("outcomeAccount");
    this.setState({ outcomeAccount: '', outcomeAccountLabel: '' });
    if (document.getElementById("outcomeFname")) {
      document.getElementById("outcomeFname").value = "";
      this.closeDanger("outcomeFname");
      this.setState({ outcomeFname: '', outcomeFnameLabel: '' });
    }
    if (document.getElementById("outcomeIname")) {
      document.getElementById("outcomeIname").value = "";
      this.closeDanger("outcomeIname");
      this.setState({ outcomeIname: '', outcomeInameLabel: '' });
    }
    if (document.getElementById("outcomeOname")) {
      document.getElementById("outcomeOname").value = "";
      this.closeDanger("outcomeOname");
      this.setState({ outcomeOname: '', outcomeOnameLabel: '' });
    }
  }

  changePaymentSystemId(a, b, c, d) {
    this.closeToolTip();
    const incomePaymentSystemName = this.state.data.map(item => item.paymentSystems.find(item => item.id === a).attributes.name)[0];
    const outcomePaymentSystemName = this.state.data.map(item => item.paymentSystems.find(item => item.id === b).attributes.name)[0];
    const directionRate = this.state.data.map(item => item.direction_rates[a][b].v)[0];
    const incomeAmount = this.state.incomeAmountUser !== '' ? +this.state.incomeAmount : this.state.outcomeAmountUser !== '' ? (this.state.outcomeAmount / (directionRate * this.state.promoDiscount * this.state.userDiscount)) : this.state.data.map(item => item.direction_rates[a][b].min_in)[0];
    const incomeMinAmount = this.state.data.map(item => item.direction_rates[a][b].min_in)[0];
    const incomeMaxAmount = this.state.data.map(item => item.direction_rates[a][b].max_in)[0];
    const directionType = this.state.data.map(item => item.direction_rates[a][b].type)[0];
    const outcomeAmount = this.state.outcomeAmountUser !== '' ? +this.state.outcomeAmount : (incomeAmount * directionRate * this.state.promoDiscount * this.state.userDiscount);
    const outcomeReserve = this.state.data.map(item => item.reserves[b])[0];
    const incomeAmountCurrency = this.state.data.map(item => item.paymentSystems.find(item => item.id === a).attributes.currency_iso_code)[0];
    const outcomeAmountCurrency = this.state.data.map(item => item.paymentSystems.find(item => item.id === b).attributes.currency_iso_code)[0];
    const incomeCrypto = this.state.data.map(item => item.paymentSystems.find(item => item.id === a).attributes.currency.is_crypto)[0];
    const outcomeCrypto = this.state.data.map(item => item.paymentSystems.find(item => item.id === b).attributes.currency.is_crypto)[0];
    this.setState({ incomePaymentSystemName: incomePaymentSystemName, outcomePaymentSystemName: outcomePaymentSystemName, directionRate: directionRate, outcomeReserve: +outcomeReserve, incomeMaxAmount: +incomeMaxAmount, incomeMinAmount: +incomeMinAmount, directionType: directionType === 'auto' ? 'автомат' : 'ручной', incomeAmountCurrency: incomeAmountCurrency, outcomeAmountCurrency: outcomeAmountCurrency, incomePaymentSystemId: a, outcomePaymentSystemId: b, incomeListCurrency: c, outcomeListCurrency: d, incomeAmount: +(parseFloat(incomeAmount).toFixed(incomeCrypto ? 8 : 2)), outcomeAmount: +(parseFloat(outcomeAmount).toFixed(outcomeCrypto ? 8 : 2)), incomeCrypto: incomeCrypto, outcomeCrypto: outcomeCrypto });
    this.checkAmount(incomeAmount, incomeMinAmount, incomeMaxAmount, outcomeAmount, outcomeReserve);
  }

  changeIncomePaymentSystemId(value) {
    const outcomePaymentSystemIdCheck = this.state.data.map(item => item.direction_rates[value][this.state.outcomePaymentSystemId])[0] ? this.state.outcomePaymentSystemId : this.state.data.map(item => item.paymentSystems.filter(item => this.state.outcomeListCurrency !== '' ? item.attributes.currency.iso_code === this.state.outcomeListCurrency : item).sort((a, b) => a.attributes.priority_out - b.attributes.priority_out).map(item => item.id).find(item => this.state.data.find(obj => Object.keys(obj.direction_rates[value]).some(obj => +obj === item))))[0];
    const outcomePaymentSystemId = outcomePaymentSystemIdCheck ? outcomePaymentSystemIdCheck : this.state.data.map(item => item.paymentSystems.sort((a, b) => a.attributes.priority_out - b.attributes.priority_out).map(item => item.id).find(item => this.state.data.find(obj => Object.keys(obj.direction_rates[value]).some(obj => +obj === item))))[0];
    if (this.state.incomePaymentSystemId !== value) {
      this.clearIncomeAccount();
    }
    if (this.state.outcomePaymentSystemId !== outcomePaymentSystemId) {
      this.clearOutcomeAccount();
    }
    this.changePaymentSystemId(value, outcomePaymentSystemId, this.state.incomeListCurrency, this.state.outcomeListCurrency);
  }

  changeOutcomePaymentSystemId(value) {
    if (this.state.outcomePaymentSystemId !== value) {
      this.clearOutcomeAccount();
    }
    this.changePaymentSystemId(this.state.incomePaymentSystemId, value, this.state.incomeListCurrency, this.state.outcomeListCurrency);
  }

  changeCurrencyIncomeList(value) {
    const incomePaymentSystemId = value === '' ? this.state.incomePaymentSystemId : this.state.data.map(item => item.paymentSystems.filter(item => item.attributes.currency.iso_code === value).map(item => item.id).some(item => item === this.state.incomePaymentSystemId))[0] ? this.state.incomePaymentSystemId : this.state.data.map(item => item.paymentSystems.sort((a, b) => a.attributes.priority_in - b.attributes.priority_in).find(item => item.attributes.currency.iso_code === value).id)[0];
    const outcomePaymentSystemId = value === '' ? this.state.outcomePaymentSystemId : this.state.data.map(item => item.direction_rates[incomePaymentSystemId][this.state.outcomePaymentSystemId])[0] ? this.state.outcomePaymentSystemId : this.state.data.map(item => item.paymentSystems.sort((a, b) => a.attributes.priority_out - b.attributes.priority_out).map(item => item.id).find(item => this.state.data.find(obj => Object.keys(obj.direction_rates[incomePaymentSystemId]).some(obj => +obj === item))))[0];
    if (this.state.incomePaymentSystemId !== incomePaymentSystemId) {
      this.clearIncomeAccount();
    }
    if (this.state.outcomePaymentSystemId !== outcomePaymentSystemId) {
      this.clearOutcomeAccount();
    }
    this.changePaymentSystemId(incomePaymentSystemId, outcomePaymentSystemId, value, this.state.outcomeListCurrency);
  }

  changeCurrencyOutcomeList(value) {
    const outcomePaymentSystemIdCheck = value === '' ? this.state.outcomePaymentSystemId : this.state.data.map(item => item.paymentSystems.filter(item => item.attributes.currency.iso_code === value).map(item => item.id).some(item => item === this.state.outcomePaymentSystemId))[0] && this.state.data.map(item => item.direction_rates[this.state.incomePaymentSystemId][this.state.outcomePaymentSystemId])[0] ? this.state.outcomePaymentSystemId : this.state.data.map(item => item.paymentSystems.filter(item => item.attributes.currency.iso_code === value).sort((a, b) => a.attributes.priority_out - b.attributes.priority_out).map(item => item.id).find(item => this.state.data.find(obj => Object.keys(obj.direction_rates[this.state.incomePaymentSystemId]).some(obj => +obj === item))))[0];
    const outcomePaymentSystemId = outcomePaymentSystemIdCheck ? outcomePaymentSystemIdCheck : this.state.outcomePaymentSystemId;
    if (this.state.outcomePaymentSystemId !== outcomePaymentSystemId) {
      this.clearOutcomeAccount();
    }
    this.changePaymentSystemId(this.state.incomePaymentSystemId, outcomePaymentSystemId, this.state.incomeListCurrency, value);
  }

  openToolTip(i) {
    const openStatus = this.state.showToolTip.slice();
    openStatus[i] = true;
    this.setState({ showToolTip: openStatus });
  }

  closeToolTip(i) {
    if (i === undefined) {
      this.setState({ showToolTip: [] });
    } else {
      const closeStatus = this.state.showToolTip.slice();
      closeStatus[i] = false;
      this.setState({ showToolTip: closeStatus });
    }
  }

  openDanger(i) {
    const openStatus = this.state.showDanger;
    openStatus[i] = true;
    this.setState({ showDanger: openStatus });
  }

  closeDanger(i) {
    const closeStatus = this.state.showDanger;
    closeStatus[i] = false;
    this.setState({ showDanger: closeStatus });
  }

  clickForm = a => event => {
    this.setState({ notification: '' });
    this.closeToolTip(event.target.name);
    const pattern = new RegExp(a);
    if (event.target.value !== '' || (event.target.value === '' && this.state.showDanger[event.target.name] === true)) {
      if (!pattern.test(event.target.value)) {
        this.openDanger(event.target.name);
        this.openToolTip(event.target.name);
      } else {
        this.closeDanger(event.target.name);
        this.closeToolTip(event.target.name);
      }
    }
    if (event.target.name === 'promoCode' && pattern.test(event.target.value) && this.state.exchangeEmail) {
      this.checkCode(event.target.value, this.state.exchangeEmail);
    }
    if ((event.target.name === 'incomeAccount' && !this.state.isValidIncomeAccount) || (event.target.name === 'outcomeAccount' && !this.state.isValidOutcomeAccount)) {
      this.openDanger(event.target.name);
      this.openToolTip(event.target.name);
    }
  }

  blurForm = a => event => {
    this.closeToolTip();
    const pattern = new RegExp(a);
    if (!pattern.test(event.target.value)) {
      this.openDanger(event.target.name);
    }
  }

  changeForm = (a, b) => event => {
    if (event.target.placeholder === 'Страна отправления' || event.target.placeholder === 'Страна получения') {
      this.setState({ isOpenedCountry: true });
    }
    if (event.target.placeholder !== 'Страна отправления' && event.target.placeholder !== 'Страна получения' && event.target.name !== 'incomeFname' && event.target.name !== 'outcomeFname' && event.target.name !== 'incomeIname' && event.target.name !== 'outcomeIname' && event.target.name !== 'incomeOname' && event.target.name !== 'outcomeOname') {
      event.target.value = event.target.value.replace(/\s/g, '');
    }
    if (b === 'ABC') {
      event.target.value = event.target.value.toUpperCase();
    } else if (b === 'abc') {
      event.target.value = event.target.value.toLowerCase();
    } else if (b === 'Abc') {
      event.target.value = event.target.value.replace(/^./, u => u.toUpperCase());
    } else if (b === 123) {
      event.target.value = event.target.value.replace(/[^\d]/g, '');
    }
    if (event.target.name === 'exchangeEmail' && this.state.promoDiscount > 1) {
      this.setState({ promoDiscount: 1, outcomeAmount: +((this.state.incomeAmount * this.state.directionRate * this.state.userDiscount).toFixed(this.state.outcomeCrypto ? 8 : 2)) })
    }
    this.setState({ [event.target.name]: event.target.value, [event.target.name + 'Label']: event.target.placeholder.replace(/([^<]*),\s([^.]+)/, '$1') });
    const pattern = new RegExp(a);
    if (event.target.value !== '') {
      if (pattern.test(event.target.value)) {
        this.closeDanger(event.target.name);
        this.closeToolTip(event.target.name);
        if (event.target.name === 'promoCode' && this.state.exchangeEmail) {
          this.checkCode(event.target.value, this.state.exchangeEmail);
        } else if (event.target.name === 'exchangeEmail' && this.state.promoCode.length === 16) {
          this.checkCode(this.state.promoCode, event.target.value);
        } else if (event.target.name === 'incomeAccount') {
          this.setState({ isValidIncomeAccount: true, checkIncomeAccount: true });
        } else if (event.target.name === 'outcomeAccount') {
          this.setState({ isValidOutcomeAccount: true, checkOutcomeAccount: true });
        }
      } else {
        if (event.target.name === 'incomeAccount') {
          this.setState({ checkIncomeAccount: false });
        } else if (event.target.name === 'outcomeAccount') {
          this.setState({ checkOutcomeAccount: false });
        }
      }
    } else {
      this.closeToolTip(event.target.name);
      this.closeDanger(event.target.name);
    }
  }

  checkAccount = async (a, b) => {
    return await fetch('/api/account-verify', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 'paymentSystemId': a, 'account': b }),
    })
      .then(response => response.json())
      .then((result) => {
        if (!result.status) {
          return false;
        } else {
          return true;
        }
      })
  }

  checkCode = (a, b) => {
    if (this.state.isCheckedPromo) {
      fetch('/api/promocode', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 'promoCode': a, 'exchangeEmail': b }),
      })
        .then(response => response.json())
        .then((result) => {
          if (!result.status) {
            this.openDanger('promoCode');
            this.openToolTip('promoCodeCheck');
            this.setState({ promoDiscount: 1 });
            this.updateData();
          } else {
            this.closeDanger('promoCode');
            this.closeToolTip('promoCodeCheck');
            this.setState({ promoDiscount: 1 + (result.discount / 100) });
            this.updateData();
          }
        })
    }
  }

  changeToggle() {
    this.setState({ isChecked: !this.state.isChecked });
    if (!this.state.isChecked === false) {
      this.openToolTip('agree');
    } else {
      this.closeToolTip('agree');
    }
  }

  changeTogglePromo() {
    this.setState({ isCheckedPromo: !this.state.isCheckedPromo });
    if (this.state.isCheckedPromo) {
      this.closeDanger('promoCode');
      this.setState({ promoCode: '', promoDiscount: 1, outcomeAmount: +((this.state.incomeAmount * this.state.directionRate * this.state.userDiscount).toFixed(this.state.outcomeCrypto ? 8 : 2)) })
      document.getElementById('promoCode').value = '';
    }
  }

  changeModal = () => {
    this.setState({ isOpened: !this.state.isOpened, isDisabled: !this.state.isOpened ? true : false });
  }

  changeIncomeModal = () => {
    this.setState({ isIncomeOpened: !this.state.isIncomeOpened });
  }

  changeOutcomeModal = () => {
    this.setState({ isOutcomeOpened: !this.state.isOutcomeOpened });
  }

  selectCountry(event) {
    const a = this.state.incomeCountry ? 'incomeAccount' : 'outcomeAccount';
    this.setState({ [a]: event.target.getAttribute('data-value'), isOpenedCountry: false, [a + 'Label']: a === 'incomeAccount' ? 'Страна отправления' : 'Страна получения' });
    document.getElementById(a).value = event.target.getAttribute('data-value');
    this.closeDanger(a);
  }

  showCountry(e) {
    if (e.target.name === 'incomeAccount') {
      this.setState({ isOpenedCountry: !this.state.isOpenedCountry, incomeCountry: true, outcomeCountry: false });
    } else if (e.target.name === 'outcomeAccount') {
      this.setState({ isOpenedCountry: !this.state.isOpenedCountry, incomeCountry: false, outcomeCountry: true });
    }
  }

  closeCountry(e) {
    if (this.incomeAccountSection.current && !this.incomeAccountSection.current.contains(e.target)) {
      if (this.state.isOpenedCountry && this.countrySection.current && !this.countrySection.current.contains(e.target)) {
        if (document.querySelector('.select-country li').ariaSelected === 'true' || (document.querySelector('.select-country li').ariaSelected === 'false' && document.querySelectorAll('.select-country li').length === 1)) {
          this.setState({ incomeAccount: document.querySelector('.select-country li').innerText, incomeAccountLabel: 'Страна отправления' });
          document.getElementById('incomeAccount').value = document.querySelector('.select-country li').innerText;
        } else {
          this.setState({ incomeAccount: '' });
          this.openDanger('incomeAccount');
        }
        this.setState({ isOpenedCountry: false });
      }
    } else if (this.outcomeAccountSection.current && !this.outcomeAccountSection.current.contains(e.target)) {
      if (this.state.isOpenedCountry && this.countrySection.current && !this.countrySection.current.contains(e.target)) {
        if (document.querySelector('.select-country li').ariaSelected === 'true' || (document.querySelector('.select-country li').ariaSelected === 'false' && document.querySelectorAll('.select-country li').length === 1)) {
          this.setState({ outcomeAccount: document.querySelector('.select-country li').innerText, outcomeAccountLabel: 'Страна получения' });
          document.getElementById('outcomeAccount').value = document.querySelector('.select-country li').innerText;
        } else {
          this.setState({ outcomeAccount: '' });
          this.openDanger('outcomeAccount');
        }
        this.setState({ isOpenedCountry: false });
      }
    }
  }

  async submitForm(e) {
    e.preventDefault();
    this.setState({ isDisabled: true, isSubmitted: { submitForm: true } });
    if (this.state.promoCode !== '') {
      this.checkCode(this.state.promoCode, this.state.exchangeEmail);
    }
    if (this.state.incomeAccount === '') {
      this.openDanger('incomeAccount');
    } else if (this.state.incomeAccount !== '' && this.state.checkIncomeAccount) {
      const validIncomeAccount = await this.checkAccount(this.state.incomePaymentSystemId, this.state.incomeAccount).then(result => { return result; });
      if (!validIncomeAccount) {
        this.openDanger('incomeAccount');
        this.setState({ isValidIncomeAccount: false });
      } else {
        this.setState({ isValidIncomeAccount: true });
      }
    }
    if (this.state.outcomeAccount === '') {
      this.openDanger('outcomeAccount');
    } else if (this.state.outcomeAccount !== '' && this.state.checkOutcomeAccount) {
      const validOutcomeAccount = await this.checkAccount(this.state.outcomePaymentSystemId, this.state.outcomeAccount).then(result => { return result; });
      if (!validOutcomeAccount) {
        this.openDanger('outcomeAccount');
        this.setState({ isValidOutcomeAccount: false });
      } else {
        this.setState({ isValidOutcomeAccount: true });
      }
    }
    if (this.state.exchangeEmail === '' && !this.props.accessToken) {
      this.openDanger('exchangeEmail');
    }
    if (this.state.isCheckedPromo && this.state.promoCode === '') {
      this.openDanger('promoCode');
    }
    if (document.getElementById("incomeFname") && this.state.incomeFname === '') {
      this.openDanger('incomeFname');
    }
    if (document.getElementById("incomeIname") && this.state.incomeIname === '') {
      this.openDanger('incomeIname');
    }
    if (document.getElementById("outcomeFname") && this.state.outcomeFname === '') {
      this.openDanger('outcomeFname');
    }
    if (document.getElementById("outcomeIname") && this.state.outcomeIname === '') {
      this.openDanger('outcomeIname');
    }

    var sortDanger = this.state.pattern.map(pattern => Object.entries(this.state.showDanger).find(item => item[0] === pattern[0])).filter(item => item !== undefined);
    var trueProps = sortDanger.filter(([key, value]) => value === true);

    if (trueProps.length > 0) {
      this.openToolTip(trueProps[0][0]);
      this.setState({ isDisabled: false, isSubmitted: {} });
    } else if (this.state.isChecked === false) {
      this.openToolTip('agree');
      this.setState({ isDisabled: false, isSubmitted: {} });
    } else {
      fetch('/api/webmoney-verify', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 'lang': 'ru', 'incomePaymentSystemId': this.state.incomePaymentSystemId, 'incomeAccount': this.state.incomeAccount, 'incomeAmount': this.state.incomeAmount, 'incomeFname': this.state.incomeFname, 'incomeIname': this.state.incomeIname, 'outcomePaymentSystemId': this.state.outcomePaymentSystemId, 'outcomeAccount': this.state.outcomeAccount, 'outcomeAmount': this.state.outcomeAmount, 'outcomeFname': this.state.outcomeFname, 'outcomeIname': this.state.outcomeIname }),
      })
        .then(response => response.json())
        .then((result) => {
          if (!result.status) {
            this.setState({ notification: result.desc.replace(/^./, u => u.toUpperCase()) });
            this.setState({ isDisabled: false, isSubmitted: {} });
          } else {
            fetch('/api/order-create', {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ 'retid': result.retid, 'pid': localStorage.getItem('partner'), 'incomePaymentSystemId': this.state.incomePaymentSystemId, 'outcomePaymentSystemId': this.state.outcomePaymentSystemId, 'incomeCrypto': this.state.incomeCrypto, 'outcomeCrypto': this.state.outcomeCrypto, 'incomeAmount': this.state.incomeAmount, 'outcomeAmount': this.state.outcomeAmount, 'incomeAccount': this.state.incomeAccount, 'outcomeAccount': this.state.outcomeAccount, 'incomeFname': this.state.incomeFname, 'incomeIname': this.state.incomeIname, 'incomeOname': this.state.incomeOname, 'outcomeFname': this.state.outcomeFname, 'outcomeIname': this.state.outcomeIname, 'outcomeOname': this.state.outcomeOname, 'exchangeEmail': this.state.exchangeEmail, 'lang': this.state.lang, 'promoCode': this.state.promoCode, 'outcomeAmountUser': this.state.outcomeAmountUser }),
            })
              .then(response => response.json())
              .then((result) => {
                if (result.login) {
                  localStorage.setItem('token', result.token);
                }
                this.setState({ orderHash: result.hash, isOpened: !this.state.isOpened, isSubmitted: {} });
              })
          }
        })
    }
  }

  render() {
    const incomeList = this.state.data.map(item => item.paymentSystems.sort((a, b) => a.attributes.priority_in - b.attributes.priority_in).map((item, index) => {
      return (this.state.incomeListCurrency !== '' ? item.attributes.currency.iso_code === this.state.incomeListCurrency && item.attributes.income_enabled === true : item.attributes.income_enabled === true) ? <div className="exchange-systems" key={index} onClick={this.state.isIncomeOpened ? () => { this.changeIncomePaymentSystemId(item.id); this.changeIncomeModal(); } : () => this.changeIncomePaymentSystemId(item.id)}>
        <div className="exchange-system-row">
          <label className={item.id === this.state.incomePaymentSystemId ? "exchange-system-item exchange-system-item-active" : "exchange-system-item"} htmlFor={'incomeSystem' + item.id}>
            <input type="radio" className="exchange-system-input" id={'incomeSystem' + item.id} name="incomeSystem" value={item.id} defaultChecked={item.id === this.state.incomePaymentSystemId ? 'checked' : null} />
            <span className={item.attributes.icon_css_class + ' icon-default'}></span>
            <span className="exchange-system-name">{item.attributes.name}</span>
            <span className="exchange-system-limit"><span className="exchange-system-limit-amount">
              {item.id === this.state.incomePaymentSystemId ? this.state.data.map(item2 => item2.direction_rates[item.id][this.state.outcomePaymentSystemId].min_in) + ' / ' + this.state.data.map(item2 => item2.direction_rates[item.id][this.state.outcomePaymentSystemId].max_in === null ? String.fromCharCode(8734) : item2.direction_rates[item.id][this.state.outcomePaymentSystemId].max_in) : null}
            </span></span>
          </label>
        </div>
      </div> : null;
    }));

    const outcomeList = this.state.data.map(item => item.paymentSystems.sort((a, b) => a.attributes.priority_out - b.attributes.priority_out).map((item, index) => this.state.data.map(obj => Object.keys(obj.direction_rates[this.state.incomePaymentSystemId]).map(obj => +obj).map(rate => {
      return item.id === rate ? (this.state.outcomeListCurrency !== '' ? item.attributes.currency.iso_code === this.state.outcomeListCurrency && item.attributes.outcome_enabled === true : item.attributes.outcome_enabled === true) ? <div className="exchange-systems" key={index} onClick={this.state.isOutcomeOpened ? () => { this.changeOutcomePaymentSystemId(item.id); this.changeOutcomeModal(); } : () => this.changeOutcomePaymentSystemId(item.id)}>
        <div className="exchange-system-row">
          <label className={item.id === this.state.outcomePaymentSystemId ? "exchange-system-item exchange-system-item-active" : "exchange-system-item"} htmlFor={'outcomeSystem' + item.id}>
            <input type="radio" className="exchange-system-input" id={'outcomeSystem' + item.id} name="outcomeSystem" value={item.id} defaultChecked={item.id === this.state.outcomePaymentSystemId ? 'checked' : null} />
            <span className={item.attributes.icon_css_class + ' icon-default'}></span>
            <span className="exchange-system-name">{item.attributes.name}</span>
            <span className="exchange-system-reserve">
              {this.state.data.map((item2, index) => {
                return <Reserves reserve={parseFloat(item2.reserves[item.id]).toFixed(item.attributes.currency.is_crypto ? 8 : 2)} key={index} />;
              })
              }
            </span>
          </label>
        </div>
      </div> : null : null;
    }))));

    const incomePaymentSystem = this.state.incomePaymentSystemName.replace(/([^<]*)\s([A-Za-z]+)/, '$1');
    const outcomePaymentSystem = this.state.outcomePaymentSystemName.replace(/([^<]*)\s([A-Za-z]+)/, '$1');

    const incomeClass = this.state.data.map(item => item.paymentSystems.find(item => item.id === this.state.incomePaymentSystemId).attributes.icon_css_class)[0];
    const outcomeClass = this.state.data.map(item => item.paymentSystems.find(item => item.id === this.state.outcomePaymentSystemId).attributes.icon_css_class)[0];

    const incomeAccount = this.state.data.map(item => item.paymentSystems.find(item => item.id === this.state.incomePaymentSystemId).attributes.income_form_fields.map((item, index) => {
      let inputName = item.name.replace(/_\w/g, $0 => $0.toUpperCase()).replace(/_/g, "");
      return <div className="field" key={index} {...(item.title === 'Страна отправления' ? { ref: this.incomeAccountSection } : {})} {...(item.title === 'Страна отправления' ? { onClick: this.showCountry } : {})}>
        <input type="text" className={"input" + (this.state.showDanger[inputName] ? " input-danger" : "")} id={inputName} name={inputName} placeholder={item.title} autoComplete="off" {...(item.is_required ? { onClick: this.clickForm(item.pattern) } : {})} {...(item.is_required ? { onBlur: this.blurForm(item.pattern) } : {})} onChange={this.changeForm(item.pattern, item.replace)} />
        <div className="field-icon"><span className={item.name === 'income_account' ? incomeClass + ' icon-medium' : 'icon-user icon-medium'}></span></div>
        <Tooltip status={this.state.showToolTip[inputName]} closeToolTip={() => this.closeToolTip(inputName)} text={'Введите корректный ' + item.title + '.'} />
      </div>;
    }));

    const outcomeAccount = this.state.data.map(item => item.paymentSystems.find(item => item.id === this.state.outcomePaymentSystemId).attributes.outcome_form_fields.map((item, index) => {
      let inputName = item.name.replace(/_\w/g, $0 => $0.toUpperCase()).replace(/_/g, "");
      return <div className="field" key={index} {...(item.title === 'Страна получения' ? { ref: this.outcomeAccountSection } : {})} {...(item.title === 'Страна получения' ? { onClick: this.showCountry } : {})}>
        <input type="text" className={"input" + (this.state.showDanger[inputName] ? " input-danger" : "")} id={inputName} name={inputName} placeholder={item.title} autoComplete="off" {...(item.is_required ? { onClick: this.clickForm(item.pattern) } : {})} {...(item.is_required ? { onBlur: this.blurForm(item.pattern) } : {})} onChange={this.changeForm(item.pattern, item.replace)} />
        <div className="field-icon"><span className={item.name === 'outcome_account' ? outcomeClass + ' icon-medium' : 'icon-user icon-medium'}></span></div>
        <Tooltip status={this.state.showToolTip[inputName]} closeToolTip={() => this.closeToolTip(inputName)} text={'Введите корректный ' + item.title + '.'} />
      </div>;
    }));

    const incomeLetterCode = this.state.data.map(item => item.paymentSystems.find(item => item.id === this.state.incomePaymentSystemId).attributes.letter_code)[0];
    const incomeNote = this.state.data.map(item => item.paymentSystems.find(item => item.id === this.state.incomePaymentSystemId).attributes.note)[0];
    const outcomeType = this.state.data.map(item => item.paymentSystems.find(item => item.id === this.state.outcomePaymentSystemId).attributes.wm_type)[0];

    const manualConfirm = this.state.data.map(item => item.paymentSystems.find(item => item.id === this.state.incomePaymentSystemId).attributes.manual_confirm)[0];

    const incomeRate = this.state.data.map(item => item.direction_rates[this.state.incomePaymentSystemId][this.state.outcomePaymentSystemId].r.i);
    const outcomeRate = this.state.data.map(item => item.direction_rates[this.state.incomePaymentSystemId][this.state.outcomePaymentSystemId].r.o);

    const percent = this.state.data.map(item => item.paymentSystems.find(item => item.id === this.state.incomePaymentSystemId).attributes.income_fee);
    const maxFee = this.state.data.map(item => item.paymentSystems.find(item => item.id === this.state.incomePaymentSystemId).attributes.income_max_fee);
    const incomeAmountFee = Math.ceil(Math.min(this.state.incomeAmount * (percent / 100 + 1) * 100 - 0.0000001, (this.state.incomeAmount - (maxFee > 0 ? -maxFee : -1000000)) * 100 - 0.0000001)) / 100;

    const countries = this.state.dataCountries.map(item => item.country.sort((a, b) => a.name.localeCompare(b.name)).map((item, index) => {
      return item['name'].includes(this.state.incomeCountry ? this.state.incomeAccount : this.state.outcomeAccount) ?
        <li key={index} role="option" data-value={item['name']} onClick={this.selectCountry} aria-selected={this.state.incomeAccount === item['name'] ? true : false}><span className={item['flag'] + " icon-medium"} data-value={item['name']} onClick={this.selectCountry}></span><span data-value={item['name']} onClick={this.selectCountry}>{item['name']}</span></li>
        : null
    }));

    const monitoring = this.state.data.map(item => item.monitoring.map((item, index) => {
      return <div className="monitoring-item" key={index}>
        <div className="monitoring-table">
          <div className="monitoring-column">
            <div className="monitoring-system">{item.date.replace(/([^<]*)\s([^<]+)/, '$1')}, {item.date.replace(/([^<]*)\s([^<]+):([^<]+)/, '$2')}</div>
            <div><span className={item.income_icon + " icon-default"}></span><span className="monitoring-amount">{item.income_amount} {item.income_payment_system_name.replace(/([^<]*)\s([A-Za-z]+)/, '$2')}</span></div>
            <div className="monitoring-system">{item.income_payment_system_name.replace(/([^<]*)\s([A-Za-z]+)/, '$1')}</div>
          </div>
          <div className="monitoring-column">→</div>
          <div className="monitoring-column">
            <div className="monitoring-system">{item.country}</div>
            <div><span className={item.outcome_icon + " icon-default"}></span><span className="monitoring-amount">{item.outcome_amount} {item.outcome_payment_system_name.replace(/([^<]*)\s([A-Za-z]+)/, '$2')}</span></div>
            <div className="monitoring-system">{item.outcome_payment_system_name.replace(/([^<]*)\s([A-Za-z]+)/, '$1')}</div>
          </div>
        </div>
      </div>
    }));

    return (
      <div className="page">
        <div className="page-content">
          <form autoComplete="off" onSubmit={this.submitForm}>
            <div className="exchange">
              <div className="exchange-column">
                <div className="exchange-content">
                  <div className="exchange-title">Отдадите</div>
                  <div className="icon-in icon-exchange"></div>
                </div>
                <div className="field">
                  <input type="text" className={"input" + (this.state.showDanger['incomeAmount'] ? " input-danger" : "")} id="incomeAmount" name="incomeAmount" placeholder={'Сумма к оплате, ' + this.state.incomeAmountCurrency} autoComplete="off" maxLength="16" value={this.state.incomeAmount} onClick={(e) => this.checkAmount(this.state.incomeAmount, this.state.incomeMinAmount, this.state.incomeMaxAmount, this.state.outcomeAmount, this.state.outcomeReserve, e.target.name)} onBlur={this.blurForm(Amount)} onChange={this.changeAmount} />
                  <div className="field-icon"><span className={incomeClass + ' icon-big'}></span></div>
                  <Tooltip status={this.state.showToolTip['incomeAmount']} closeToolTip={() => this.closeToolTip('incomeAmount')} text={this.state.incomeAmount < this.state.incomeMinAmount ? 'Минимальная сумма для данного направления обмена составляет ' + parseFloat(this.state.incomeMinAmount).toFixed(this.state.incomeCrypto ? 8 : 2) + ' ' + this.state.incomeAmountCurrency + '.' : 'Максимальная сумма для данного направления обмена составляет ' + parseFloat(this.state.incomeMaxAmount).toFixed(this.state.incomeCrypto ? 8 : 2) + ' ' + this.state.incomeAmountCurrency + '.'} />
                </div>

                <div className="field select" onClick={this.changeIncomeModal}>
                  <div className="field-icon left"><span className={incomeClass + ' icon-big'}></span></div>
                  <input type="text" className="input input-select" id="incomePaymentSystem" name="incomePaymentSystem" autoComplete="off" value={this.state.incomePaymentSystemName} readOnly />
                  <div className="field-arrow-icon"><span className="icon-arrow icon-default"></span></div>
                </div>

                <div className={"exchange-system-wrapper" + (this.state.isIncomeOpened ? " open" : "")}>
                  <div className="modal-system-close" onClick={this.changeIncomeModal}><span className="icon-close icon-small"></span></div>

                  <div className="exchange-categories">
                    <div className={this.state.incomeListCurrency === '' ? "exchange-categories-column exchange-categories-column-active" : "exchange-categories-column"} onClick={() => this.changeCurrencyIncomeList('')}>Все</div>
                    <div className={this.state.incomeListCurrency === 'RUB' ? "exchange-categories-column exchange-categories-column-active" : "exchange-categories-column"} onClick={() => this.changeCurrencyIncomeList('RUB')}>RUB</div>
                    <div className={this.state.incomeListCurrency === 'USD' ? "exchange-categories-column exchange-categories-column-active" : "exchange-categories-column"} onClick={() => this.changeCurrencyIncomeList('USD')}>USD</div>
                    <div className={this.state.incomeListCurrency === 'EUR' ? "exchange-categories-column exchange-categories-column-active" : "exchange-categories-column"} onClick={() => this.changeCurrencyIncomeList('EUR')}>EUR</div>
                    <div className={this.state.incomeListCurrency === 'UAH' ? "exchange-categories-column exchange-categories-column-active" : "exchange-categories-column"} onClick={() => this.changeCurrencyIncomeList('UAH')}>UAH</div>
                    <div className={this.state.incomeListCurrency === 'COIN' ? "exchange-categories-column exchange-categories-column-active" : "exchange-categories-column"} onClick={() => this.changeCurrencyIncomeList('COIN')}>COIN</div>
                  </div>
                  <div className="exchange-text">
                    <span className="exchange-text-limit">Мин/Макс сумма</span>
                    <span>Выбор системы</span>
                  </div>
                  <div className="exchange-list">{incomeList}</div>
                </div>

              </div>
              <div className="exchange-column">
                <div className="exchange-content">
                  <div className="exchange-title">Получите</div>
                  <div className="icon-out icon-exchange"></div>
                </div>
                <div className="field">
                  <input type="text" className={"input" + (this.state.showDanger['outcomeAmount'] ? " input-danger" : "")} id="outcomeAmount" name="outcomeAmount" placeholder={'Сумма к получению, ' + this.state.outcomeAmountCurrency} autoComplete="off" maxLength="16" value={this.state.outcomeAmount} onClick={(e) => this.checkAmount(this.state.incomeAmount, this.state.incomeMinAmount, this.state.incomeMaxAmount, this.state.outcomeAmount, this.state.outcomeReserve, e.target.name)} onBlur={this.blurForm(Amount)} onChange={this.changeAmount} />
                  <div className="field-icon"><span className={outcomeClass + ' icon-big'}></span></div>
                  <Tooltip status={this.state.showToolTip['outcomeAmount']} closeToolTip={() => this.closeToolTip('outcomeAmount')} text={'На текущий момент сумма резерва составляет ' + parseFloat(this.state.outcomeReserve).toFixed(this.state.outcomeCrypto ? 8 : 2) + ' ' + this.state.outcomeAmountCurrency + '.'} />
                </div>

                <div className="field select" onClick={this.changeOutcomeModal}>
                  <div className="field-icon left"><span className={outcomeClass + ' icon-big'}></span></div>
                  <input type="text" className="input input-select" id="outcomePaymentSystem" name="outcomePaymentSystem" autoComplete="off" value={this.state.outcomePaymentSystemName} readOnly />
                  <div className="field-arrow-icon"><span className="icon-arrow icon-default"></span></div>
                </div>

                <div className={"exchange-system-wrapper" + (this.state.isOutcomeOpened ? " open" : "")}>
                  <div className="modal-system-close" onClick={this.changeOutcomeModal}><span className="icon-close icon-small"></span></div>

                  <div className="exchange-categories">
                    <div className={this.state.outcomeListCurrency === '' ? "exchange-categories-column exchange-categories-column-active" : "exchange-categories-column"} onClick={() => this.changeCurrencyOutcomeList('')}>Все</div>
                    <div className={this.state.outcomeListCurrency === 'RUB' ? "exchange-categories-column exchange-categories-column-active" : "exchange-categories-column"} onClick={() => this.changeCurrencyOutcomeList('RUB')}>RUB</div>
                    <div className={this.state.outcomeListCurrency === 'USD' ? "exchange-categories-column exchange-categories-column-active" : "exchange-categories-column"} onClick={() => this.changeCurrencyOutcomeList('USD')}>USD</div>
                    <div className={this.state.outcomeListCurrency === 'EUR' ? "exchange-categories-column exchange-categories-column-active" : "exchange-categories-column"} onClick={() => this.changeCurrencyOutcomeList('EUR')}>EUR</div>
                    <div className={this.state.outcomeListCurrency === 'UAH' ? "exchange-categories-column exchange-categories-column-active" : "exchange-categories-column"} onClick={() => this.changeCurrencyOutcomeList('UAH')}>UAH</div>
                    <div className={this.state.outcomeListCurrency === 'COIN' ? "exchange-categories-column exchange-categories-column-active" : "exchange-categories-column"} onClick={() => this.changeCurrencyOutcomeList('COIN')}>COIN</div>
                  </div>
                  <div className="exchange-text">
                    <span>Выбор системы</span>
                    <span className="exchange-text-reserve">Резервы валют</span>
                  </div>
                  <div className="exchange-list">{outcomeList}</div>
                </div>
              </div>

              <div className="exchange-column">
                <div className="exchange-content">
                  <h1>Заявка</h1>
                  <div className="icon-data icon-exchange"></div>
                </div>

                <div className="exchange-direction-row">
                  <div className="exchange-direction-item">
                    <span className="exchange-direction-title">Курс обмена</span>
                    <span className="exchange-direction-content">
                      <span className="exchange-direction-amount">{parseFloat(incomeRate).toFixed(2)} {this.state.incomeAmountCurrency} = {parseFloat(outcomeRate).toFixed(2)} {this.state.outcomeAmountCurrency}</span>
                    </span>
                  </div>

                  <div className="exchange-direction-item">
                    <span className={incomeClass + ' icon-default'}></span>
                    <span className="exchange-direction-title">{this.state.incomePaymentSystemName}</span>
                    <span className="exchange-direction-content">
                      <span className="exchange-direction-amount">{this.state.incomeAmount <= 0 ? (0).toFixed(2) : parseFloat(this.state.incomeAmount).toFixed(this.state.incomeCrypto ? 8 : 2)}</span>
                    </span>
                  </div>

                  {percent > 0 ?
                    <div className="exchange-direction-item">
                      <span className="exchange-direction-title">с комиссией {percent}%</span>
                      <span className="exchange-direction-content">
                        <span className="exchange-direction-amount">{incomeAmountFee.toFixed(this.state.incomeCrypto ? 8 : 2)}</span>
                      </span>
                    </div>
                    : null
                  }

                  <div className="exchange-direction-item">
                    <span className={outcomeClass + ' icon-default'}></span>
                    <span className="exchange-direction-title">{this.state.outcomePaymentSystemName}</span>
                    <span className="exchange-direction-content">
                      <span className="exchange-direction-amount">{this.state.outcomeAmount <= 0 ? (0).toFixed(2) : parseFloat(this.state.outcomeAmount).toFixed(this.state.outcomeCrypto ? 8 : 2)}</span>
                    </span>
                  </div>

                  <div className="exchange-direction-item">
                    <span className="exchange-direction-title">Тип обмена</span>
                    <span className="exchange-direction-content">
                      <span className="exchange-direction-amount">{this.state.directionType}</span>
                    </span>
                  </div>
                </div>

                {incomeAccount}
                {this.state.isOpenedCountry ?
                  <div className="countries">
                    <div className={"select-country" + (this.state.incomeCountry ? " income" : " outcome")} ref={this.countrySection}>
                      <ul>{(countries[0]).every(element => element === null) ? <li><span className="icon-globe icon-medium"></span>Страна не найдена</li> : countries}</ul>
                    </div>
                  </div>
                  :
                  null
                }
                {outcomeAccount}

                {!this.props.accessToken ?
                  <div className="field">
                    <input type="text" className={"input" + (this.state.showDanger['exchangeEmail'] ? " input-danger" : "")} id="exchangeEmail" name="exchangeEmail" placeholder="E-mail" autoComplete="off" onClick={this.clickForm(Email)} onBlur={this.blurForm(Email)} onChange={this.changeForm(Email, 'abc')} />
                    <div className="field-icon"><span className="icon-email icon-medium"></span></div>
                    <Tooltip status={this.state.showToolTip['exchangeEmail']} closeToolTip={() => this.closeToolTip('exchangeEmail')} text="Введите корректный E-mail." />
                  </div>
                  : null
                }
                <div className="field last">
                  <label className="checkbox-content">
                    <input type="checkbox" className="checkbox" id="promo" name="promo" value="true" checked={this.state.isCheckedPromo} onChange={this.changeTogglePromo} onBlur={this.blurForm()} />
                    <div className="checkbox-area" aria-checked={this.state.isCheckedPromo ? "true" : "false"}>
                      <span className={"checkbox-toggle" + (this.state.isCheckedPromo ? " active" : "")}></span>
                    </div>
                    У меня есть промокод
                  </label>
                </div>
                <div className="field" style={!this.state.isCheckedPromo || this.state.promoDiscount !== 1 ? { display: 'none' } : null}>
                  <input type="text" className={"input" + (this.state.showDanger['promoCode'] ? " input-danger" : "")} id="promoCode" name="promoCode" placeholder="Промокод, 16 цифр и букв" autoComplete="off" onClick={this.clickForm(Promo)} onBlur={this.blurForm(Promo)} onChange={this.changeForm(Promo, 'ABC')} />
                  <div className="field-icon"><span className="icon-discount icon-medium"></span></div>
                  <Tooltip status={this.state.showToolTip['promoCode']} closeToolTip={() => this.closeToolTip('promoCode')} text="Введите корректный Промокод, 16 цифр и букв." />
                  <Tooltip status={this.state.showToolTip['promoCodeCheck']} closeToolTip={() => this.closeToolTip('promoCodeCheck')} text="Промокод недействителен или уже использован." />
                </div>

                {this.state.promoDiscount !== 1 ?
                  <div className="field promocode">Промокод на скидку {parseFloat((this.state.promoDiscount - 1) * 100).toFixed(2)}% активирован.</div>
                  :
                  null
                }

                <div className="field last">
                  <label className="checkbox-content">
                    <input type="checkbox" className="checkbox" id="agree" name="agree" value="true" checked={this.state.isChecked} onChange={this.changeToggle} onBlur={this.blurForm()} />
                    <div className="checkbox-area" aria-checked={this.state.isChecked ? "true" : "false"}>
                      <span className={"checkbox-toggle" + (this.state.isChecked ? " active" : "")}></span>
                    </div>
                    Я принимаю <NavLink to="/agreement">Пользовательское Соглашение</NavLink>
                  </label>
                  <div className="tooltip-agree">
                    <Tooltip status={this.state.showToolTip['agree']} closeToolTip={() => this.closeToolTip('agree')} text="Вам необходимо ознакомиться и принять Пользовательское Соглашение." />
                  </div>
                </div>

                {this.state.notification ?
                  <div className="notification-danger">{this.state.notification}</div>
                  : null
                }

                <button className={"button" + (this.state.isSubmitted['submitForm'] ? " button-loading" : "") + (this.state.isUpdating ? " button-updating" : "")} disabled={this.state.isDisabled} type="submit">Обменять</button>

                {(incomeNote !== '' && incomePaymentSystem !== 'WebMoney') || (incomeNote !== '' && incomePaymentSystem === 'WebMoney' && outcomeType > 0 && outcomeType < 5) ?
                  <div className="notification-main">{incomeNote}</div>
                  : null
                }

              </div>
            </div>
          </form>
          <div className="monitoring">{monitoring}</div>

          {this.state.isDirectLink ?
            <div className="about">
              <h2>Обмен {this.state.incomePaymentSystemName} на {this.state.outcomePaymentSystemName}</h2>
              <p>Сервис WMSIM предоставляет возможность для пользователей из разных стран выгодно обменять {this.state.incomePaymentSystemName} на {this.state.outcomePaymentSystemName} при высочайшем уровне надежности, безопасности и высоких резервах.</p>
              <p>С момента запуска мы обработали более 5 000 000 платежей, завоевали доверие многочисленных пользователей качественным сервисом и внимательной поддержкой. Мы делаем пополнение, вывод и обмен электронных валют и криптовалют удобнее и быстрее. С нами купить, продать или обменять электронные деньги стало намного проще!</p>
              <p><NavLink to="/about-us">Подробнее</NavLink></p>
            </div>
            :
            <div className="about">
              <h2>О платформе WMSIM</h2>
              <p>Сервис WMSIM является <a href="https://passport.webmoney.ru/asp/certview.asp?wmid=394929061825" target="_blank" rel="nofollow noopener noreferrer">аттестованным участником</a> системы WebMoney Transfer с 2007 года и предоставляет возможность для пользователей из разных стран выгодно купить, продать или обменять электронные валюты и криптовалюты при высочайшем уровне надежности, безопасности и высоких резервах.</p>
              <p>С момента запуска мы обработали более 5 000 000 платежей, завоевали доверие многочисленных пользователей качественным сервисом и внимательной поддержкой. Мы делаем пополнение, вывод и обмен электронных валют и криптовалют удобнее и быстрее. С нами купить, продать или обменять электронные деньги стало намного проще!</p>
              <p><NavLink to="/about-us">Подробнее</NavLink></p>
            </div>
          }
        </div>
        <Order incomeClass={incomeClass}
          outcomeClass={outcomeClass}
          orderHash={this.state.orderHash}
          orderEmail={this.state.exchangeEmail}
          incomeAmount={this.state.incomeAmount}
          outcomeAmount={this.state.outcomeAmount}
          incomeAmountCurrency={this.state.incomeAmountCurrency}
          outcomeAmountCurrency={this.state.outcomeAmountCurrency}
          incomeAccount={this.state.incomeAccount}
          outcomeAccount={this.state.outcomeAccount}
          incomeFname={this.state.incomeFname}
          incomeIname={this.state.incomeIname}
          incomeOname={this.state.incomeOname}
          outcomeFname={this.state.outcomeFname}
          outcomeIname={this.state.outcomeIname}
          outcomeOname={this.state.outcomeOname}
          incomeAccountLabel={this.state.incomeAccountLabel}
          outcomeAccountLabel={this.state.outcomeAccountLabel}
          incomeFnameLabel={this.state.incomeFnameLabel}
          incomeInameLabel={this.state.incomeInameLabel}
          incomeOnameLabel={this.state.incomeOnameLabel}
          outcomeFnameLabel={this.state.outcomeFnameLabel}
          outcomeInameLabel={this.state.outcomeInameLabel}
          outcomeOnameLabel={this.state.outcomeOnameLabel}
          incomeDirection={incomePaymentSystem}
          outcomeDirection={outcomePaymentSystem}
          manualConfirm={manualConfirm}
          incomeLetterCode={incomeLetterCode}
          isOpened={this.state.isOpened} changeModal={() => this.changeModal(this)} />
      </div>
    );
  }
}

class Reserves extends Component {
  constructor(props) {
    super(props);
    this.state = { reserveChange: '' };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.reserve !== this.props.reserve) {
      if (+prevProps.reserve < +this.props.reserve) {
        this.setState({ reserveChange: ' exchange-system-reserve-change' });
      } else {
        this.setState({ reserveChange: ' exchange-system-limit-change' });
      }
      this.timerReserve = setTimeout(() => this.setState({ reserveChange: '' }), 1000);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timerReserve);
  }

  render() {
    return (
      <span className={"exchange-system-reserve-amount" + this.state.reserveChange}>{this.props.reserve}</span>
    );
  }
}

export default Main;