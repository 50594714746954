import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Captcha from '../captcha';
import Tooltip from '../tooltip';
import { Email, Message } from '../components/constants';

class Reviews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      isFetching: true,
      currentItem: 0,
      totalLoading: 0,
      totalCount: 0,
      isDisabled: false,
      isSubmitted: {},
      showToolTip: [],
      showDanger: [],
      reviewEmail: '',
      reviewMessage: '',
      isOpened: false,
      isCheckedOne: false,
      isCheckedTwo: false,
      isCheckedThree: false,
      isCheckedFour: false,
      isCheckedFive: true,
      showNotice: false,
      notification: [],
      pattern: [["reviewEmail", false], ["reviewMessage", false]]
    };
    this.openToolTip = this.openToolTip.bind(this);
    this.closeToolTip = this.closeToolTip.bind(this);
    this.resetInputData = this.resetInputData.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  loadingData() {
    if (this.state.isFetching) {
      fetch('/api/reviews', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 'limit': 10, 'item': this.state.currentItem }),
      })
        .then(response => { this.setState({ totalCount: response.headers.get('total-count') }); return response.json() })
        .then(result => {
          if (this.state.data.length > 0) {
            this.setState({ ...this.state.data, ...this.state.data[0].reviews.push(...result.data[0].reviews), loading: false, totalLoading: this.state.totalLoading + result.data[0].reviews.length });
          } else {
            this.setState({ data: result.data, loading: false, totalLoading: result.data[0].reviews.length });
          }
        })
        .finally(() => this.setState({ isFetching: false }));


    }
  }

  scrollHadler = (e) => {
    if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) <= 240 && this.state.totalLoading < this.state.totalCount && !this.state.isFetching) {
      this.setState({ isFetching: true, currentItem: this.state.currentItem + 10 });
      this.loadingData();
    }
  }

  componentDidMount() {
    let title = 'Отзывы пользователей | ' + process.env.REACT_APP_NAME;
    let description = 'Узнайте мнение реальных пользователей о платформе WMSIM. Читайте, пишите и делитесь отзывами.';
    document.title = title;
    document.querySelector("meta[name='description']").setAttribute('content', description);
    document.querySelector("meta[property='og:title']").setAttribute('content', title);
    document.querySelector("meta[property='og:description']").setAttribute('content', description);
    document.querySelector("meta[property='og:url']").setAttribute('content', window.location.href);
    document.querySelector("link[rel='canonical']").setAttribute('href', window.location.href);
    this.loadingData();
    this.resetInputData();
    document.addEventListener('scroll', this.scrollHadler);
  }

  componentDidUpdate() {
    let urlElements = window.location.href.split('/');
    let title = urlElements[3] === "reviews#add" ? 'Добавить отзыв | ' + process.env.REACT_APP_NAME : 'Отзывы пользователей | ' + process.env.REACT_APP_NAME;
    document.title = title;
    document.querySelector("meta[property='og:title']").setAttribute('content', title);
    document.querySelector("meta[property='og:url']").setAttribute('content', window.location.href);
    document.querySelector("link[rel='canonical']").setAttribute('href', window.location.href);
    if (this.state.isOpened) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.removeAttribute("style");
    }
    if (this.state.showNotice) {
      this.timer = setTimeout(() => this.setState({ showNotice: false }), 5000);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.scrollHadler);
    clearTimeout(this.timer);
  }

  resetInputData() {
    document.querySelectorAll('input,textarea').forEach(function (item) {
      item.value = "";
    });
    this.closeDanger();
    this.setState({ reviewEmail: '', reviewMessage: '', isCheckedOne: false, isCheckedTwo: false, isCheckedThree: false, isCheckedFour: false, isCheckedFive: true });
  }

  changeModal = () => {
    this.setState({ isOpened: !this.state.isOpened });
  }

  openToolTip(i) {
    const openStatus = this.state.showToolTip.slice();
    openStatus[i] = true;
    this.setState({ showToolTip: openStatus });
  }

  closeToolTip(i) {
    if (i === undefined) {
      this.setState({ showToolTip: [] });
    } else {
      const closeStatus = this.state.showToolTip.slice();
      closeStatus[i] = false;
      this.setState({ showToolTip: closeStatus });
    }
  }

  openDanger(i) {
    const openStatus = this.state.showDanger;
    openStatus[i] = true;
    this.setState({ showDanger: openStatus });
  }

  closeDanger(i) {
    if (i === undefined) {
      this.setState({ showDanger: [] });
    } else {
      const closeStatus = this.state.showDanger;
      closeStatus[i] = false;
      this.setState({ showDanger: closeStatus });
    }
  }

  changeToggle(i) {
    if (i === 1) {
      this.setState({ isCheckedOne: true, isCheckedTwo: false, isCheckedThree: false, isCheckedFour: false, isCheckedFive: false });
    } else if (i === 2) {
      this.setState({ isCheckedOne: false, isCheckedTwo: true, isCheckedThree: false, isCheckedFour: false, isCheckedFive: false });
    } else if (i === 3) {
      this.setState({ isCheckedOne: false, isCheckedTwo: false, isCheckedThree: true, isCheckedFour: false, isCheckedFive: false });
    } else if (i === 4) {
      this.setState({ isCheckedOne: false, isCheckedTwo: false, isCheckedThree: false, isCheckedFour: true, isCheckedFive: false });
    } else if (i === 5) {
      this.setState({ isCheckedOne: false, isCheckedTwo: false, isCheckedThree: false, isCheckedFour: false, isCheckedFive: true });
    }
  }

  clickForm = a => event => {
    const pattern = new RegExp(a);
    if (event.target.value !== '' || (event.target.value === '' && this.state.showDanger[event.target.name] === true)) {
      if (!pattern.test(event.target.value)) {
        this.openDanger(event.target.name);
        this.openToolTip(event.target.name);
      } else {
        this.closeDanger(event.target.name);
        this.closeToolTip(event.target.name);
      }
    }
  }

  blurForm = a => event => {
    this.closeToolTip();
    const pattern = new RegExp(a);
    if (!pattern.test(event.target.value)) {
      this.openDanger(event.target.name);
    }
  }

  changeForm = a => event => {
    if (event.target.name === 'reviewEmail') {
      event.target.value = event.target.value.toLowerCase().replace(/\s/g, '');
    }
    if (event.target.name === 'reviewMessage') {
      event.target.value = event.target.value.replace(/^./, u => u.toUpperCase());
    }
    this.setState({ [event.target.name]: event.target.value })
    const pattern = new RegExp(a);
    if (event.target.value !== '') {
      if (pattern.test(event.target.value)) {
        this.closeDanger(event.target.name);
        this.closeToolTip(event.target.name);
      }
    } else {
      this.closeToolTip(event.target.name);
      this.closeDanger(event.target.name);
    }
  }

  submitCaptcha() {
    this.setState({ isDisabled: true, isSubmitted: { submitForm: true } });
    fetch('/api/rating', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 'reviewEmail': this.state.reviewEmail, 'reviewMessage': this.state.reviewMessage, 'reviewRating': this.state.isCheckedFive ? '5' : this.state.isCheckedFour ? '4' : this.state.isCheckedThree ? '3' : this.state.isCheckedTwo ? '2' : '1' }),
    })
      .then(response => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ notification: 'Отзыв успешно отправлен.', showNotice: true, isDisabled: false, isSubmitted: {} });
          this.resetInputData();
        }
        this.setState({ isDisabled: false, isSubmitted: {} });
      })
  }

  submitForm(e) {
    e.preventDefault();
    if (this.state.reviewEmail === '') {
      this.openDanger('reviewEmail');
      document.getElementById('reviewEmail').focus();
    }
    if (this.state.reviewMessage === '') {
      this.openDanger('reviewMessage');
      document.getElementById('reviewMessage').focus();
    }

    var sortDanger = this.state.pattern.map(pattern => Object.entries(this.state.showDanger).find(item => item[0] === pattern[0])).filter(item => item !== undefined);
    var trueProps = sortDanger.filter(([key, value]) => value === true);

    if (trueProps.length > 0) {
      document.getElementById(trueProps[0][0]).focus();
      this.openToolTip(trueProps[0][0]);
    } else if (!this.state.isOpened) {
      this.setState({ isOpened: true });
    }
  }

  render() {
    let urlElements = window.location.href.split('/');
    var contentTitle;
    if (urlElements[3] === "reviews#add") {
      contentTitle = 'Добавить отзыв';
    } else {
      contentTitle = 'Отзывы пользователей';
    }
    const reviews = this.state.data.map(item => item.reviews.sort((a, b) => b.id - a.id).map((item, index) => {
      return (
        <div key={index}>
          <div className="reviews-date-block">
            <span className="icon-date icon-default"></span>
            <span className="reviews-date">{new Intl.DateTimeFormat('ru', { year: 'numeric', month: 'long', day: '2-digit' }).format(item.timestamp + '000').slice(0, -3)} | {item.country}</span>
            <div className="reviews-rating">
              <span className="icon-review icon-small"></span>
              <span className={(item.rating >= 2 ? "icon-review" : "icon-reviews") + " icon-small"}></span>
              <span className={(item.rating >= 3 ? "icon-review" : "icon-reviews") + " icon-small"}></span>
              <span className={(item.rating >= 4 ? "icon-review" : "icon-reviews") + " icon-small"}></span>
              <span className={(item.rating >= 5 ? "icon-review" : "icon-reviews") + " icon-small"}></span>
            </div>
          </div>
          <div className="review-text"><i>{item.review}</i>
            {item.response ?
              <div>
                <span><br />{process.env.REACT_APP_NAME} ответил:<br /><i>{item.response}</i></span>
              </div>
              :
              null
            }
          </div>
        </div>
      )
    }));
    var reviewMail = <div>
      <form autoComplete="off" onSubmit={this.submitForm}>
        <div className="field">
          <span className="review-rating">Оценка</span>
          <label className="rating-content">
            <input type="radio" className="checkbox" id="reviewRating-1" name="reviewRating" value="1" checked={this.state.isCheckedOne} onChange={() => this.changeToggle(1)} onBlur={this.blurForm()} />
            <span className={(this.state.isCheckedOne || this.state.isCheckedTwo || this.state.isCheckedThree || this.state.isCheckedFour || this.state.isCheckedFive ? "icon-review" : "icon-reviews") + " icon-medium"}></span>
          </label>
          <label className="rating-content">
            <input type="radio" className="checkbox" id="reviewRating-2" name="reviewRating" value="2" checked={this.state.isCheckedTwo} onChange={() => this.changeToggle(2)} onBlur={this.blurForm()} />
            <span className={(this.state.isCheckedTwo || this.state.isCheckedThree || this.state.isCheckedFour || this.state.isCheckedFive ? "icon-review" : "icon-reviews") + " icon-medium"}></span>
          </label>
          <label className="rating-content">
            <input type="radio" className="checkbox" id="reviewRating-3" name="reviewRating" value="3" checked={this.state.isCheckedThree} onChange={() => this.changeToggle(3)} onBlur={this.blurForm()} />
            <span className={(this.state.isCheckedThree || this.state.isCheckedFour || this.state.isCheckedFive ? "icon-review" : "icon-reviews") + " icon-medium"}></span>
          </label>
          <label className="rating-content">
            <input type="radio" className="checkbox" id="reviewRating-4" name="reviewRating" value="4" checked={this.state.isCheckedFour} onChange={() => this.changeToggle(4)} onBlur={this.blurForm()} />
            <span className={(this.state.isCheckedFour || this.state.isCheckedFive ? "icon-review" : "icon-reviews") + " icon-medium"}></span>
          </label>
          <label className="rating-content">
            <input type="radio" className="checkbox" id="reviewRating-5" name="reviewRating" value="5" checked={this.state.isCheckedFive} onChange={() => this.changeToggle(5)} onBlur={this.blurForm()} />
            <span className={(this.state.isCheckedFive ? "icon-review" : "icon-reviews") + " icon-medium"}></span>
          </label>
        </div>
        <div className="field">
          <input type="text" className={"input" + (this.state.showDanger['reviewEmail'] ? " input-danger" : "")} id="reviewEmail" name="reviewEmail" placeholder="E-mail" autoComplete="off" onClick={this.clickForm(Email)} onBlur={this.blurForm(Email)} onChange={this.changeForm(Email)} />
          <div className="field-icon"><span className="icon-email icon-medium"></span></div>
          <Tooltip status={this.state.showToolTip['reviewEmail']} closeToolTip={() => this.closeToolTip('reviewEmail')} text="Введите корректный E-mail." />
        </div>
        <div className="field">
          <textarea className={"input" + (this.state.showDanger['reviewMessage'] ? " input-danger" : "")} id="reviewMessage" name="reviewMessage" placeholder="Отзыв: не менее 15 символов." autoComplete="off" onClick={this.clickForm(Message)} onBlur={this.blurForm(Message)} onChange={this.changeForm(Message)} />
          <Tooltip status={this.state.showToolTip['reviewMessage']} closeToolTip={() => this.closeToolTip('reviewMessage')} text="Введите Ваш отзыв от 15 до 500 символов." />
        </div>
        <button className={"button" + (this.state.isSubmitted['submitForm'] ? " button-loading" : "")} disabled={this.state.isDisabled} type="submit" onBlur={this.blurForm()}>Отправить</button>
      </form>
    </div>;
    return (
      <div className="page">
        <div className="page-content">
          <div className={"notice fade" + (this.state.showNotice ? " in" : "")}>{this.state.notification}</div>
          <div className="default">
            <div className="default-column">
              <div className="default-menu">
                <div>
                  <NavLink to="/reviews" className="default-nav-item" activeClassName={urlElements[3] === "reviews" ? "default-nav-item-active" : null}>Обзор отзывов</NavLink>
                  <NavLink to="#add" onClick={this.resetInputData} className="default-nav-item" activeClassName={urlElements[3] === "reviews#add" ? "default-nav-item-active" : null}>Добавить отзыв</NavLink>
                </div>
              </div>
            </div>
            <div className="default-column">
              <div className="default-content">
                <h1>{contentTitle}</h1>
                <div className="icon-reviews icon-main"></div>
              </div>
              <div className="default-submenu" >
                <NavLink to="/reviews" className="user-nav-item" activeClassName={urlElements[3] === "reviews" ? "user-nav-item-active" : null}>Обзор отзывов</NavLink>
                <NavLink to="#add" onClick={this.resetInputData} className="user-nav-item" activeClassName={urlElements[3] === "reviews#add" ? "user-nav-item-active" : null}>Добавить отзыв</NavLink>
              </div>
              {urlElements[3] === "reviews#add" ?
                <div>
                  {reviewMail}
                  <h2>Разместить отзыв на мониторингах</h2>
                  <div className="monitorings">
                    <span><a href="https://www.trustpilot.com/review/www.wmsim.com" target="_blank" rel="noopener noreferrer">Trustpilot</a></span>
                    <span><a href="https://advisor.wmtransfer.com/sitedetails.aspx?url=wmsim.ru" target="_blank" rel="noopener noreferrer">Advisor</a></span>
                    <span><a href="https://www.bestchange.ru/wmsim-exchanger.html" target="_blank" rel="noopener noreferrer">Bestchange</a></span>
                    <span><a href="https://mmgp.com/threads/wmsim-ru-mgnovennyj-vyvod-i-vvod-webmoney.626245/" target="_blank" rel="noopener noreferrer">MMGP</a></span>
                    <span><a href="https://superobmen.org/monitoring-obmennikov/?details=252" target="_blank" rel="noopener noreferrer">Superobmen</a></span>
                    <span><a href="https://www.okchanger.ru/exchangers/wmsim" target="_blank" rel="noopener noreferrer">OKchanger</a></span>
                    <span><a href="https://bestexchangers.ru/ru/detail.html?xobmen=243" target="_blank" rel="noopener noreferrer">Bestexchangers</a></span>
                    <span><a href="https://e-mon.ru/exchanger/17" target="_blank" rel="noopener noreferrer">E-mon</a></span>
                    <span><a href="https://pro-obmen.ru/wmsim" target="_blank" rel="noopener noreferrer">Pro-obmen</a></span>
                    <span><a href="https://kurses.com.ua/exchangers/wmsim.ru" target="_blank" rel="noopener noreferrer">Kurses</a></span>
                    <span><a href="https://exchangesumo.com/exchanger/537/WMSiM/" target="_blank" rel="noopener noreferrer">Exchangesumo</a></span>
                    <span><a href="https://bestcurs.org/monitoring-obmennikov/?exch_vote=206" target="_blank" rel="noopener noreferrer">Bestcurs</a></span>
                    <span><a href="https://exchangetop.com/exchange/wmsim" target="_blank" rel="noopener noreferrer">Exchangetop</a></span>
                    <span><a href="https://x-monitor.ru/obmenniki/1681_wmsim.html" target="_blank" rel="noopener noreferrer">X-monitor</a></span>
                    <span><a href="https://irecommend.ru/content/httpwwwwmsimru" target="_blank" rel="noopener noreferrer">Irecommend</a></span>
                    <span><a href="https://otzyvi-vse.ru/wmsim-ru" target="_blank" rel="noopener noreferrer">Otzyvi-vse</a></span>
                    <span><a href="https://sites.reviews/otzyvy/site/wmsim.ru/?id=36799" target="_blank" rel="noopener noreferrer">Sites.reviews</a></span>
                    <span><a href="https://www.mywot.com/scorecard/wmsim.com" target="_blank" rel="noopener noreferrer">Mywot</a></span>
                  </div>
                </div>
                :
                <div>
                  {this.state.loading ?
                    <div className="data-loading"></div>
                    :
                    <div>
                      {reviews}
                      {this.state.totalLoading < this.state.totalCount ?
                        <div className="data-loading next"></div>
                        :
                        null}
                    </div>
                  }
                </div>
              }
            </div>
          </div>
        </div>
        {this.state.isOpened ? <Captcha isOpened={this.state.isOpened} submitCaptcha={() => this.submitCaptcha(this)} changeModal={() => this.changeModal(this)} /> : null}
      </div>
    );
  }
}

export default Reviews;