import React, { Component, Suspense } from 'react';
import { NavLink } from 'react-router-dom';
const BonusLazy = React.lazy(() => import('./lazy/bonus'));
const DiamondsLazy = React.lazy(() => import('./lazy/diamonds'));
const RewardsLazy = React.lazy(() => import('./lazy/rewards'));

class Bonus extends Component {
  componentDidMount() {
    let title = 'Бонусная программа | ' + process.env.REACT_APP_NAME;
    let description = 'Примите участие в Бонусной программы WMSIM и получите лучшие условия на обменные операции с бонусом до 3.00% при высочайшем уровне надежности, безопасности и высоких резервах.';
    document.title = title;
    document.querySelector("meta[name='description']").setAttribute('content', description);
    document.querySelector("meta[property='og:title']").setAttribute('content', title);
    document.querySelector("meta[property='og:description']").setAttribute('content', description);
    document.querySelector("meta[property='og:url']").setAttribute('content', window.location.href);
    document.querySelector("link[rel='canonical']").setAttribute('href', window.location.href);
  }

  componentDidUpdate() {
    let urlElements = window.location.href.split('/');
    let title = urlElements[3] === "bonus#diamonds" ? 'Ежедневные Бриллианты | ' + process.env.REACT_APP_NAME : urlElements[3] === "bonus#rewards" ? 'Вознаграждения | ' + process.env.REACT_APP_NAME : 'Бонусная программа | ' + process.env.REACT_APP_NAME;
    document.title = title;
    document.querySelector("meta[property='og:title']").setAttribute('content', title);
    document.querySelector("meta[property='og:url']").setAttribute('content', window.location.href);
    document.querySelector("link[rel='canonical']").setAttribute('href', window.location.href);
  }

  render() {
    let urlElements = window.location.href.split('/');
    var contentTitle;
    if (urlElements[3] === "bonus#diamonds") {
      contentTitle = 'Ежедневные Бриллианты';
    } else if (urlElements[3] === "bonus#rewards") {
      contentTitle = 'Вознаграждения';
    } else {
      contentTitle = 'Бонусная программа';
    }
    return (
      <div className="page">
        <div className="page-content">
          <div className="default">
            <div className="default-column">
              <div className="default-menu">
                <div>
                  <NavLink to="/bonus" className="default-nav-item" activeClassName={urlElements[3] === "bonus" ? "default-nav-item-active" : null}>Бонусная программа</NavLink>
                  <NavLink to="#diamonds" className="default-nav-item" activeClassName={urlElements[3] === "bonus#diamonds" ? "default-nav-item-active" : null}>Ежедневные Бриллианты</NavLink>
                  <NavLink to="#rewards" className="default-nav-item" activeClassName={urlElements[3] === "bonus#rewards" ? "default-nav-item-active" : null}>Вознаграждения</NavLink>
                </div>
              </div>
            </div>
            <div className="default-column">
              <div className="default-content">
                <h1>{contentTitle}</h1>
                <div className="icon-discount icon-main"></div>
              </div>
              <div className="default-submenu" >
                <NavLink to="/bonus" className="user-nav-item" activeClassName={urlElements[3] === "bonus" ? "user-nav-item-active" : null}>Бонусная программа</NavLink>
                <NavLink to="#diamonds" className="user-nav-item" activeClassName={urlElements[3] === "bonus#diamonds" ? "user-nav-item-active" : null}>Ежедневные Бриллианты</NavLink>
                <NavLink to="#rewards" className="user-nav-item" activeClassName={urlElements[3] === "bonus#rewards" ? "user-nav-item-active" : null}>Вознаграждения</NavLink>
              </div>
              {urlElements[3] === "bonus#diamonds" ?
                <Suspense fallback={<div className="data-loading"></div>}>
                  <DiamondsLazy accessToken={this.props.accessToken} changeModal={() => this.props.changeModal()} />
                </Suspense>
                : urlElements[3] === "bonus#rewards" ?
                  <Suspense fallback={<div className="data-loading"></div>}>
                    <RewardsLazy accessToken={this.props.accessToken} changeModal={() => this.props.changeModal()} />
                  </Suspense>
                  :
                  <Suspense fallback={<div className="data-loading"></div>}>
                    <BonusLazy />
                  </Suspense>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Bonus;