import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import ScrollToTop from './components/scrolltotop';
import Header from './components/header';
import Footer from './components/footer';
import Main from './pages/main';
import AboutUs from './pages/about-us';
import Bonus from './pages/bonus';
import Reviews from './pages/reviews';
import News from './pages/news';
import Agreement from './pages/agreement';
import Partners from './pages/partners';
import Support from './pages/support';
import Notfound from './pages/notfound';
import Language from './language';
import Signup from './signup';
import Signin from './signin';
import Recovery from './recovery';
import Confirm from './confirm';
import Orders from './pages/profile/orders';
import Cards from './pages/profile/cards';
import Discount from './pages/profile/discount';
import Referrals from './pages/profile/referrals';
import Promotion from './pages/profile/promotion';
import Settings from './pages/profile/settings';
import Test from './pages/test';
import { verifyAccessToken } from './components/constants';

class App extends Component {
  constructor(props) {
    super(props);
    const accessToken = localStorage.getItem('token');
    const query = new URLSearchParams(window.location.search);
    const isOpenedSignin = query.get('login') ? true : false;
    this.state = {
      accessToken: accessToken,
      verifyToken: false,
      isOpenedSignup: false,
      isOpenedSignin: isOpenedSignin,
      isOpenedRecovery: false,
      isOpenedConfirm: false,
      isLogin: false,
      userEmail: ''
    }
  }

  checkToken = async () => {
    const accessToken = localStorage.getItem('token');
    const verifyToken = await verifyAccessToken(accessToken).then(result => { return result; });
    if (verifyToken) {
      this.setState({ accessToken: accessToken, verifyToken: verifyToken });

      this.loadingUser();

    } else {
      localStorage.removeItem('token');
      this.setState({ accessToken: false, verifyToken: verifyToken });
    }
  }

  loadingUser() {
    fetch('/api/profile/settings', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.state.accessToken,
      },
      body: JSON.stringify({ 'loading': true }),
    })
      .then(response => response.json())
      .then(result => {
        this.setState({ userEmail: result.data.map(item => item.settings.map(item => item.login)).toString() });
      }, (error) => {
        if (error) {
          localStorage.removeItem('token');
        }
      });
  }

  authenticate() {
    return new Promise(resolve => setTimeout(resolve, 1000))
  }

  componentDidMount() {
    this.checkToken();
    if (this.state.accessToken) {
      this.loadingUser();
    }
    window.addEventListener("storage", () =>
      localStorage.getItem('token') !== this.state.accessToken ? this.checkToken() : null
    );

    this.authenticate().then(() => {
      const ele = document.getElementById('preloader');
      const status = document.getElementById('status');
      if (ele) {
        if (localStorage.getItem('site-break') === "true") {
          status.innerHTML = 'Обновление...<br />Пожалуйста, зайдите через несколько минут.';
        } else {
          ele.classList.add('loaded_hiding');
          window.setTimeout(() => {
            ele.classList.add('loaded');
            ele.classList.remove('loaded_hiding');
          }, 100);
          setTimeout(() => {
            ele.outerHTML = ''
          }, 500)
        }
      }
    })

    document.getElementById('language') && document.getElementById('language').addEventListener('click', () => this.changeModalLanguage(this));
    document.getElementById('mobile-language') && document.getElementById('mobile-language').addEventListener('click', () => this.changeModalLanguage(this));
    document.getElementById('fixed-chat') && document.getElementById('fixed-chat').addEventListener('click', () => document.getElementById('jivo_custom_widget').click());

    const query = new URLSearchParams(window.location.search);
    if ((query.get('email-confirm') !== null && query.get('email-confirm').length > 64) || (query.get('recovery-confirm') !== null && query.get('recovery-confirm').length > 64)) {
      this.changeModalConfirm(this);
    }
  }

  componentDidUpdate() {
    if (this.state.isOpenedLanguage || this.state.isOpenedSignin || this.state.isOpenedSignup || this.state.isOpenedRecovery || this.state.isOpenedConfirm) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.removeAttribute("style");
    }
    document.getElementById('signup') && document.getElementById('signup').addEventListener('click', () => this.changeModalSignup(this));
    document.getElementById('signin') && document.getElementById('signin').addEventListener('click', () => this.changeModalSignin(this));
    document.getElementById('recovery') && document.getElementById('recovery').addEventListener('click', () => this.changeModalRecovery(this));
    document.getElementById('recovery-signup') && document.getElementById('recovery-signup').addEventListener('click', () => this.changeModalSignup(this));
    document.getElementById('recovery-signin') && document.getElementById('recovery-signin').addEventListener('click', () => this.changeModalSignin(this));
  }

  changeModalLanguage = () => {
    this.setState({ isOpenedLanguage: !this.state.isOpenedLanguage, isOpenedSignup: false, isOpenedSignin: false, isOpenedRecovery: false });
  }

  changeModalSignup = () => {
    this.setState({ isOpenedSignup: !this.state.isOpenedSignup, isOpenedSignin: false, isOpenedRecovery: false, isOpenedLanguage: false });
  }

  changeModalSignin = () => {
    this.setState({ isOpenedSignin: !this.state.isOpenedSignin, isOpenedSignup: false, isOpenedRecovery: false, isOpenedLanguage: false });
  }

  changeModalRecovery = () => {
    this.setState({ isOpenedRecovery: !this.state.isOpenedRecovery, isOpenedSignin: false, isOpenedSignup: false, isOpenedLanguage: false });
  }

  changeModalConfirm = () => {
    this.setState({ isOpenedConfirm: !this.state.isOpenedConfirm });
  }

  render() {
    return (
      <BrowserRouter>
        <div className="app">
          <Header accessToken={this.state.accessToken} userEmail={this.state.userEmail} changeModal={() => this.changeModalSignin(this)} />
          <ScrollToTop />
          <Switch>
            <Route exact path="/" render={(props) => (<Main accessToken={this.state.accessToken} />)} />
            <Route path="/bonus" render={(props) => (<Bonus accessToken={this.state.accessToken} changeModal={() => this.changeModalSignin(this)} />)} />
            <Route path="/about-us" component={AboutUs} />
            <Route path="/reviews" component={Reviews} />
            <Route path="/news" render={(props) => (<News verifyToken={this.state.verifyToken} />)} />
            <Route path="/agreement" component={Agreement} />
            <Route path="/partners" render={(props) => (<Partners accessToken={this.state.accessToken} verifyToken={this.state.verifyToken} changeModal={() => this.changeModalSignin(this)} />)} />
            <Route path="/support" component={Support} />
            <Route path="/profile/orders" render={(props) => (<Orders accessToken={this.state.accessToken} />)} />
            <Route path="/profile/cards" render={(props) => (<Cards accessToken={this.state.accessToken} verifyToken={this.state.verifyToken} />)} />
            <Route path="/profile/discount" render={(props) => (<Discount accessToken={this.state.accessToken} />)} />
            <Route path="/profile/referrals" render={(props) => (<Referrals accessToken={this.state.accessToken} verifyToken={this.state.verifyToken} />)} />
            <Route path="/profile/promotion" render={(props) => (<Promotion accessToken={this.state.accessToken} verifyToken={this.state.verifyToken} />)} />
            <Route path="/profile/settings" render={(props) => (<Settings accessToken={this.state.accessToken} />)} />
            <Route path="/test" component={Test} />
            <Route path="*" component={Notfound} />
          </Switch>
          <Footer accessToken={this.state.accessToken} changeModal={() => this.changeModalSignin(this)} />
          {this.state.isOpenedLanguage ? <Language isOpened={this.state.isOpenedLanguage} changeModal={() => this.changeModalLanguage(this)} /> : null}
          {this.state.isOpenedSignup ? <Signup isOpened={this.state.isOpenedSignup} changeModal={() => this.changeModalSignup(this)} /> : null}
          {this.state.isOpenedSignin ? <Signin isOpened={this.state.isOpenedSignin} changeModal={() => this.changeModalSignin(this)} /> : null}
          {this.state.isOpenedRecovery ? <Recovery isOpened={this.state.isOpenedRecovery} changeModal={() => this.changeModalRecovery(this)} /> : null}
          {this.state.isOpenedConfirm ? <Confirm location={window.location} isOpened={this.state.isOpenedConfirm} changeModal={() => this.changeModalConfirm(this)} /> : null}
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
