import React, { Component } from 'react';

class Test extends Component {
  

  
  render() {

    const downloadFile = (
      filePath,
      fileName = 'Example-PDF-file.pdf',
    ) => {
      fetch('https://www.wmsim.com/media/promo/' + filePath, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
        },
      })
        .then(response => response.blob())
        .then(blob => {
          const url = window.URL.createObjectURL(new Blob([blob]));
  
          const link = document.createElement('a');
          link.href = url;
          link.download = fileName;
  
          document.body.appendChild(link);
  
          link.click();
  
          link.parentNode.removeChild(link);
        });
    };



    return (
      <div className="page">

<button
        onClick={() => downloadFile('wmsim728x90.png')}
      >
        Download file
      </button>
      </div>
    );
  }
}

export default Test;