import React, { Component } from 'react';

class Tooltip extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = { visible: false, fadein: false };
  }

  componentDidUpdate(prevProps) {
    this._isMounted = true;
    if (this.props.status !== prevProps.status) {
      if (this.props.status === true) {
        this.timerFadein = setTimeout(() => this._isMounted && this.setState({ fadein: true }), 100);
        this.timerVisible = setTimeout(() => this._isMounted && this.setState({ visible: true }), 50);
      } else {
        this.timerFadein = setTimeout(() => this._isMounted && this.setState({ fadein: false }), 100);
        this.timerVisible = setTimeout(() => this._isMounted && this.setState({ visible: false }), 50);
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this.timerFadein, this.timerVisible);
  }

  render() {
    return (
      this.state.visible && (
        <div className={"fade" + (this.state.fadein ? ' in' : '') + " tooltip tooltip-danger"}>
          <div className="tooltip-arrow"></div>
          <button type="button" className="tooltip-cross" onClick={() => this.props.closeToolTip()}><span className="icon-cross"></span></button>
          <div className="tooltip-inner">
            <div className="text">{this.props.text}</div>
          </div>
        </div>
      )
    );
  }
}

export default Tooltip;