import React, { Component } from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import '../../Card.css';
import Tooltip from '../../tooltip';
import Card from '../../card';
import { AccountMenu } from '../../components/account_menu';
import { CardNumber, CardDate, CardHolder, CardPhoto } from '../../components/constants';

class Cards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      cardType: '',
      cardIcon: '',
      cardNumber: '',
      cardDate: '',
      cardHolder: '',
      cardPhoto: '',
      cardIdDelete: '',
      cardNumberDelete: '',
      cardHolderDelete: '',
      cardIconDelete: '',
      cardTypeDelete: '',
      cardDateDelete: '',
      cardStatusDelete: '',
      showNotice: false,
      isOpened: false,
      isSubmitted: {},
      isDisabled: false,
      isRedirected: false,
      notification: '',
      showToolTip: [],
      showDanger: [],
      pattern: [["cardNumber", false], ["cardDate", false], ["cardHolder", false], ["cardPhoto", false]],
      isOpenedMenu: false
    }
    this.openToolTip = this.openToolTip.bind(this);
    this.closeToolTip = this.closeToolTip.bind(this);
    this.resetInputData = this.resetInputData.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.deleteCardConfirm = this.deleteCardConfirm.bind(this);
    this.fileInput = React.createRef();
    this.accountSection = React.createRef();
    this.menuSection = React.createRef();
    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  loadingData() {
    fetch('/api/profile/cards', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.accessToken,
      },
      body: JSON.stringify({ 'loading': true }),
    })
      .then(response => response.json())
      .then((result) => {
        this.setState({ data: result.data, loading: false });
      }, (error) => {
        if (error) {
          localStorage.removeItem('token');
          window.location.href = '/?login=true';
        }
      });
  }

  componentDidMount() {
    if (!this.props.accessToken) {
      window.location.href = '/?login=true';
    }
    this.resetInputData();
    this.loadingData();
    document.title = 'Банковские карты | ' + process.env.REACT_APP_NAME;
    document.addEventListener('mousedown', this.closeMenu);
  }

  componentDidUpdate() {
    if (!this.props.accessToken) {
      window.location.href = '/?login=true';
    }
    if (this.state.showNotice) {
      this.timer = setTimeout(() => this.setState({ showNotice: false }), 5000);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
    document.removeEventListener('mousedown', this.closeMenu);
  }

  resetInputData() {
    document.querySelectorAll('input').forEach(function (item) {
      item.value = '';
    });
    this.closeDanger();
    this.setState({ isRedirected: false, cardType: '', cardIcon: '', cardNumber: '', cardDate: '', cardHolder: '', cardPhoto: '' });
  }

  openToolTip(i) {
    const openStatus = this.state.showToolTip.slice();
    openStatus[i] = true;
    this.setState({ showToolTip: openStatus });
  }

  closeToolTip(i) {
    if (i === undefined) {
      this.setState({ showToolTip: [] });
    } else {
      const closeStatus = this.state.showToolTip.slice();
      closeStatus[i] = false;
      this.setState({ showToolTip: closeStatus });
    }
  }

  openDanger(i) {
    const openStatus = this.state.showDanger;
    openStatus[i] = true;
    this.setState({ showDanger: openStatus });
  }

  closeDanger(i) {
    if (i === undefined) {
      this.setState({ showDanger: [] });
    } else {
      const closeStatus = this.state.showDanger;
      closeStatus[i] = false;
      this.setState({ showDanger: closeStatus });
    }
  }

  clickForm = a => event => {
    this.closeToolTip(event.target.name);
    const pattern = new RegExp(a);
    if (event.target.value !== '' || (event.target.value === '' && this.state.showDanger[event.target.name] === true)) {
      if (!pattern.test(event.target.value)) {
        this.openDanger(event.target.name);
        this.openToolTip(event.target.name);
      } else {
        this.closeDanger(event.target.name);
        this.closeToolTip(event.target.name);
      }
    }
  }

  blurForm = a => event => {
    let b = event.target.value;
    let c = event.target.name;
    if (event.target.name === undefined) {
      if (this.fileInput.current.files.length) {
        b = this.fileInput.current.files[0].name;
      }
      c = 'cardPhoto';
    }
    this.closeToolTip();
    const pattern = new RegExp(a);
    if (!pattern.test(b)) {
      if ((c === 'cardPhoto' && b !== undefined) || c !== 'cardPhoto') {
        this.openDanger(c);
      }
    }
  }

  changeForm = a => event => {
    if (event.target.name === 'cardNumber') {
      const visa = new RegExp("^4");
      const mastercard = new RegExp("^5[1-5]|^222[1-9]|^22[3-9][0-9]|^2[3-6][0-9][0-9]|^27[01][0-9]|^2720");
      const maestro = new RegExp("^500|^501[0-8]|^50[2-9]|^5[6-9]|^601[02-9]|^60[2-5]|^6060|^616788|^6218[368]|^6219[89]|^6220|^622110|^627[0-9]|^628[01]|^6294|^6301|^630490|^633857|^63609|^6361|^636392|^636708|^637043|^637102|^637118|^637187|^637529|^639|^64[0-3]|^67[0-5]|^677|^676[0-9]|^6771|^679");
      const mir = new RegExp("^220[0-4]");
      var cardtype;
      var cardicon;
      if (event.target.value.match(visa) != null) {
        cardtype = 'VISA';
        cardicon = 'visa';
      } else if (event.target.value.match(mastercard) != null) {
        cardtype = 'MasterCard';
        cardicon = 'mastercard';
      } else if (event.target.value.match(maestro) != null) {
        cardtype = 'Maestro';
        cardicon = 'maestro';
      } else if (event.target.value.match(mir) != null) {
        cardtype = 'МИР';
        cardicon = 'mir2';
      }
      this.setState({ [event.target.name]: event.target.value.replace(/[^\d]*/g, '').replace(/(\d{4})/g, '$1 ').trim(), cardType: cardtype, cardIcon: cardicon });
    }
    if (event.target.name === 'cardDate') {
      this.setState({ [event.target.name]: event.target.value.replace(/[^\d]*/g, '').replace(/^(\d{2})(\d{1,2})$/, '$1/$2') });
    }
    if (event.target.name === 'cardHolder') {
      this.setState({ [event.target.name]: event.target.value.replace(/[^a-zA-Zа-яА-ЯЁё\s]*/g, '').toUpperCase() });
    }
    if (event.target.name === 'cardPhoto') {
      if (event.target.files.length) {
        this.setState({ [event.target.name]: event.target.files[0].name });
      } else {
        this.setState({ [event.target.name]: event.target.value });
      }
    }
    const pattern = new RegExp(a);
    if (event.target.value !== '') {
      if (pattern.test(event.target.value)) {
        this.closeDanger(event.target.name);
        this.closeToolTip(event.target.name);
      }
    } else {
      this.closeToolTip(event.target.name);
      this.closeDanger(event.target.name);
    }
  }

  renderRedirect = () => {
    if (this.state.isRedirected) {
      return <Redirect to='/profile/cards' />
    }
  }

  changeModal = () => {
    this.setState({ isOpened: !this.state.isOpened });
  }

  submitForm(e) {
    e.preventDefault();

    if (this.state.cardNumber === '') {
      this.openDanger('cardNumber');
    }
    if (this.state.cardDate === '') {
      this.openDanger('cardDate');
    }
    if (this.state.cardHolder === '') {
      this.openDanger('cardHolder');
    }
    if (this.state.cardPhoto === '') {
      this.openDanger('cardPhoto');
    }

    var sortDanger = this.state.pattern.map(pattern => Object.entries(this.state.showDanger).find(item => item[0] === pattern[0])).filter(item => item !== undefined);
    var trueProps = sortDanger.filter(([key, value]) => value === true);

    if (trueProps.length > 0) {
      this.openToolTip(trueProps[0][0]);

    } else if ((this.state.cardDate && this.state.cardDate.slice(-2) < new Date().getFullYear().toString().slice(-2)) || (this.state.cardDate && this.state.cardDate.slice(0, 2) > 12) || (this.state.cardDate && this.state.cardDate.slice(-2) === new Date().getFullYear().toString().slice(-2) && this.state.cardDate.slice(0, 2) < ('0' + (new Date().getMonth() + 1)).slice(-2))) {
      this.openDanger('cardDate');
      this.openToolTip('cardDateValid');


    } else {
      this.setState({ isDisabled: true, isSubmitted: { submitForm: true } });
      fetch('/api/profile/cards-add', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.props.accessToken,
        },
        body: JSON.stringify({ 'cardNumber': this.state.cardNumber, 'cardDate': this.state.cardDate, 'cardHolder': this.state.cardHolder, 'cardType': this.state.cardType, 'cardIcon': this.state.cardIcon }),
      })
        .then(response => response.json())
        .then((result) => {
          if (!result.validatecard) {
            this.openToolTip('cardNumber');
            this.openDanger('cardNumber');
          } else if (!result.checkcard) {
            this.openToolTip('cardNumberCheck');
            this.openDanger('cardNumber');
          } else {
            const fileInput = document.querySelector("#cardPhoto");
            const formData = new FormData();
            formData.append("uid", this.props.verifyToken);
            formData.append("cardPhoto", fileInput.files[0]);
            formData.append("cardType", this.state.cardType);
            formData.append("cardNumber", this.state.cardNumber);
            formData.append("cardDate", this.state.cardDate);
            formData.append("cardIcon", this.state.cardIcon);
            fetch("/api/profile/verification", {
              method: "POST",
              headers: {
                'Authorization': 'Bearer ' + this.props.accessToken,
              },
              body: formData
            }, (error) => {
              if (error) {
                localStorage.removeItem('token');
                window.location.href = '/?login=true';
              }
            });
            this.setState({ notification: 'Банковская карта успешно добавлена.', showNotice: true, isRedirected: true });
            this.loadingData();
          }
          this.setState({ isDisabled: false, isSubmitted: {} });
        }, (error) => {
          if (error) {
            localStorage.removeItem('token');
            window.location.href = '/?login=true';
          }
        })
    }
  }

  deleteCardConfirm(cid, type, number, date, holder, status, icon) {
    this.setState({ isOpened: !this.state.isOpened, cardIdDelete: cid, cardTypeDelete: type, cardNumberDelete: number, cardDateDelete: date, cardHolderDelete: holder, cardStatusDelete: status, cardIconDelete: icon });
  }

  deleteCard = () => {
    fetch('/api/profile/cards-delete', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.accessToken,
      },
      body: JSON.stringify({ 'cid': this.state.cardIdDelete, 'cardNumber': this.state.cardNumberDelete, 'cardType': this.state.cardTypeDelete, 'cardIcon': this.state.cardIconDelete, 'cardDate': this.state.cardDateDelete }),
    })
      .then(response => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ isOpened: !this.state.isOpened, notification: 'Банковская карта успешно удалена.', showNotice: true });
          this.loadingData();
        }
      }, (error) => {
        if (error) {
          localStorage.removeItem('token');
          window.location.href = '/?login=true';
        }
      })
  }

  showMenu() {
    this.setState({ isOpenedMenu: !this.state.isOpenedMenu });
  }

  closeMenu(e) {
    if (this.accountSection.current && !this.accountSection.current.contains(e.target)) {
      if (this.state.isOpenedMenu && this.menuSection.current && !this.menuSection.current.contains(e.target)) {
        this.setState({ isOpenedMenu: false });
      }
    }
  }

  render() {
    let urlElements = window.location.href.split('/');
    const count = this.state.data.map(obj => Object.keys(obj.cards).length)[0];
    const cards = this.state.data.map(item => item.cards.sort((a, b) => a.id - b.id).map((item, index) => {
      return (
        <div className="user-card-item" key={index}>
          <div className="card-type">{item.card_type ? item.card_type : 'Card'}</div>
          <div className="card-number">{item.card_number}</div>
          <div className="card-valid"><span className="card-title">VALID<br />THRU</span><span className="card-date">{item.card_date}</span></div>
          <div className="card-holder">{item.card_holder}</div>
          {item.status === "1" ? <div className="field-card-verified-icon"><span className="icon-verified icon-medium" data-tooltip="проверено"></span></div> : null}
          <div className="field-card-bin-icon"><span className="icon-bin icon-medium" data-tooltip="удалить" onClick={() => this.deleteCardConfirm(item.id, item.card_type, item.card_number, item.card_date, item.card_holder, item.status, item.card_icon)}></span></div>
          <div className="field-card-icon"><span className={"icon-" + (item.card_icon ? item.card_icon : "visa-mastercard") + " icon-big"}></span></div>
        </div>
      )
    }));

    return (
      <div className="page">

        {this.renderRedirect()}

        <div className="page-content">
          <div className={"notice fade" + (this.state.showNotice ? " in" : "")}>{this.state.notification}</div>
          <div className="account">
            <div className="account-column">
              <div className="account-section" onClick={this.showMenu} ref={this.accountSection}>
                <span className="account-section-title">Кабинет</span>
                <span className={'icon-arrow icon-default' + (this.state.isOpenedMenu ? ' rotate' : '')}></span>
              </div>
              <div className={'account-menu' + (this.state.isOpenedMenu ? ' open' : '')} ref={this.menuSection}>{AccountMenu}</div>
            </div>
            <div className="account-column">
              <div className="account-content">
                <h1>Банковские карты</h1>
                <div className="icon-cards icon-main"></div>
              </div>
              <div className="user-menu">
                <NavLink to="/profile/cards" onClick={this.resetInputData} className="user-nav-item" activeClassName={urlElements[4] === "cards" ? "user-nav-item-active" : null}>Список карт</NavLink>
                <NavLink to="#add" onClick={this.resetInputData} className="user-nav-item" activeClassName={urlElements[4] === "cards#add" ? "user-nav-item-active" : null}>Добавление новой карты</NavLink>
              </div>
              {urlElements[4] === "cards" ?
                <div>

                  {count !== 0 ?
                    this.state.loading ?
                      <div className="data-loading"></div>
                      :
                      <div className="user-cards">
                        {cards}
                      </div>
                    :
                    <div className="account-description-empty">
                      <div className="field"><span className="icon-empty icon-big"></span></div>
                      <div className="field">У Вас еще нет карт</div>
                      <NavLink to="#add" className="button button-middle">Добавить карту</NavLink>
                    </div>
                  }

                  <div className="note">
                    <p>Добавление и верификация банковской карты необходимы для защиты онлайн-платежей от несанкционированного доступа. Верификация банковской карты позволяет осуществлять быстрые и безопасные платежи с карты.</p>
                    <p><span className="icon-verified icon-medium"></span><span style={{ verticalAlign: 'middle' }}>Верифицированная карта отмечена специальной зеленой иконкой.</span></p>
                  </div>
                </div>
                :
                <div>
                  <div className="user-cards">
                    <div className="user-addcard-item">
                      <form autoComplete="off" onSubmit={this.submitForm}>

                        <div className="field">
                          <input type="text" inputMode="numeric" className={"input" + (this.state.showDanger['cardNumber'] ? " input-danger" : "")} id="cardNumber" name="cardNumber" value={this.state.cardNumber} autoComplete="off" maxLength="19" placeholder="Номер карты, 16 цифр" onClick={this.clickForm(CardNumber)} onBlur={this.blurForm(CardNumber)} onChange={this.changeForm(CardNumber)} />
                          <div className="field-icon"><span className={"icon-" + (this.state.cardIcon ? this.state.cardIcon : "visa-mastercard") + " icon-medium"}></span></div>
                          <Tooltip status={this.state.showToolTip['cardNumber']} closeToolTip={() => this.closeToolTip('cardNumber')} text="Введите корректный Номер карты, 16 цифр." />
                          <Tooltip status={this.state.showToolTip['cardNumberCheck']} closeToolTip={() => this.closeToolTip('cardNumberCheck')} text="Указанная карта уже добавлена." />
                        </div>
                        <div className="field">
                          <input type="text" inputMode="numeric" className={"input" + (this.state.showDanger['cardDate'] ? " input-danger" : "")} id="cardDate" name="cardDate" value={this.state.cardDate} autoComplete="off" maxLength="5" placeholder="Срок действия, ММГГ" onClick={this.clickForm(CardDate)} onBlur={this.blurForm(CardDate)} onChange={this.changeForm(CardDate)} />
                          <div className="field-icon"><span className="icon-date icon-medium"></span></div>
                          <Tooltip status={this.state.showToolTip['cardDate']} closeToolTip={() => this.closeToolTip('cardDate')} text="Введите корректный Срок действия карты, ММГГ." />
                          <Tooltip status={this.state.showToolTip['cardDateValid']} closeToolTip={() => this.closeToolTip('cardDateValid')} text="Введен некорректный срок действия карты." />

                        </div>
                        <div className="field">
                          <input type="text" className={"input" + (this.state.showDanger['cardHolder'] ? " input-danger" : "")} id="cardHolder" name="cardHolder" value={this.state.cardHolder} autoComplete="off" placeholder="Имя и Фамилия владельца" onClick={this.clickForm(CardHolder)} onBlur={this.blurForm(CardHolder)} onChange={this.changeForm(CardHolder)} />
                          <div className="field-icon"><span className="icon-user icon-medium"></span></div>
                          <Tooltip status={this.state.showToolTip['cardHolder']} closeToolTip={() => this.closeToolTip('cardHolder')} text="Введите корректные Имя и Фамилию владельца латинскими буквами." />
                        </div>
                        <div className="field">
                          <input type="file" accept="image/*,image/jpeg" style={{ display: 'none' }} className="input" id="cardPhoto" name="cardPhoto" ref={this.fileInput} autoComplete="off" placeholder="Фото лицевой стороны карты" onBlur={this.blurForm(CardPhoto)} onChange={this.changeForm(CardPhoto)} />
                          <div className="field-icon"><span className="icon-camera icon-medium"></span></div>
                          <Tooltip status={this.state.showToolTip['cardPhoto']} closeToolTip={() => this.closeToolTip('cardPhoto')} text="Загрузите фото лицевой стороны карты в формате jpg, png или gif." />
                          <label htmlFor="cardPhoto">
                            <div tabIndex="1" className={"file-upload" + (this.state.showDanger['cardPhoto'] ? " file-upload-danger" : "") + (!this.state.cardPhoto ? " placeholder" : "")} onBlur={this.blurForm(CardPhoto)}>{this.state.cardPhoto ? this.state.cardPhoto : "Фото лицевой стороны карты"}</div>
                          </label>
                        </div>
                        <button className={"button" + (this.state.isSubmitted['submitForm'] ? " button-loading" : "")} disabled={this.state.isDisabled} type="submit" onBlur={this.blurForm()}>Добавить карту</button>
                      </form>
                    </div>
                    <div className="user-addcard-item">
                      <div className="card-type">{this.state.cardType ? this.state.cardType : "Card"}</div>
                      <div className="card-number">{this.state.cardNumber ? this.state.cardNumber : "1234 5678 9876 5432"}</div>
                      <div className="card-valid"><span className="card-title">VALID<br />THRU</span><span className="card-date">{this.state.cardDate ? this.state.cardDate : "12/99"}</span></div>
                      <div className="card-holder">{this.state.cardHolder ? this.state.cardHolder : "CARDHOLDER NAME"}</div>
                      <div className="field-card-icon"><span className={"icon-" + (this.state.cardIcon ? this.state.cardIcon : "visa-mastercard") + " icon-big"}></span></div>
                    </div>
                  </div>
                  <div className="note">
                    <p>Введите номер принадлежащей Вам банковской карты, дату окончания срока ее действия, имя и фамилию как указано на этой карте. Если банковская карта неименная, введите в поле Имя и Фамилия владельца - NO NAME.</p>
                    <p>Для верификации банковской карты необходимо загрузить цветной скан или фотоснимок ее лицевой стороны. Верификация осуществляется в течение 1-24 часов.</p>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        {this.state.isOpened ? <Card cardNumberDelete={this.state.cardNumberDelete}
          cardDateDelete={this.state.cardDateDelete}
          cardHolderDelete={this.state.cardHolderDelete}
          cardStatusDelete={this.state.cardStatusDelete}
          cardIconDelete={this.state.cardIconDelete}
          cardTypeDelete={this.state.cardTypeDelete}
          isOpened={this.state.isOpened} deleteCard={() => this.deleteCard(this)} changeModal={() => this.changeModal(this)} /> : null}
      </div>
    );
  }
}

export default Cards;