import React, { Component } from 'react';
const captchaString = ["qiwi", "webmoney", "visa-mastercard", "swift", "paypal", "neteller"];

class Captcha extends Component {
  constructor(props) {
    super(props);
    const captchaRandom = captchaString.sort((a, b) => 0.5 - Math.random());
    const captchaControl = captchaString[Math.floor(Math.random() * captchaString.length)];
    this.state = {
      data: captchaRandom,
      captchaControl: captchaControl,
      isOpened: false
    }
  }

  componentDidMount() {
    this.timer = setTimeout(() => this.setState({ isOpened: this.props.isOpened ? true : false }), 1);
  }

  updateCaptcha = () => {
    const captchaRandom = captchaString.sort((a, b) => 0.5 - Math.random());
    const captchaControl = captchaString[Math.floor(Math.random() * captchaString.length)];
    this.setState({ data: captchaRandom, captchaControl: captchaControl });
  }

  render() {
    var captchaItems = this.state.data.map((item, index) => {
      return <div key={index} className="captcha-item">
        <span className={"icon-" + item + " icon-captcha"} onClick={item === this.state.captchaControl ? () => {this.props.changeModal();this.props.submitCaptcha()} : () => this.updateCaptcha()}></span>
      </div>;
    });
    return (
      <div className={"modal-overlay" + (this.props.isOpened ? " open" : " close")}>
        <div className={"modal-captcha" + (this.state.isOpened ? " open" : "")}>
          <div className='modal-close' onClick={() => this.props.changeModal()}><span className="icon-close icon-small"></span></div>
          <div className="modal-title">Вы не робот?</div>
          <div className="modal-note">Нажмите на логотип {this.state.captchaControl.toUpperCase()}</div>
          <div className="captcha">
            {captchaItems}
          </div>
        </div>
      </div>
    );
  };
};

export default Captcha;