import React, { Component, Suspense } from 'react';
import { NavLink } from 'react-router-dom';
const AboutUsLazy = React.lazy(() => import('./lazy/about-us'));
const InstructionLazy = React.lazy(() => import('./lazy/instruction'));
const DirectionsLazy = React.lazy(() => import('./lazy/directions'));

class AboutUs extends Component {
    componentDidMount() {
        let title = 'О платформе | ' + process.env.REACT_APP_NAME;
        let description = 'Платформа WMSIM является аттестованным участником системы WebMoney Transfer с 2007 года и предоставляет возможность для пользователей из разных стран выгодно купить, продать или обменять электронные валюты и криптовалюты.';
        document.title = title;
        document.querySelector("meta[name='description']").setAttribute('content', description);
        document.querySelector("meta[property='og:title']").setAttribute('content', title);
        document.querySelector("meta[property='og:description']").setAttribute('content', description);
        document.querySelector("meta[property='og:url']").setAttribute('content', window.location.href);
        document.querySelector("link[rel='canonical']").setAttribute('href', window.location.href);
    }

    componentDidUpdate() {
        let urlElements = window.location.href.split('/');
        let title = urlElements[3] === "about-us#instruction" ? 'Инструкция по обмену | ' + process.env.REACT_APP_NAME : urlElements[3] === "about-us#directions" ? 'Направления обмена | ' + process.env.REACT_APP_NAME : 'О платформе | ' + process.env.REACT_APP_NAME;
        document.title = title;
        document.querySelector("meta[property='og:title']").setAttribute('content', title);
        document.querySelector("meta[property='og:url']").setAttribute('content', window.location.href);
        document.querySelector("link[rel='canonical']").setAttribute('href', window.location.href);
    }

    render() {
        let urlElements = window.location.href.split('/');
        var contentTitle;
        if (urlElements[3] === "about-us#instruction") {
            contentTitle = 'Инструкция по обмену';
        } else if (urlElements[3] === "about-us#directions") {
            contentTitle = 'Направления обмена';
        } else {
            contentTitle = 'О платформе ' + process.env.REACT_APP_NAME;
        }
        return (
            <div className="page">
                <div className="page-content">
                    <div className="default">
                        <div className="default-column">
                            <div className="default-menu">
                                <div>
                                    <NavLink to="/about-us" className="default-nav-item" activeClassName={urlElements[3] === "about-us" ? "default-nav-item-active" : null}>О платформе {process.env.REACT_APP_NAME}</NavLink>
                                    <NavLink to="#instruction" className="default-nav-item" activeClassName={urlElements[3] === "about-us#instruction" ? "default-nav-item-active" : null}>Инструкция по обмену</NavLink>
                                    <NavLink to="#directions" className="default-nav-item" activeClassName={urlElements[3] === "about-us#directions" ? "default-nav-item-active" : null}>Направления обмена</NavLink>
                                </div>
                            </div>
                        </div>
                        <div className="default-column">
                            <div className="default-content">
                                <h1>{contentTitle}</h1>
                                <div className="icon-about icon-main"></div>
                            </div>
                            <div className="default-submenu" >
                                <NavLink to="/about-us" className="user-nav-item" activeClassName={urlElements[3] === "about-us" ? "user-nav-item-active" : null}>О платформе {process.env.REACT_APP_NAME}</NavLink>
                                <NavLink to="#instruction" className="user-nav-item" activeClassName={urlElements[3] === "about-us#instruction" ? "user-nav-item-active" : null}>Инструкция по обмену</NavLink>
                                <NavLink to="#directions" className="user-nav-item" activeClassName={urlElements[3] === "about-us#directions" ? "user-nav-item-active" : null}>Направления обмена</NavLink>
                            </div>
                            {urlElements[3] === "about-us#instruction" ?
                                <Suspense fallback={<div className="data-loading"></div>}>
                                    <InstructionLazy />
                                </Suspense>
                                :
                                urlElements[3] === "about-us#directions" ?
                                    <Suspense fallback={<div className="data-loading"></div>}>
                                        <DirectionsLazy />
                                    </Suspense>
                                    :
                                    <Suspense fallback={<div className="data-loading"></div>}>
                                        <AboutUsLazy />
                                    </Suspense>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AboutUs;