import React, { Component } from 'react';

class Card extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmitted: {},
            isDisabled: false,
            isOpened: false
        };
        this.buttonClick = this.buttonClick.bind(this);
    }

    componentDidMount() {
        this.timer = setTimeout(() => this.setState({ isOpened: this.props.isOpened ? true : false }), 1);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isOpened !== this.props.isOpened) {
            if (this.props.isOpened) {
                this.setState({ isSubmitted: {}, isDisabled: false });
            }
        }
    }

    buttonClick() {
        this.setState({ isDisabled: true, isSubmitted: { submitForm: true } });
    }

    render() {

        return (
            this.props.isOpened && (
                <div className={"modal-overlay" + (this.props.isOpened ? " open" : " close")}>
                    <div className={"modal-content" + (this.state.isOpened ? " open" : "")}>
                        <div className='modal-close' onClick={() => this.props.changeModal()}><span className="icon-close icon-small"></span></div>
                        <div className="modal-title">Банковская карта</div>
                        <div className="user-card-delete-item">
                            <div className="card-type">{this.props.cardTypeDelete ? this.props.cardTypeDelete : 'Card'}</div>
                            <div className="card-number">{this.props.cardNumberDelete.replace(/(\d{4}) (\d{4}) (\d{4}) (\d{4})/, '$1 **** **** $4')}</div>
                            <div className="card-valid"><span className="card-title">VALID<br />THRU</span><span className="card-date">{this.props.cardDateDelete}</span></div>
                            <div className="card-holder">{this.props.cardHolderDelete}</div>
                            {this.props.cardStatusDelete === "1" ? <div className="field-card-verified-icon"><span className="icon-verified icon-medium"></span></div> : null}
                            <div className="field-card-icon"><span className={"icon-" + (this.props.cardIconDelete ? this.props.cardIconDelete : "visa-mastercard") + " icon-big"}></span></div>
                        </div>
                        <div className="modal-note">Вы действительно хотите удалить данную банковскую карту?</div>
                        <button className={"button button-modal" + (this.state.isSubmitted['submitForm'] ? " button-loading" : "")} disabled={this.state.isDisabled} onClick={() => { this.buttonClick(); this.props.deleteCard(); }}>Удалить</button>
                        <div class="modal-note last"><span id="recovery" onClick={() => this.props.changeModal()}>Отменить</span></div>
                    </div>
                </div>
            )
        )
    }
}

export default Card;