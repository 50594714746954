import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenedMenu: false,
      touchStartX: 0,
      touchStartY: 0
    }
    this.navigation = React.createRef();
    this.mobileMenu = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate() {
    if (this.state.isOpenedMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.removeAttribute("style");
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleStart = (event) => {
    this.setState({ touchStartX: event.touches[0].clientX, touchStartY: event.touches[0].clientY });
  }

  handleEnd = (event) => {
    if (this.state.touchStartX > (event.changedTouches[0].clientX) && (this.state.touchStartY > event.changedTouches[0].clientY ? (this.state.touchStartY - event.changedTouches[0].clientY) < 20 : (event.changedTouches[0].clientY - this.state.touchStartY) < 20)) {
      this.setState({ isOpenedMenu: false });
    }
  }

  handleClick = () => {
    this.setState({ isOpenedMenu: !this.state.isOpenedMenu });
  }

  handleClose = () => {
    this.setState({ isOpenedMenu: false });
  }

  handleClickOutside = (event) => {
    if (this.state.isOpenedMenu && this.navigation && !this.navigation.current.contains(event.target) && this.mobileMenu && !this.mobileMenu.current.contains(event.target)) {
      this.setState({ isOpenedMenu: false });
    }
  }

  render() {
    return (
      <div className="header">
        <div className="header-container">
          <a href="/"><span className="header-logo"></span></a>
          <div className="header-menu">
            <NavLink to="/agreement" className="header-nav-item" activeClassName="header-nav-item-active">Соглашение</NavLink>
            <NavLink to="/bonus" className="header-nav-item" activeClassName="header-nav-item-active">Бонусы</NavLink>
            <NavLink to="/partners" className="header-nav-item" activeClassName="header-nav-item-active">Партнерам</NavLink>
            <NavLink to="/news" className="header-nav-item" activeClassName="header-nav-item-active">Новости</NavLink>
            <NavLink to="/support" className="header-nav-item" activeClassName="header-nav-item-active">Поддержка</NavLink>
          </div>
          {!this.props.accessToken ?
            <div className="header-auth" onClick={() => this.props.changeModal()}>Войти</div>
            :
            <div className="header-auth">
              <div className="header-login">
                <NavLink to="/profile/orders">{this.props.userEmail}</NavLink>
              </div>
            </div>
          }
          <div className="header-mobile-menu" onClick={this.handleClick} ref={this.mobileMenu}>
            <div className={"mobile-menu" + (this.state.isOpenedMenu ? " open" : "")}>
              <div className="mobile-menubar"></div>
            </div>
          </div>
        </div>
        <div className={"navigation" + (this.state.isOpenedMenu ? " open" : "")} ref={this.navigation} onTouchStart={this.handleStart} onTouchEnd={this.handleEnd}>
          <ul>
            {!this.props.accessToken ?
              <li onClick={() => { this.handleClose(); this.props.changeModal() }} id="mobile-login"><span className="mobile-login">Войти</span><span className="icon-user navigation-icon"></span></li>
              :
              <li onClick={this.handleClose}><span className="icon-user navigation-icon"></span><NavLink to="/profile/orders">Личный кабинет</NavLink></li>
            }
            <li onClick={this.handleClose}><span className="icon-in navigation-icon"></span><a href="/">Обмен электронных валют</a></li>
            <li onClick={this.handleClose}><span className="icon-about navigation-icon"></span><NavLink to="/about-us#instruction">Инструкция по обмену</NavLink></li>
            <li onClick={this.handleClose}><span className="icon-data navigation-icon"></span><NavLink to="/agreement">Пользовательское Соглашение</NavLink></li>
            <li onClick={this.handleClose}><span className="icon-discount navigation-icon"></span><NavLink to="/bonus">Бонусная программа</NavLink></li>
            <li onClick={this.handleClose}><span className="icon-referrals navigation-icon"></span><NavLink to="/partners">Партнерская программа</NavLink></li>
            <li onClick={this.handleClose}><span className="icon-news navigation-icon"></span><NavLink to="/news">Новости и акции</NavLink></li>
            <li onClick={this.handleClose}><span className="icon-faq navigation-icon"></span><NavLink to="/support">Центр поддержки</NavLink></li>
            <li onClick={this.handleClose}><span className="icon-reviews navigation-icon"></span><NavLink to="/reviews">Отзывы пользователей</NavLink></li>
          </ul>
          <div className="social-links">
            <span className="icon-telegram icon-big"></span>
            <span className="icon-twitter icon-big"></span>
            <span className="icon-facebook icon-big"></span>
            <span className="icon-instagram icon-big"></span>
            <span className="icon-youtube icon-big"></span>
            <span className="icon-vk icon-big"></span>
          </div>
          <div className="language">
            <span id="mobile-language"><span className="icon-globe icon-medium"></span>Russian</span>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;