import { NavLink } from 'react-router-dom';

export const AccountMenu = <div>
  <NavLink to="/profile/orders" className="account-nav-item" activeClassName="account-nav-item-active">Мои заявки</NavLink>
  <a className="account-nav-item" href="/">Создать заявку</a>
  <NavLink to="/profile/cards" className="account-nav-item" activeClassName="account-nav-item-active">Банковские карты</NavLink>
  <NavLink to="/profile/discount" className="account-nav-item" activeClassName="account-nav-item-active">Бонусная программа</NavLink>
  <NavLink to="/profile/referrals" className="account-nav-item" activeClassName="account-nav-item-active">Партнерская программа</NavLink>
  <NavLink to="/profile/promotion" className="account-nav-item" activeClassName="account-nav-item-active">Рекламные материалы</NavLink>
  <NavLink to="/profile/settings" className="account-nav-item" activeClassName="account-nav-item-active">Настройки</NavLink>
  <a className="account-nav-item" href="/" onClick={() => localStorage.removeItem('token')}>Выход</a>
</div>;

